import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  Td,
  Th,
  Tr,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Button,
  Thead,
  VStack,
  Stack,
  Spacer,
  Heading,
  Center,
  Skeleton,
  Modal,
  Divider,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  CircularProgress,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertDescription,
  ButtonGroup,
  Tooltip,
  SimpleGrid,
  InputRightElement,
  InputGroup,
  Input,
  Checkbox,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent,
  Switch,
  HStack,
} from '@chakra-ui/react';
import Pagination from '../common/Pagination';
import {
  EditIcon,
  DownloadIcon,
  CloseIcon,
  DeleteIcon,
  AddIcon,
  MinusIcon,
  PlusSquareIcon,
  HamburgerIcon,
  ViewIcon,
} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import ProductFilters from './ProductFilters';
import apiHelpers from '../../utils/apiHelpers';
import { CSVLink } from 'react-csv';
import { Parser } from 'json2csv';
import SelectDates from '../common/selects/SelectDates';
import formatter from '../../utils/formatter';
import moment from 'moment';
import SelectBranch from '../common/selects/SelectBranch';
import SelectProvince from '../common/selects/SelectProvince';
import SelectStore from '../common/selects/SelectStore';
import OrderArrows from '../common/OrderArrows';
import SelectProductFormat from '../common/selects/SelectProductFormat';
import Joyride, { STATUS, EVENTS, ACTIONS } from 'react-joyride';
import BranchesContext from '../../contexts/BranchesContext';
import FilterModal from '../common/FilterModal';
import JoyrideContext from '../../contexts/JoyrideContext';
import { DateRangePicker } from 'rsuite';

const Products = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const { isJoyrideActive, setJoyride } = useContext(JoyrideContext);
  const branchContext = useContext(BranchesContext);
  const boxBg = useColorModeValue('white', '#1E2626');
  const TheadBg = 'generic.1';
  const [pageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(null);
  const [count, setCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [CSVdata, setCSVdata] = useState([]);
  const color = 'black';
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [datesFilter, setDatesFilter] = useState([null, null]);
  const [errorMessage, setMessageError] = useState(null);
  const [isOperative, setIsOperative] = useState(false);
  const [eans, setEans] = useState([]);
  const [allProducts, setAllProducts] = useState(false);
  const [actualOrdering, setActualOrdering] = useState({
    coverage: 'DESC',
  });
  const [descriptionsOfProducts, setDescriptions] = useState([]);

  const mainColor = 'generic.1';
  const notEvenRowColor = '#e4e4e4';
  const evenRowColor = '#d8d8d8';

  const [stateFilterPrices, setStateFilterPrices] = useState({
    branchType: '',
    province: '',
    storeBrand: '',
    productFormat: '',
    coverage: '',
  });
  const [steps, setSteps] = useState([
    {
      target: '.my-first-step',
      content: (
        <Text as="b" fontSize="18px">
          En listado de productos, vas a poder encontrar todo lo relacionado a
          los productos en sistema.
        </Text>
      ),
      title: (
        <Flex flexDirection={'column'}>
          <i class="fa fa-solid fa-list" style={{ fontSize: '4em' }}></i>
          <Text as="b" fontSize={'40px'}>
            ¡Bienvenido!
          </Text>
        </Flex>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-second-step',
      content: (
        <Text as="b" fontSize="18px">
          Podrás filtrar tu búsqueda siendo así mas rápida y dinámica.
        </Text>
      ),
      title: (
        <Text as="b" fontSize={'40px'}>
          Filtros
        </Text>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-third-step',
      content: (
        <Text as="b" fontSize="18px">
          Encontrarás toda la información sobre los productos.
        </Text>
      ),
      title: (
        <Text as="b" fontSize={'40px'}>
          Listado
        </Text>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-fourth-step',
      content: (
        <Text as="b" fontSize="18px">
          Podrás ordenar de manera ascendente o descendente los productos por
          cada una de sus columnas.
        </Text>
      ),
      title: (
        <Text as="b" fontSize={'40px'}>
          Flechas
        </Text>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-fifth-step',
      content: (
        <Text as="b" fontSize="18px">
          Tenes la opción de marcar un producto para recibir notificaciones o
          no, solo debes hacer click en "Si" o "No", respectivamente.
        </Text>
      ),
      title: (
        <Text as="b" fontSize={'40px'}>
          Notificar
        </Text>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-sixth-step',
      content: (
        <Text as="b" fontSize="18px">
          Podrás acceder al detalle del producto seleccionado.
        </Text>
      ),
      title: (
        <Text as="b" fontSize={'40px'}>
          Detalle
        </Text>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-seventh-step',
      content: (
        <Text as="b" fontSize="18px">
          El listado cuenta con paginación en caso de querer ver más productos.
        </Text>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-eighth-step',
      content: (
        <Text as="b" fontSize="18px">
          Podrás exportar los productos con toda su información en un archivo
          CSV.
        </Text>
      ),
      title: (
        <Text as="b" fontSize={'40px'}>
          Productos
        </Text>
      ),
      disableBeacon: true,
    },
    {
      target: '.my-tenth-step',
      content: (
        <Text as="b" fontSize="18px">
          Podrás acceder a la pantalla comparativa de productos
        </Text>
      ),
      title: (
        <Text as="b" fontSize={'40px'}>
          Comparativas
        </Text>
      ),
      disableBeacon: true,
    },
  ]);
  const [stepsModalExport, setStepsModalExport] = useState([
    {
      target: '.my-eleventh-step',
      content: (
        <Text as="b" fontSize="18px">
          Cuentas con diferentes filtros para los precios de los productos
          seleccionados al momento de exportar.
        </Text>
      ),
    },
    {
      target: '.my-twelfth-step',
      content: (
        <Text as="b" fontSize="18px">
          Haciendo clic en este botón podrás exportar en un archivo CSV los
          precios con los filtros seleccionados previamente.
        </Text>
      ),
    },
  ]);
  const [runOnboardingModal, setRunOnboardingModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const colorButtonRelevant = useColorModeValue('white', 'Black');
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(0)"
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const download = useRef(null);

  useEffect(() => {
    if (window.navigator.userAgentData.mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    apiHelpers.getCurrentUser().then(response => {
      setCurrentUser(response.data);
      response.data.role === 'OPERATIVE' && setIsOperative(true);
    });
    getProducts();
  }, []);

  useEffect(() => {
    if (allProducts) {
      let filtersForComparative = filters;
      if (filters && filters.where) {
        filtersForComparative.where.allProductEans = true;
      } else {
        filtersForComparative = {
          where: {
            allProductEans: true,
          },
        };
      }
      apiHelpers.getProducts(filtersForComparative).then(response => {
        let eans = response.data
          ? Array.from(response.data, product => product.productEan)
          : [];
        let descriptions = response.data
          ? Array.from(response.data, product => product.productName)
          : [];
        setEans(eans);
        setDescriptions(descriptions);
      });
    } else {
      setEans([]);
      setDescriptions([]);
    }
  }, [allProducts]);

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  useEffect(() => {
    CSVdata.length && download.current.link.click();
    setRunOnboardingModal(false);
    onClose();
    setStateFilterPrices({
      branchType: '',
      province: '',
      storeBrand: '',
      productFormat: '',
    });
    setDatesFilter([null, null]);
  }, [CSVdata]);

  useEffect(() => {
    getProducts();
  }, [filters]);

  const addAndRemoveProduct = (productEan, description) => {
    let newEans = JSON.parse(JSON.stringify(eans));
    if (newEans.includes(productEan)) {
      for (let i = 0; i < newEans.length; i++) {
        if (newEans[i] === productEan) {
          newEans.splice(i, 1);
          descriptionsOfProducts.splice(i, 1);
        }
      }
      setEans(newEans);
    } else {
      let descriptions = JSON.parse(JSON.stringify(descriptionsOfProducts));
      newEans.push(productEan);
      descriptions.push(description);
      setEans(newEans);
      setDescriptions(descriptions);
    }
  };
  const exportProducts = async () => {
    const fields = [
      'EAN',
      'Marca',
      'Descripción',
      'Formato',
      'Notificar',
      'Fecha de alta',
      'Categoria 1',
      'Categoria 2',
      'Categoria 3',
      'Categoria 4',
    ];
    let outputProducts = [];

    //get products without pagination
    let productsCsv = await apiHelpers.getProducts({
      ...filters,
      limit: count,
    });

    let countCategories = await apiHelpers.getCategoriesCount();
    let categories = await apiHelpers.getCategories({
      limit: countCategories.data.count,
    });

    for (let index = 0; index < productsCsv.data.length; index++) {
      for (let product of productsCsv.data) {
        let cat_1 = categories.data.find(
          c => c.categoryInternalId == product.cat_1,
        );
        let cat_2 = categories.data.find(
          c => c.categoryInternalId == product.cat_2,
        );
        let cat_3 = categories.data.find(
          c => c.categoryInternalId == product.cat_3,
        );
        let cat_4 = categories.data.find(
          c => c.categoryInternalId == product.cat_4,
        );
        outputProducts[index] = {
          EAN: product.productEan + '\t',
          Marca:
            product.ProductBrand !== 'undefined'
              ? product.productBrand
              : 'Sin marca',
          Descripción: product.productName,
          Formato: product.productFormat,
          Notificar: product.relevant ? 'Si' : 'No',
          'Fecha de alta': formatter.formatDate(product.createdAt),
          'Categoria 1': cat_1.categoryName,
          'Categoria 2': cat_2.categoryName,
          'Categoria 3': cat_3.categoryName,
          'Categoria 4': cat_4.categoryName,
        };
        index++;
      }
    }

    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputProducts);
    setCSVdata(csv);
  };
  const getProducts = async () => {
    setIsLoading(true);

    const filtersWithPaging = {
      ...filters,
      limit: pageSize,
      order: actualOrdering,
      skip: (currentPage - 1) * pageSize,
    };
    try {
      try {
        let products = await apiHelpers.getProducts(filtersWithPaging);

        if (products.data) {
          let count = await apiHelpers.getProductsCount(filters);
          setCount(count.data.count);
          setProducts(products.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleChangeFilters = filters => {
    setIsLoading(true);
    setFilters(filters);
  };

  const handleChangePage = pageOfItems => {
    setCurrentPage(pageOfItems);
    getProducts();
  };

  const getNumberOfPages = () => {
    return Math.ceil(count / pageSize);
  };

  const orderBy = (element, order, remove) => {
    let newOrdering = actualOrdering;

    if (remove) {
      newOrdering[element] && delete newOrdering[element];
    } else {
      newOrdering[element] = order;
    }
    setActualOrdering(newOrdering);
    getProducts();
  };

  const exportMetricPrice = async () => {
    let fields = [
      'EAN',
      'Descripción',
      'Formato',
      'Marca',
      'Fecha',
      'Precio mínimo',
      'Precio máximo',
      'Precio medio',
      'Moda',
      'Cobertura',
      'Cobertura (%)',
      'Cadena de sucursal',
      'Provincia',
      'Canal',
    ];
    let outputPrices = [];

    let filtersWithDate = filters;
    if (filters) {
      filters.where.day_from = moment(datesFilter[0]).toString();
      filters.where.day_to = moment(datesFilter[1]).toString();
    } else {
      if (datesFilter[0] && datesFilter[1]) {
        filtersWithDate = {
          where: {
            day_from: moment(datesFilter[0]).toString(),
            day_to: moment(datesFilter[1]).toString(),
          },
        };
      } else {
        let daysAgo = moment().subtract(90, 'days');

        filtersWithDate = {
          where: {
            day_from: moment(daysAgo).toString(),
            day_to: moment().toString(),
          },
        };
      }
    }
    if (stateFilterPrices.branchType)
      filtersWithDate.where.branchType = stateFilterPrices.branchType;
    if (stateFilterPrices.storeBrand)
      filtersWithDate.where.storeBrand = stateFilterPrices.storeBrand;
    if (stateFilterPrices.province)
      filtersWithDate.where.province = stateFilterPrices.province;
    if (stateFilterPrices.productFormat)
      filtersWithDate.where.productFormat = stateFilterPrices.productFormat;
    if (stateFilterPrices.coverage)
      filtersWithDate.where.coverage = stateFilterPrices.coverage;
    setIsLoadingExport(true);

    let prices = await apiHelpers.getPricesMetricsForExport(filtersWithDate);
    let totalBranches = branchContext.branchesCount;

    if (!prices.data.length) {
      setIsLoadingExport(false);
      setMessageError('No hay precios con los filtros seleccionados');
      return;
    } else {
      setMessageError(null);
    }
    for (let price of prices.data) {
      outputPrices[price.id] = {
        EAN: price.productEan + '\t',
        Descripción: price.productName,
        Formato: price.productFormat,
        Marca: price.productBrand,
        Fecha: formatter.formatDate(price.metric_date),
        'Precio mínimo': formatter.formatNumberDecimals(price.minPrice, 2),
        'Precio máximo': formatter.formatNumberDecimals(price.maxPrice, 2),
        'Precio medio': formatter.formatNumberDecimals(price.medPrice, 2),
        Moda: formatter.formatNumberDecimals(price.modePrice, 2),
        Cobertura: price.coverage + ' / ' + totalBranches + '\t',
        'Cobertura (%)':
          totalBranches == 0
            ? 'Sin cobertura'
            : formatter.formatNumberDecimals(
                (price.coverage / totalBranches) * 100,
                3,
              ) + '%',
        'Cadena de sucursal': price.storeBrand ? price.storeBrand : 'Todos',
        Provincia: price.province ? price.province : 'Todos',
        Canal: price.branchType ? price.branchType : 'Todos',
      };
    }
    outputPrices = outputPrices.sort((a, b) =>
      moment(a.Fecha).isBefore(moment(b.Fecha)) ? 1 : -1,
    );
    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputPrices);

    setIsLoadingExport(false);
    setCSVdata(csv);
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setStateFilterPrices(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  const modalExportPrices = (
    <Modal
      isOpen={isOpen}
      size={'4xl'}
      onClose={() => {
        setRunOnboardingModal(false);
        onClose();
      }}>
      <OverlayOne />
      <ModalContent bgColor={boxBg}>
        <ModalHeader fontSize={35}>Exportar precios</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize={25}>
          {!isLoadingExport ? (
            <Box>
              {errorMessage && (
                <Box pr={1}>
                  <Center>
                    <Alert minW="full" borderRadius="md" status="error">
                      <AlertIcon />
                      <AlertDescription fontSize="md">
                        {errorMessage}{' '}
                      </AlertDescription>
                    </Alert>
                  </Center>
                </Box>
              )}
              <SimpleGrid
                className="my-eleventh-step"
                spacing={1}
                columns={{ base: 1, sm: 4, lg: 3 }}
                rows={{ lg: 2 }}>
                <Box
                  borderRadius="md"
                  border={'1px solid'}
                  borderColor={color}
                  my={1}
                  _hover={{
                    fontSize: '15px',
                  }}>
                  <SelectDates
                    export={true}
                    value={datesFilter}
                    onChange={setDatesFilter}
                  />
                </Box>
                <Tooltip
                  label="Filtrar por cobertura"
                  placement="top"
                  arrowSize={5}
                  hasArrow>
                  <Box my={1}>
                    <InputGroup>
                      <Input
                        border={'1px solid'}
                        borderColor={color}
                        _placeholder={{
                          color: color,
                        }}
                        _hover={{
                          fontSize: '15px',
                        }}
                        minW={'220px'}
                        placeholder="Cobertura"
                        name="coverage"
                        onChange={handleChange}
                        value={stateFilterPrices.coverage}
                      />
                      {stateFilterPrices.coverage !== '' && (
                        <InputRightElement
                          children={
                            <Button
                              p={0}
                              m={0}
                              boxSize={0}
                              onClick={() =>
                                setStateFilterPrices(prevState => {
                                  return { ...prevState, coverage: '' };
                                })
                              }
                              bg={'none'}>
                              <CloseIcon color="grey" boxSize={3} />
                            </Button>
                          }
                        />
                      )}
                    </InputGroup>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="Filtrar por formato"
                  placement="top"
                  arrowSize={5}
                  hasArrow>
                  <Box
                    my={1}
                    fontSize="16px"
                    borderRadius="md"
                    border={'1px solid'}
                    borderColor={color}
                    placeholder="Formato"
                    _placeholder={{
                      color: color,
                    }}
                    _hover={{
                      fontSize: '15px',
                    }}
                    id="productFormat">
                    <SelectProductFormat
                      key={
                        !stateFilterPrices.productFormat
                          ? `clearer__${stateFilterPrices.productFormat}`
                          : 'productFormat'
                      }
                      placeholder="Formato"
                      value={stateFilterPrices.productFormat}
                      name={'productFormat'}
                      onChange={handleChange}
                    />
                  </Box>
                </Tooltip>
                <Tooltip
                  label="Filtrar por canal"
                  placement="top"
                  arrowSize={5}
                  hasArrow>
                  <Box
                    layerStyle={'input-box'}
                    placeholder="Canal"
                    id="branchType">
                    <SelectBranch
                      combinationFilters={{
                        storeBrand: stateFilterPrices.storeBrand
                          ? stateFilterPrices.storeBrand
                          : null,
                        province: stateFilterPrices.province
                          ? stateFilterPrices.province
                          : null,
                      }}
                      placeholder="Canal"
                      value={stateFilterPrices.branchType}
                      name={'branchType'}
                      onChange={handleChange}></SelectBranch>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="Filtrar por provincia"
                  placement="top"
                  arrowSize={5}
                  hasArrow>
                  <Box
                    layerStyle={'input-box'}
                    placeholder="Provincia"
                    id="province">
                    {' '}
                    <SelectProvince
                      placeholder="Provincia"
                      value={stateFilterPrices.province}
                      name={'province'}
                      combinationFilters={{
                        storeBrand: stateFilterPrices.storeBrand
                          ? stateFilterPrices.storeBrand
                          : null,
                        branchType: stateFilterPrices.branchType
                          ? stateFilterPrices.branchType
                          : null,
                      }}
                      onChange={handleChange}></SelectProvince>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="Filtrar por cadena"
                  placement="top"
                  arrowSize={5}
                  hasArrow>
                  <Box
                    layerStyle={'input-box'}
                    placeholder="Cadena"
                    id="storeBrand">
                    {' '}
                    <SelectStore
                      combinationFilters={{
                        province: stateFilterPrices.province
                          ? stateFilterPrices.province
                          : null,
                        branchType: stateFilterPrices.branchType
                          ? stateFilterPrices.branchType
                          : null,
                      }}
                      placeholder="Cadena"
                      value={stateFilterPrices.storeBrand}
                      name={'storeBrand'}
                      onChange={handleChange}></SelectStore>
                  </Box>
                </Tooltip>
              </SimpleGrid>
            </Box>
          ) : (
            <Box>
              <Center>
                <Stack>
                  {' '}
                  <CircularProgress
                    isIndeterminate
                    color="generic.1"
                    size="80px"
                    thickness="3px"
                  />
                </Stack>
              </Center>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <SimpleGrid
            w="full"
            spacing={1}
            columns={{ base: 1, sm: 3, lg: 3 }}
            rows={{ lg: 2 }}>
            <Button
              className="my-twelfth-step"
              w="full"
              minW={'100px'}
              onClick={() => {
                exportMetricPrice();
              }}>
              {' '}
              <CSVLink
                ref={download}
                data={CSVdata}
                filename={'Metricas de precios.csv'}
                className="hidden"></CSVLink>
              <Text
                mr={1}
                px={4}
                justifyContent="center"
                _hover={{
                  fontSize: '15px',
                }}
                display={{ base: 'none', sm: 'block' }}>
                Exportar
              </Text>
              <DownloadIcon></DownloadIcon>
            </Button>

            <Button
              variant="reset-filters-button"
              onClick={() => {
                setStateFilterPrices({
                  branchType: '',
                  province: '',
                  storeBrand: '',
                  productFormat: '',
                  coverage: '',
                });
                setDatesFilter([null, null]);
              }}
              type="reset">
              <Text
                color={colorButtonRelevant}
                px={4}
                display={{ base: 'none', sm: 'block' }}>
                Limpiar filtros
              </Text>
              <DeleteIcon color={colorButtonRelevant} />
            </Button>

            <Button
              w="full"
              colorScheme={'orange'}
              _hover={{
                fontSize: '15px',
              }}
              minW={'100px'}
              onClick={onClose}>
              <Text
                color={colorButtonRelevant}
                px={4}
                display={{ base: 'none', sm: 'block' }}>
                Cerrar
              </Text>

              <CloseIcon></CloseIcon>
            </Button>
          </SimpleGrid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const exportModalCloseButton = (
    <Flex
      w="full"
      gap={{ base: '10px', md: '50px' }}
      justify={'center'}
      alignItems={'center'}
      direction={{ base: 'column', md: 'row' }}>
      <Button
        variant="reset-filters-button"
        onClick={() => {
          setStateFilterPrices({
            branchType: '',
            province: '',
            storeBrand: '',
            productFormat: '',
            coverage: '',
          });
          setDatesFilter([null, null]);
        }}
        type="reset">
        <Text color={'black'} px={4} display={{ base: 'none', sm: 'block' }}>
          Limpiar filtros
        </Text>
        <DeleteIcon color={'black'} />
      </Button>
      <Button
        className="my-twelfth-step"
        // w={'300px'}
        // h={'40px'}
        // bg={'#b2b1ef'}
        variant="submit-filters-button"
        onClick={() => {
          exportMetricPrice();
        }}>
        {' '}
        <CSVLink
          ref={download}
          data={CSVdata}
          filename={'Metricas de precios.csv'}
          className="hidden"></CSVLink>
        <Text
          mr={1}
          px={4}
          justifyContent="center"
          _hover={{
            fontSize: '15px',
          }}
          color={'black'}
          display={{ base: 'none', sm: 'block' }}>
          Exportar
        </Text>
        <DownloadIcon color={'black'}></DownloadIcon>
      </Button>
    </Flex>
  );

  const [exportModalOpen, setExportModalOpen] = useState(false);
  const onCloseExportModal = () => {
    setExportModalOpen(false);
  };
  const exportModalButton = (
    <Box m={1}>
      <Button
        variant="primary-button"
        onClick={() => {
          setExportModalOpen(true);
        }}
        type="submit"
        isLoading={branchContext.isLoading || isLoading || isLoadingExport}>
        <Text mr={2}>Exportar Precios</Text>
        <DownloadIcon />
      </Button>
    </Box>
  );

  const exportButton = (
    <Flex>
      <Box className="my-eighth-step" my={1} mx={1}>
        <Button
          // _hover={{
          //   fontSize: '15px',
          // }}
          // fontSize="16px"
          // w={'320px'}
          // h={'45px'}
          // bg={mainColor}
          // color="white"
          variant="primary-button"
          type="submit"
          onClick={() => exportProducts()}
          isLoading={isLoading || isLoadingExport}>
          <Text mx={2} display={{ base: 'none', sm: 'block' }}>
            Exportar productos
          </Text>
          <DownloadIcon />
        </Button>
      </Box>
      {exportModalButton}
    </Flex>
  );
  const handleJoyrideCallback = data => {
    const { status, action, index, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
      setJoyride(true);
    } else if (action == 'close') {
      setJoyride(false);
    }
  };

  return (
    <Flex w="100%" justify="center">
      <FilterModal
        isModalOpen={exportModalOpen}
        onClose={onCloseExportModal}
        title={'Exportar precios'}
        filterButtons={exportModalCloseButton}>
        {!isLoadingExport ? (
          <Box>
            {errorMessage && (
              <Box pr={1}>
                <Center>
                  <Alert minW="full" borderRadius="md" status="error">
                    <AlertIcon />
                    <AlertDescription fontSize="md">
                      {errorMessage}{' '}
                    </AlertDescription>
                  </Alert>
                </Center>
              </Box>
            )}
            <SimpleGrid
              className="my-eleventh-step"
              spacing={1}
              columns={{ base: 1, sm: 2, lg: 3 }}
              rows={{ lg: 2 }}>
              <Box
                borderRadius="md"
                border={'2px solid'}
                borderColor={mainColor}
                my={1}
                _hover={{
                  fontSize: '15px',
                }}
                _focus={{
                  border: '2px solid',
                  borderColor: mainColor,
                }}
                _active={{
                  border: '2px solid',
                  borderColor: mainColor,
                }}>
                <SelectDates
                  export={true}
                  value={datesFilter}
                  onChange={setDatesFilter}
                />
              </Box>
              <Tooltip
                label="Filtrar por cobertura"
                placement="top"
                arrowSize={5}
                hasArrow>
                <Box my={1}>
                  <InputGroup>
                    <Input
                      value={stateFilterPrices.coverage}
                      border={'2px solid'}
                      borderColor={mainColor}
                      _focus={{
                        border: '2px solid',
                        borderColor: mainColor,
                      }}
                      _placeholder={{
                        color: color,
                      }}
                      _hover={{
                        fontSize: '15px',
                      }}
                      placeholder="Cobertura"
                      name="coverage"
                      onChange={handleChange}
                      minW={'220px'}
                    />
                    {stateFilterPrices.coverage !== '' && (
                      <InputRightElement
                        children={
                          <Button
                            p={0}
                            m={0}
                            boxSize={0}
                            onClick={() =>
                              setStateFilterPrices(prevState => {
                                return { ...prevState, coverage: '' };
                              })
                            }
                            bg={'none'}>
                            <CloseIcon color="grey" boxSize={3} />
                          </Button>
                        }
                      />
                    )}
                  </InputGroup>
                </Box>
              </Tooltip>
              <Tooltip
                label="Filtrar por formato"
                placement="top"
                arrowSize={5}
                hasArrow>
                <Box
                  my={1}
                  fontSize="16px"
                  borderRadius="md"
                  border={'2px solid'}
                  borderColor={mainColor}
                  placeholder="Formato"
                  _placeholder={{
                    color: color,
                  }}
                  _hover={{
                    fontSize: '15px',
                    border: '2px solid',
                    borderColor: mainColor,
                  }}
                  id="productFormat">
                  <SelectProductFormat
                    key={
                      !stateFilterPrices.productFormat
                        ? `clearer__${stateFilterPrices.productFormat}`
                        : 'productFormat'
                    }
                    placeholder="Formato"
                    value={stateFilterPrices.productFormat}
                    name={'productFormat'}
                    onChange={handleChange}
                  />
                </Box>
              </Tooltip>
              <Tooltip
                label="Filtrar por canal"
                placement="top"
                arrowSize={5}
                hasArrow>
                <Box
                  my={1}
                  fontSize="16px"
                  borderRadius="md"
                  border={'2px solid'}
                  borderColor={mainColor}
                  placeholder="Canal"
                  _placeholder={{
                    color: color,
                  }}
                  _hover={{
                    fontSize: '15px',
                  }}
                  id="branchType">
                  <SelectBranch
                    combinationFilters={{
                      storeBrand: stateFilterPrices.storeBrand
                        ? stateFilterPrices.storeBrand
                        : null,
                      province: stateFilterPrices.province
                        ? stateFilterPrices.province
                        : null,
                    }}
                    placeholder="Canal"
                    value={stateFilterPrices.branchType}
                    name={'branchType'}
                    onChange={handleChange}></SelectBranch>
                </Box>
              </Tooltip>
              <Tooltip
                label="Filtrar por provincia"
                placement="top"
                arrowSize={5}
                hasArrow>
                <Box
                  my={1}
                  fontSize="16px"
                  borderRadius="md"
                  border={'2px solid'}
                  borderColor={mainColor}
                  placeholder="Provincia"
                  _placeholder={{
                    color: color,
                  }}
                  _hover={{
                    fontSize: '15px',
                  }}
                  id="province">
                  {' '}
                  <SelectProvince
                    placeholder="Provincia"
                    value={stateFilterPrices.province}
                    name={'province'}
                    combinationFilters={{
                      storeBrand: stateFilterPrices.storeBrand
                        ? stateFilterPrices.storeBrand
                        : null,
                      branchType: stateFilterPrices.branchType
                        ? stateFilterPrices.branchType
                        : null,
                    }}
                    onChange={handleChange}></SelectProvince>
                </Box>
              </Tooltip>
              <Tooltip
                label="Filtrar por cadena"
                placement="top"
                arrowSize={5}
                hasArrow>
                <Box
                  my={1}
                  fontSize="16px"
                  borderRadius="md"
                  border={'2px solid'}
                  borderColor={mainColor}
                  placeholder="Cadena"
                  _placeholder={{
                    color: color,
                  }}
                  _hover={{
                    fontSize: '15px',
                  }}
                  id="storeBrand">
                  {' '}
                  <SelectStore
                    combinationFilters={{
                      province: stateFilterPrices.province
                        ? stateFilterPrices.province
                        : null,
                      branchType: stateFilterPrices.branchType
                        ? stateFilterPrices.branchType
                        : null,
                    }}
                    placeholder="Cadena"
                    value={stateFilterPrices.storeBrand}
                    name={'storeBrand'}
                    onChange={handleChange}></SelectStore>
                </Box>
              </Tooltip>
            </SimpleGrid>
          </Box>
        ) : (
          <Box>
            <Center>
              <Stack>
                {' '}
                <CircularProgress
                  isIndeterminate
                  color="generic.1"
                  size="80px"
                  thickness="3px"
                />
              </Stack>
            </Center>
          </Box>
        )}
      </FilterModal>
      <VStack w="100%">
        <Joyride
          run={isJoyrideActive}
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          stepIndex={stepIndex}
          scrollToFirstStep
          showProgress
          showSkipButton
          disableScrolling
          steps={steps}
          locale={{
            next: <span>Continuar</span>,
            skip: '',
          }}
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: '#fff',
            },
            buttonNext: {
              backgroundColor: '#E31D1C',
              color: '#00000',
              borderColor: '#E31D1C',
            },
            buttonBack: {
              color: '#000000',
            },
            buttonClose: {
              backgroundColor: '#008080',
              color: '#000000',
            },
          }}
        />
        {/* Page title and filters */}
        <Flex
          bg="generic.1"
          w="100%"
          color={'white'}
          minH={'100px'}
          py={4}
          justify="space-around"
          align="center"
          direction={{ base: 'column', md: 'row' }}>
          <Heading className="my-first-step" ml={2}>
            Lista de Productos
          </Heading>
          <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
            <Popover
              placement="bottom-end"
              border="4px solid"
              borderColor="primary.200"
              _focus={{
                border: '4px solid',
                borderColor: 'primary.200',
              }}>
              <PopoverTrigger>
                <Button variant="secondary-button" className="my-second-step">
                  <Text mr={2}>Filtros</Text>
                  <i class="fa fa-solid fa-list"></i>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                bg="white"
                color="black"
                w={['330px', '480px', '630px', '980px', '1230px']}
                border="4px solid"
                borderColor={'primary.200'}
                _focus={{
                  border: '4px solid',
                  borderColor: 'primary.200',
                }}>
                <PopoverHeader as="Flex" align="center">
                  <Heading>Filtros</Heading>
                </PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody w={['300px', '450px', '600px', '950px', '1200px']}>
                  <ProductFilters
                    loadData={handleChangeFilters}
                    isLoading={isLoading || isLoadingExport}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Box className="my-tenth-step" mx={1}>
              <Button
                variant="secondary-button"
                onMouseDown={e => {
                  if (e.button !== 0 && e.button !== 2) {
                    window.open(`products/ComparativeScreen`, '_blank');
                  } else {
                    navigate('ComparativeScreen', {
                      state: {
                        eans: eans,
                        description: descriptionsOfProducts,
                      },
                    });
                  }
                }}
                type="submit">
                <Text px={2} display={{ base: 'none', sm: 'block' }}>
                  Comparar
                </Text>
                <i className="fa fa-solid fa-sort-amount-down"></i>
              </Button>
            </Box>
          </Flex>
        </Flex>
        {/* <Flex>{exportButton}</Flex> */}
        <Spacer />
        <Box bgColor={boxBg} border={'none'}>
          <Flex
            alignItems="center"
            justifyContent="center"
            border={'none'}
            sx={{ boxShadow: 'none' }}>
            <VStack
              m={{ base: 0, md: 2, lg: 3, xl: 3 }}
              direction="column"
              border={'none'}
              py={0}>
              <Stack w="full">
                <CSVLink
                  ref={download}
                  data={CSVdata}
                  filename={'productos.csv'}
                  className="hidden"></CSVLink>
              </Stack>

              <TableContainer
                className="my-third-step"
                overflowX={isMobile ? 'scroll' : 'auto'}
                whiteSpace={'normal'}
                maxW={{ md: 'full', lg: '1220px', xl: '1220px' }}
                minW={{ md: 'full', lg: '1220px', xl: '1220px' }}>
                <Table
                  variant="simple"
                  size={useBreakpointValue({ md: 'md', base: 'sm' })}>
                  <TableCaption>Lista de productos</TableCaption>

                  {isLoading || (isLoading && !products.length) ? (
                    <Stack my={3} w="full">
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                    </Stack>
                  ) : products.length ? (
                    <>
                      <Thead bgColor={TheadBg}>
                        <Tr m="0" p="0px">
                          <Th color="white" px={2}>
                            <Flex alignContent={'center'}>
                              <VStack alignItems="center">
                                <Text fontSize={12} mb={2}>
                                  EAN
                                </Text>
                                <Box p={0} m={0} className="my-fourth-step">
                                  <OrderArrows
                                    element={'productEan'}
                                    selectedOrder={actualOrdering}
                                    orderBy={orderBy}
                                  />
                                </Box>
                              </VStack>
                            </Flex>
                          </Th>
                          <Th color="white" px={2}>
                            <Center alignContent={'center'}>
                              <VStack alignItems="center">
                                <Text fontSize={12} mb={2}>
                                  Descripción
                                </Text>
                                <OrderArrows
                                  element={'productName'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}
                                />
                              </VStack>
                            </Center>
                          </Th>
                          <Th color="white" px={2}>
                            <Center alignContent={'center'}>
                              <VStack alignItems="center">
                                <Text fontSize={12} mb={2}>
                                  Marca
                                </Text>
                                <OrderArrows
                                  element={'productBrand'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}
                                />
                              </VStack>
                            </Center>
                          </Th>
                          <Th color="white" px={2}>
                            <Flex alignContent={'left'}>
                              <VStack alignItems="center">
                                <Text fontSize={12} mb={2}>
                                  Formato
                                </Text>
                                <OrderArrows
                                  element={'productFormat'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}
                                />
                              </VStack>
                            </Flex>
                          </Th>
                          {/* <Th color="white" px={2}>
                            <Flex alignContent={'left'}>
                              <VStack alignItems="center">
                                <Text fontSize={12} mb={2} textAlign={'center'}>
                                  Fecha de alta
                                </Text>
                                <OrderArrows
                                  element={'createdAt'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}
                                />
                              </VStack>
                            </Flex>
                          </Th> */}
                          <Th color="white" px={2}>
                            <Flex alignContent={'left'}>
                              <VStack alignItems="center">
                                <Text fontSize={12} mb={2} textAlign={'center'}>
                                  Cobertura PV
                                </Text>
                                <OrderArrows
                                  element={'coverage'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}
                                />
                              </VStack>
                            </Flex>
                          </Th>
                          <Th color="white" px={2}>
                            <Flex alignContent={'left'}>
                              <VStack alignItems="center" mb={4} mt={4}>
                                <Text fontSize={12} mb={2} textAlign={'center'}>
                                  Última actualización
                                </Text>
                                <OrderArrows
                                  element={'updatedAt'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}
                                />
                              </VStack>
                            </Flex>
                          </Th>
                          {!isOperative && (
                            <Th color="white" px={2}>
                              <Flex alignContent={'left'}>
                                <VStack alignItems="center">
                                  <Text fontSize={12} mb={2}>
                                    Notificar
                                  </Text>
                                  <OrderArrows
                                    element={'relevant'}
                                    selectedOrder={actualOrdering}
                                    orderBy={orderBy}
                                  />
                                </VStack>
                              </Flex>
                            </Th>
                          )}
                          <Th color="white" px={2}>
                            <Flex alignContent={'left'}>
                              <VStack alignItems="center">
                                <Text fontSize={12}>Analizar</Text>
                                <Spacer />
                                <Spacer />
                              </VStack>
                            </Flex>
                          </Th>
                          <Th color="white" px={2}>
                            <Flex
                              direction="column"
                              alignItems={'center'}
                              pb={4}>
                              <Text fontSize={12}>Comparar</Text>
                              {/* <Flex direction={'row'} mt={2}>
                                <Text fontSize={11} textAlign={'center'}>
                                  Seleccionar todos
                                </Text>
                                <Checkbox
                                  mt={0}
                                  ml={1}
                                  spacing="1rem"
                                  isChecked={allProducts}
                                  onChange={e =>
                                    setAllProducts(e.target.checked)
                                  }></Checkbox>
                              </Flex> */}
                            </Flex>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {products.map((product, i) => {
                          return (
                            <Tr m="0" p="0px">
                              <Td
                                px={2}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {product.productEan}
                              </Td>
                              <Td
                                px={2}
                                maxW={'240px'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {product.productName}
                              </Td>
                              <Td
                                px={2}
                                textAlign={'center'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {product.productBrand == 'SIN MARCA'
                                  ? 'S/m'
                                  : product.productBrand}
                              </Td>
                              <Td
                                px={2}
                                textAlign={'center'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {product.productFormat}
                              </Td>
                              {/* <Td
                                px={2}
                                textAlign={'center'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {formatter.formatDate(product.createdAt)}
                              </Td> */}
                              <Td
                                px={2}
                                textAlign={'center'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {product.coverage}
                              </Td>
                              <Td
                                pl={'18px'}
                                textAlign={'center'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {formatter.formatDate(product.updatedAt)}
                              </Td>
                              {!isOperative && (
                                <Td
                                  px={2}
                                  textAlign={'center'}
                                  className="my-fifth-step"
                                  bg={
                                    i % 2 == 0 ? notEvenRowColor : evenRowColor
                                  }>
                                  <HStack pl={2}>
                                    {!product.relevant && <Text>No</Text>}
                                    <Switch
                                      mx={2}
                                      mt={1}
                                      isDisabled={isOperative}
                                      sx={{
                                        'span.chakra-switch__track:not([data-checked])':
                                          { backgroundColor: 'red' },
                                      }}
                                      isChecked={product.relevant}
                                      colorScheme={
                                        !product.relevant ? 'red' : 'green'
                                      }
                                      onChange={async () => {
                                        await apiHelpers.patchProduct(
                                          product.productEan,
                                          {
                                            relevant: !product.relevant,
                                          },
                                        );
                                        getProducts();
                                      }}></Switch>
                                    {product.relevant && <Text>Si</Text>}
                                  </HStack>
                                </Td>
                              )}
                              <Td
                                px={2}
                                className="my-sixth-step"
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                <Center>
                                  <Button
                                    p="0px"
                                    bg="transparent"
                                    onMouseDown={e => {
                                      if (e.button !== 0 && e.button !== 2) {
                                        window.open(
                                          `products/${product.productEan}`,
                                          '_blank',
                                        );
                                      } else {
                                        navigate(`${product.productEan}`);
                                      }
                                    }}>
                                    <i className="fa fa-solid fa-eye"></i>
                                  </Button>
                                </Center>
                              </Td>
                              <Td
                                px={2}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                <Flex
                                  alignItems={'center'}
                                  justifyItems={'center'}
                                  justifyContent={'center'}
                                  alignContent={'center'}>
                                  {eans.includes(product.productEan) ? (
                                    <Button
                                      bgColor={'generic.1'}
                                      borderColor="black"
                                      border="2px"
                                      borderRadius="0px"
                                      onClick={() => {
                                        addAndRemoveProduct(
                                          product.productEan,
                                          product.productName,
                                        );
                                      }}
                                      _hover={{
                                        backgroundColor: mainColor,
                                        boxShadow: 'none',
                                        outline: '0',
                                      }}
                                      _focus={{
                                        backgroundColor: mainColor,
                                        boxShadow: 'none',
                                        outline: '0px',
                                      }}
                                      _active={{
                                        backgroundColor: mainColor,
                                        boxShadow: 'none',
                                        outline: '0',
                                      }}>
                                      <MinusIcon color="white" w={3} h={3} />
                                    </Button>
                                  ) : (
                                    <Button
                                      bgColor={'white'}
                                      borderColor="black"
                                      border="2px"
                                      borderRadius="0px"
                                      onClick={() => {
                                        addAndRemoveProduct(
                                          product.productEan,
                                          product.productName,
                                        );
                                      }}
                                      _hover={{
                                        boxShadow: 'none',
                                        outline: '0',
                                      }}
                                      _focus={{
                                        boxShadow: 'none',
                                        outline: '0px',
                                      }}
                                      _active={{
                                        boxShadow: 'none',
                                        outline: '0',
                                      }}>
                                      <AddIcon color="black" w={3} h={3} />
                                    </Button>
                                  )}
                                </Flex>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </>
                  ) : (
                    <Center minW={'full'}>
                      <Text fontSize={20} textAlign={'center'} p={6}>
                        No se encontraron resultados
                      </Text>
                    </Center>
                  )}
                </Table>
                <Box>
                  <Pagination
                    onChangePage={handleChangePage}
                    currentPage={currentPage}
                    totalPages={getNumberOfPages()}
                  />
                </Box>
              </TableContainer>
              {modalExportPrices}
            </VStack>
          </Flex>
        </Box>
      </VStack>
    </Flex>
  );
};
export default Products;
