import React from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { DateRangePicker, Toggle } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './SelectDatesLightMode.css';
import moment from 'moment';
const SelectDates = props => {
  const themeMode = useColorModeValue('rs-theme-light', 'rs-theme-dark');

  const {
    allowedRange,
    afterToday,
    after,
    combine,
    allowedMaxDays,
    beforeToday,
  } = DateRangePicker;
  let daysAgo = moment().subtract(360, 'days');
  return (
    <DateRangePicker
      appearance="subtle"
      portalId="root-portal"
      preventOverflow
      disabledDate={
        props.rangeLimit
          ? combine(afterToday(), allowedMaxDays(props.rangeLimit))
          : props.export
          ? allowedRange(
              daysAgo.toISOString('es-AR'),
              new Date().toISOString('es-AR'),
            )
          : // : afterToday()
          props.excludeToday
          ? after(moment(new Date()).subtract(1, 'day').toDate())
          : afterToday()
      }
      format={'dd/MM/yyyy'}
      character={' al '}
      // menuClassName={themeMode}
      placeholder={props.placeholder ? props.placeholder : 'Rango de fecha'}
      defaultValue={[null, null]}
      size={'md'}
      style={{
        // fontSize: '15px',
        // color: '#000000',
        // background: 'none',
        color: 'none',
        width: '100%',
        zIndex: -1,
      }}
      {...props}
    />
  );
};

export default SelectDates;
