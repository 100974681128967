import {
  Flex,
  VStack,
  Heading,
  Button,
  Spacer,
  useBreakpointValue,
  Tbody,
  Table,
  TableContainer,
  Tr,
  Td,
  Switch,
  Text,
  HStack,
  Tooltip,
  Input,
  InputGroup,
  Box,
  InputRightAddon,
} from '@chakra-ui/react';
import { colors } from '../../theme';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InfoIcon,
  QuestionIcon,
} from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import apiHelpers from '../../utils/apiHelpers';
import { useAlert } from 'react-alert';

const Notification = () => {
  const [showCoverageOptions, setShowCoverageOptions] = useState(false);
  const [notificationConfig, setNotificationConfig] = useState({
    newEansNotification: false,
    priceVariationIncreaseNotification: 0,
    priceVariationReduceNotification: 0,
    coverageVariationNotification: 0,
    hipermarketCoverageVariationNotification: 0,
    supermarketCoverageVariationNotification: 0,
    autoserviceCoverageVariationNotification: 0,
    traditionalCoverageVariationNotification: 0,
    webCoverageVariationNotification: 0,
  });
  const alert = useAlert();

  useEffect(() => {
    getUserNotificationConfig();
  }, []);
  const getUserNotificationConfig = async () => {
    const selfReponse = await apiHelpers.getCurrentUser();
    setNotificationConfig({
      newEansNotification: selfReponse.data.newEansNotification,
      priceVariationIncreaseNotification:
        selfReponse.data.priceVariationIncreaseNotification,
      priceVariationReduceNotification:
        selfReponse.data.priceVariationReduceNotification,
      coverageVariationNotification:
        selfReponse.data.coverageVariationNotification,
      hipermarketCoverageVariationNotification:
        selfReponse.data.hipermarketCoverageVariationNotification,
      supermarketCoverageVariationNotification:
        selfReponse.data.supermarketCoverageVariationNotification,
      autoserviceCoverageVariationNotification:
        selfReponse.data.autoserviceCoverageVariationNotification,
      traditionalCoverageVariationNotification:
        selfReponse.data.traditionalCoverageVariationNotification,
      webCoverageVariationNotification:
        selfReponse.data.webCoverageVariationNotification,
    });
  };
  const validateValues = () => {
    if (notificationConfig.priceVariationIncreaseNotification < 0.5) {
      return {
        status: false,
        message: 'La variación de precios debe ser mayor a 0.5%',
      };
    }
    if (notificationConfig.priceVariationReduceNotification < 0.5) {
      return {
        status: false,
        message: 'La variación de precios debe ser mayor a 0.5%',
      };
    }
    if (notificationConfig.coverageVariationNotification < 2) {
      return {
        status: false,
        message: 'La variación de cobertura debe ser al menos de 2%',
      };
    }
    if (notificationConfig.hipermarketCoverageVariationNotification < 2) {
      return {
        status: false,
        message:
          'La variación de cobertura en hipermercados debe ser al menos de 2%',
      };
    }
    if (notificationConfig.supermarketCoverageVariationNotification < 2) {
      return {
        status: false,
        message:
          'La variación de cobertura en supermercados debe ser al menos de 2%',
      };
    }
    if (notificationConfig.autoserviceCoverageVariationNotification < 2) {
      return {
        status: false,
        message:
          'La variación de cobertura en autoservicios debe ser al menos de 2%',
      };
    }
    if (notificationConfig.traditionalCoverageVariationNotification < 2) {
      return {
        status: false,
        message:
          'La variación de cobertura en comercios tradicionales debe ser al menos de 2%',
      };
    }
    if (notificationConfig.webCoverageVariationNotification < 2) {
      return {
        status: false,
        message:
          'La variación de cobertura en comercios web debe ser al menos de 2%',
      };
    }
    return { status: true };
  };
  const submitChanges = async () => {
    const validation = validateValues();
    if (validation.status == false) {
      alert.error(validation.message);
      return;
    }
    const selfReponse = await apiHelpers.getCurrentUser();
    const response = await apiHelpers.patchUserNotificationOptions(
      selfReponse.data.id,
      notificationConfig,
    );
    alert.success('Configuración de notificaciones actualizada');
  };
  const handleSwitchChange = e => {
    setNotificationConfig({
      ...notificationConfig,
      newEansNotification: e.target.checked,
    });
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setNotificationConfig({
      ...notificationConfig,
      [name]: value,
    });
  };
  return (
    <Flex w="100%" justify="center" pb={5}>
      <VStack w={'100%'}>
        <Flex
          my={4}
          bg={colors.generic['admin-headers']}
          w="100%"
          color={'white'}
          minH={'100px'}
          py={4}
          justify="space-around"
          align="center"
          direction={{ base: 'column', md: 'row' }}>
          <Heading>Notificaciones</Heading>
          <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
            <Button variant="primary-button" onClick={submitChanges}>
              Guardar cambios
            </Button>
          </Flex>
        </Flex>
        <Spacer />
        <TableContainer
          className="my-third-step"
          //   overflowX={isMobile ? 'scroll' : 'auto'}
          whiteSpace={'normal'}
          maxW={{ md: 'full', lg: '1200px', xl: '1200px' }}
          minW={{ md: 'full', lg: '1200px', xl: '1200px' }}>
          <Table
            variant="simple"
            size={useBreakpointValue({ md: 'md', base: 'sm' })}>
            <Tbody>
              <Tr>
                <Td bg={colors.generic['not-even-row']}>
                  <HStack>
                    <Text fontSize={'24px'} fontWeight={500}>
                      Alta/Baja de nuevos EANs
                    </Text>
                    {/* <Tooltip
                      maxW={'220px'}
                      label="Al activarse, cada día enviará notificaciones al correo con las altas/bajas de nuevos productos"
                      hasArrow
                      placement="right-start">
                      <QuestionIcon />
                    </Tooltip> */}
                  </HStack>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  <Box
                    layerStyle={'input-box'}
                    fontSize={'12px'}
                    w={'520px'}
                    bg={'white'}
                    _hover={{ bg: 'white' }}
                    h={'45px'}
                    color={'#9a9a9a'}>
                    <HStack pl={'10px'}>
                      <QuestionIcon fontSize={'20px'} color={'#06b9b3'} />
                      <Text>
                        Al activarse, cada día enviará notificaciones al correo
                        con las altas/bajas de nuevos productos
                      </Text>
                    </HStack>
                  </Box>
                </Td>
                <Td
                  bg={colors.generic['not-even-row']}
                  textAlign={'right'}
                  pr={'58px'}>
                  <Flex justifyContent={'right'}>
                    {!notificationConfig.newEansNotification && (
                      <Text mt={1}>No</Text>
                    )}
                    <Switch
                      mx={2}
                      mt={1}
                      sx={{
                        'span.chakra-switch__track:not([data-checked])': {
                          backgroundColor: 'red',
                        },
                      }}
                      isChecked={notificationConfig.newEansNotification}
                      name="newEansNotification"
                      onChange={handleSwitchChange}
                      colorScheme={
                        !notificationConfig.newEansNotification
                          ? 'red'
                          : 'green'
                      }></Switch>
                    {notificationConfig.newEansNotification && (
                      <Text mt={1}>Si</Text>
                    )}
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['even-row']}>
                  <HStack>
                    <Text fontSize={'24px'} fontWeight={500}>
                      Incrementos de precios
                    </Text>
                    {/* <Tooltip
                      label="Cada día enviará notificaciones al correo con aquellos productos que tuvieron un % de incremento igual o superior al seleccionado"
                      hasArrow
                      maxW={'220px'}
                      placement="right-start">
                      <QuestionIcon></QuestionIcon>
                    </Tooltip> */}
                  </HStack>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  <Box
                    layerStyle={'input-box'}
                    fontSize={'12px'}
                    w={'520px'}
                    bg={'white'}
                    _hover={{ bg: 'white' }}
                    h={'45px'}
                    color={'#9a9a9a'}>
                    <HStack pl={'10px'}>
                      <QuestionIcon fontSize={'20px'} color={'#06b9b3'} />
                      <Text>
                        Enviará notificaciones con aquellos productos que
                        tuvieron un % de incremento igual o superior al
                        seleccionado, respecto al promedio de los últimos 30
                        días a nivel nacional.
                      </Text>
                    </HStack>
                  </Box>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  <Flex
                    w={'100%'}
                    justifyContent={'flex-end'}
                    direction={'row'}>
                    <InputGroup w={'120px'}>
                      <Input
                        variant="secondary-input"
                        name="priceVariationIncreaseNotification"
                        value={
                          notificationConfig.priceVariationIncreaseNotification
                        }
                        w={'68px'}
                        onChange={handleChange}
                      />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['not-even-row']}>
                  <HStack>
                    <Text fontSize={'24px'} fontWeight={500}>
                      Reducción de precios
                    </Text>
                    {/* <Tooltip
                      maxW={'220px'}
                      placement="right-start"
                      hasArrow
                      label="Cada día enviará notificaciones al corre con aquellos productos que tuvieron un % de reducción igual o superior al seleccionado">
                      <QuestionIcon></QuestionIcon>
                    </Tooltip> */}
                  </HStack>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  <Box
                    layerStyle={'input-box'}
                    fontSize={'12px'}
                    w={'520px'}
                    bg={'white'}
                    _hover={{ bg: 'white' }}
                    h={'45px'}
                    color={'#9a9a9a'}>
                    <HStack pl={'10px'}>
                      <QuestionIcon fontSize={'20px'} color={'#06b9b3'} />
                      <Text>
                        Enviará notificaciones con aquellos productos que
                        tuvieron un % de reducción igual o superior al
                        seleccionado, respecto al promedio de los últimos 30
                        días a nivel nacional.
                      </Text>
                    </HStack>
                  </Box>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  <Flex
                    w={'100%'}
                    justifyContent={'flex-end'}
                    direction={'row'}>
                    <InputGroup w={'120px'}>
                      <Input
                        variant="secondary-input"
                        w={'68px'}
                        name="priceVariationReduceNotification"
                        value={
                          notificationConfig.priceVariationReduceNotification
                        }
                        onChange={handleChange}
                      />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['even-row']}>
                  <HStack>
                    <Text fontSize={'24px'} fontWeight={500}>
                      Cobertura
                    </Text>
                    <Button
                      bg={colors.generic['even-row']}
                      onClick={() => {
                        setShowCoverageOptions(!showCoverageOptions);
                      }}>
                      {showCoverageOptions ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )}
                    </Button>
                    {/* <Tooltip
                      maxW={'220px'}
                      placement="right-start"
                      hasArrow
                      label="Cada día enviará notificaciones al correo con aquellos productos que tuvieron un % de cobertura igual o inferior al seleccionado">
                      <QuestionIcon></QuestionIcon>
                    </Tooltip> */}
                  </HStack>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  <Box
                    layerStyle={'input-box'}
                    fontSize={'12px'}
                    w={'520px'}
                    bg={'white'}
                    _hover={{ bg: 'white' }}
                    h={'45px'}
                    color={'#9a9a9a'}>
                    <HStack pl={'10px'}>
                      <QuestionIcon fontSize={'20px'} color={'#06b9b3'} />
                      <Text>
                        Enviará notificaciones con aquellos productos que
                        tuvieron un % de cobertura igual o inferior al
                        seleccionado con respecto al promedio de los últimos 30
                        dias
                      </Text>
                    </HStack>
                  </Box>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  <Flex
                    w={'100%'}
                    justifyContent={'flex-end'}
                    direction={'row'}>
                    <InputGroup w={'120px'}>
                      <Input
                        variant="secondary-input"
                        w={'68px'}
                        name="coverageVariationNotification"
                        value={notificationConfig.coverageVariationNotification}
                        onChange={handleChange}
                      />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                  </Flex>
                </Td>
              </Tr>
              {showCoverageOptions && (
                <>
                  <Tr>
                    <Td
                      bg={colors.generic['not-even-row']}
                      pl={'180px'}
                      fontSize={'24px'}
                      fontWeight={500}>
                      Hipermercado
                    </Td>
                    <Td bg={colors.generic['not-even-row']}>
                      <Input
                        variant="secondary-input"
                        visibility={'hidden'}></Input>
                    </Td>
                    <Td bg={colors.generic['not-even-row']}>
                      <Flex
                        w={'100%'}
                        justifyContent={'flex-end'}
                        direction={'row'}>
                        <InputGroup w={'120px'}>
                          <Input
                            variant="secondary-input"
                            name="hipermarketCoverageVariationNotification"
                            w={'68px'}
                            value={
                              notificationConfig.hipermarketCoverageVariationNotification
                            }
                            onChange={handleChange}
                          />
                          <InputRightAddon>%</InputRightAddon>
                        </InputGroup>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      bg={colors.generic['even-row']}
                      pl={'180px'}
                      fontSize={'24px'}
                      fontWeight={500}>
                      Supermercados
                    </Td>

                    <Td bg={colors.generic['even-row']}>
                      <Input
                        variant="secondary-input"
                        visibility={'hidden'}></Input>
                    </Td>
                    <Td bg={colors.generic['even-row']}>
                      <Flex
                        w={'100%'}
                        justifyContent={'flex-end'}
                        direction={'row'}>
                        <InputGroup w={'120px'}>
                          <Input
                            variant="secondary-input"
                            name="supermarketCoverageVariationNotification"
                            w={'68px'}
                            value={
                              notificationConfig.supermarketCoverageVariationNotification
                            }
                            onChange={handleChange}
                          />
                          <InputRightAddon>%</InputRightAddon>
                        </InputGroup>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      bg={colors.generic['not-even-row']}
                      pl={'180px'}
                      fontSize={'24px'}
                      fontWeight={500}>
                      Autoservicios
                    </Td>
                    <Td bg={colors.generic['not-even-row']}>
                      <Input
                        variant="secondary-input"
                        visibility={'hidden'}></Input>
                    </Td>
                    <Td bg={colors.generic['not-even-row']}>
                      <Flex
                        w={'100%'}
                        justifyContent={'flex-end'}
                        direction={'row'}>
                        <InputGroup w={'120px'}>
                          <Input
                            variant="secondary-input"
                            name="autoserviceCoverageVariationNotification"
                            w={'68px'}
                            value={
                              notificationConfig.autoserviceCoverageVariationNotification
                            }
                            onChange={handleChange}
                          />
                          <InputRightAddon>%</InputRightAddon>
                        </InputGroup>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      bg={colors.generic['even-row']}
                      pl={'180px'}
                      fontSize={'24px'}
                      fontWeight={500}>
                      Tradicionales
                    </Td>
                    <Td bg={colors.generic['even-row']}>
                      <Input
                        variant="secondary-input"
                        visibility={'hidden'}></Input>
                    </Td>
                    <Td bg={colors.generic['even-row']}>
                      <Flex
                        w={'100%'}
                        justifyContent={'flex-end'}
                        direction={'row'}>
                        <InputGroup w={'120px'}>
                          <Input
                            variant="secondary-input"
                            name="traditionalCoverageVariationNotification"
                            w={'68px'}
                            value={
                              notificationConfig.traditionalCoverageVariationNotification
                            }
                            onChange={handleChange}
                          />
                          <InputRightAddon>%</InputRightAddon>
                        </InputGroup>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      bg={colors.generic['not-even-row']}
                      pl={'180px'}
                      fontSize={'24px'}
                      fontWeight={500}>
                      Web
                    </Td>
                    <Td bg={colors.generic['not-even-row']}>
                      <Input
                        variant="secondary-input"
                        visibility={'hidden'}></Input>
                    </Td>
                    <Td bg={colors.generic['not-even-row']} textAlign={'right'}>
                      <Flex
                        w={'100%'}
                        justifyContent={'flex-end'}
                        direction={'row'}>
                        <InputGroup w={'120px'}>
                          <Input
                            variant="secondary-input"
                            name="webCoverageVariationNotification"
                            w={'68px'}
                            value={
                              notificationConfig.webCoverageVariationNotification
                            }
                            onChange={handleChange}
                          />
                          <InputRightAddon>%</InputRightAddon>
                        </InputGroup>
                      </Flex>
                    </Td>
                  </Tr>
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Flex>
  );
};

export default Notification;
