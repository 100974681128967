import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Flex,
  Input,
  useDisclosure,
  useColorModeValue,
  GridItem,
  Divider,
  VStack,
  SimpleGrid,
  Text,
  Button,
  Stack,
  Heading,
  Grid,
  TableContainer,
  Tr,
  Thead,
  Table,
  Th,
  Skeleton,
  Tbody,
  Center,
  Td,
  TableCaption,
  Spacer,
  ButtonGroup,
  HStack,
  Spinner,
  CircularProgress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent,
  Switch,
} from '@chakra-ui/react';
import { Parser } from 'json2csv';
import {
  CloseIcon,
  DownloadIcon,
  ViewIcon,
  ViewOffIcon,
} from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import PriceChart from './PriceChart';
import apiHelpers from '../../utils/apiHelpers';
import moment from 'moment';
import ProductDetailFilters from './ProductDetailFilters';
import SelectCategory from '../common/selects/SelectCategory';
import formatter from '../../utils/formatter';
import { CSVLink } from 'react-csv';
import PriceModaByProvinceChart from './PriceModaByProvinceChart';
import Joyride, { STATUS } from 'react-joyride';
import SelectCategoryDetailProduct from '../common/selects/SelectCategoryDetailProduct';
import BranchesContext from '../../contexts/BranchesContext';
import { colors } from '../../theme';

const ProductDetail = () => {
  const branchContext = useContext(BranchesContext);
  const download = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({
    productEan: '',
    productName: '',
    relevant: '',
    productFormat: '',
    cat_1: '',
    cat_2: '',
    cat_3: '',
    cat_4: '',
    productBrand: '',
  });
  const [productDetails, setProductDetails] = useState({
    productEan: '',
    productName: '',
    relevant: '',
    productFormat: '',
    cat_1: '',
    cat_2: '',
    cat_3: '',
    cat_4: '',
    productBrand: '',
  });
  const [priceMetrics, setPriceMetrics] = useState([]);
  const [priceMetricsByProvince, setPriceMetricsByProvince] = useState([]);
  const boxBg = useColorModeValue('white', '#1E2626');
  const bgFocus = colors.primary['100'];
  const color = useColorModeValue('black', 'white');
  const boxShadow = useColorModeValue('dark-lg', '0 8px 25px #5B7D76');
  const location = useLocation();
  const [filters, setFilters] = useState(null);
  const colorButtonRelevant = useColorModeValue('white', 'Black');
  const colorButtonNotRelevant = useColorModeValue('#757575', '#DBDBDB');
  const [totalBranches, setTotalBranches] = useState(0);
  const [categories, setCategories] = useState([]);
  const [CSVdata, setCSVdata] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const [isLoadingProvinces, setIsLoadingProvinces] = useState(false);
  const [isOperative, setIsOperative] = useState(false);
  const [colorsProvinces, setColorsProvinces] = useState([]);
  const [CSVExportName, setCSVExportName] = useState('Métricas de precios.csv');
  const mainColor = colors.generic['1'];

  const [steps, setSteps] = useState([
    {
      target: '.my-first-step',
      content: (
        <Text as="b" fontSize="18px">
          Bienvenido al detalle de producto, aquí encontrarás toda la
          información asociada a un producto y sus precios.
        </Text>
      ),
    },
    {
      target: '.my-second-step',
      content: (
        <Text as="b" fontSize="18px">
          Tendrás disponible los precios del producto en forma de lista.
        </Text>
      ),
    },
    {
      target: '.my-third-step',
      content: (
        <Text as="b" fontSize="18px">
          {' '}
          Cuentas con diferentes filtros para los precios y así lograr una
          búsqueda mas precisa.
        </Text>
      ),
    },
    {
      target: '.my-fourth-step',
      content: (
        <Text as="b" fontSize="18px">
          Tambien podrás observar los precios en forma de gráfico.
        </Text>
      ),
    },
    {
      target: '.my-seventh-step',
      content: (
        <Text as="b" fontSize="18px">
          Tendrás disponible un gráfico de moda por provincia, identificado por
          color.
        </Text>
      ),
    },
    {
      target: '.my-fifth-step',
      content: (
        <Text as="b" fontSize="18px">
          Haciendo clic en este botón, se mostrará toda la información del
          producto, incluyendo las categorias a las que pertenece.{' '}
        </Text>
      ),
    },
    {
      target: '.my-sixth-step',
      content: (
        <Text as="b" fontSize="18px">
          Haciendo clic en este botón, podrás exportar los precios filtrados del
          producto en un archivo CSV
        </Text>
      ),
    },
  ]);
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [changeDisabled, setChangeDisabled] = useState(true);
  let param = location.pathname.split('/').pop();

  useEffect(() => {
    setIsLoading(true);
    apiHelpers.getCurrentUser().then(response => {
      setCurrentUser(response.data);
      setRunOnboarding(response.data.onboardingProductDetail);
      response.data.role == 'OPERATIVE'
        ? setIsOperative(true)
        : setIsOperative(false);
    });
    getProduct();
    getBranches();
    getCategories();
    if (window.navigator.userAgentData.mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    if (CSVdata.length) {
      CSVdata.length && download.current.link.click();
      setCSVdata([]);
    }
  }, [CSVdata]);

  useEffect(() => {
    if (filters && filters.where.productEan && !branchContext.isLoading) {
      getBranches();
      getPriceMetrics();
      getPriceMetricsByProvince();
    }
  }, [filters, branchContext]);

  const onChangeHandler = e => {
    let value = e.target.value;
    let field = e.target.name;
    setProductDetails({
      ...product,
      [field]: value,
    });
  };

  const availableChanges = () => {
    setChangeDisabled(false);
  };
  const disableChanges = () => {
    setChangeDisabled(true);
  };

  const patchProduct = async () => {
    await apiHelpers.patchProductDetails(product.productEan, {
      relevant: productDetails.relevant,
      productBrand: productDetails.productBrand,
      productName: productDetails.productName,
      productFormat: productDetails.productFormat,
    });
    getProduct();
  };

  const getProduct = () => {
    try {
      apiHelpers.getProduct(param).then(response => {
        setProduct(response.data);
        setProductDetails(response.data);
        console.log('PRODUCTO: ', response.data);
        setIsLoading(false);
        disableChanges();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = () => {
    apiHelpers
      .getCategories({
        limit: 10000,
      })
      .then(response => {
        setCategories(response.data);
        console.log('CATEGORIAS: ', response.data);
      });
  };

  const filterBranches = () => {
    if (!filters) {
      return [];
    }
    const filteredBranches = branchContext.allBranches.filter(branch => {
      if (
        filters.where.storeBrand &&
        filters.where.storeBrand !== branch.storeBrand
      )
        return false;
      if (filters.where.province && filters.where.province !== branch.province)
        return false;
      if (
        filters.where.branchType &&
        filters.where.branchType !== branch.branchType
      )
        return false;
      return true;
    });
    return filteredBranches;
  };

  const getBranches = () => {
    setTotalBranches(filterBranches().length);
  };

  const exportPrices = async prices => {
    const fields = [
      'EAN',
      'Marca',
      'Descripción',
      'Formato',
      'Categoria 1',
      'Categoria 2',
      'Categoria 3',
      'Categoria 4',
      'Fecha',
      'Precio mínimo',
      'Precio máximo',
      'Precio medio',
      'ID de sucursal',
      'Tipo de sucursal',
      'Cadena de sucursal',
      'Provincia',
      'Localidad',
      'Dirección',
      'Latitud',
      'Longitud',
      'Denominacion Legal',
    ];
    let outputPrices = [];
    let branches = branchContext.allBranches;

    let cat_1 = categories.find(c => c.categoryInternalId == product.cat_1);
    let cat_2 = categories.find(c => c.categoryInternalId == product.cat_2);
    let cat_3 = categories.find(c => c.categoryInternalId == product.cat_3);
    let cat_4 = categories.find(c => c.categoryInternalId == product.cat_4);

    for (let price of prices) {
      const branch = branches.find(b => price.branchId == b.id);
      outputPrices[price.id] = {
        EAN: price.productEan + '\t',
        Marca:
          product.ProductBrand !== 'undefined'
            ? product.productBrand
            : 'Sin marca',
        Descripción: product.productName,
        Formato: product.productFormat,
        'Categoria 1': cat_1.categoryName,
        'Categoria 2': cat_2.categoryName,
        'Categoria 3': cat_3.categoryName,
        'Categoria 4': cat_4.categoryName,
        Fecha: formatter.formatDate(price.createdAt),
        'Precio mínimo': formatter.formatNumberDecimals(price.minPrice, 2),
        'Precio máximo': formatter.formatNumberDecimals(price.maxPrice, 2),
        'Precio medio': formatter.formatNumberDecimals(price.medPrice, 2),
        'ID de sucursal': price.branchId,
        'Tipo de sucursal': price.branchType,
        'Cadena de sucursal': price.storeBrand,
        Provincia: price.province,
        Localidad: branch ? branch.locality : '',
        Dirección: branch ? branch.address : '',
        Latitud: branch ? branch.lat : '',
        Longitud: branch ? branch.lng : '',
        'Denominacion Legal': branch ? branch.storeLegalName : '',
      };
    }
    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputPrices);

    setCSVdata(csv);
  };

  const getPrices = async date => {
    try {
      onOpen();
      let filtersWithLimit = {
        where: {
          productEan: product.productEan,
          createdAt: moment(date).format('YYYY-MM-DD'),
        },
        limit: 10000,
      };
      const pricesResponse = await apiHelpers.getPrices(filtersWithLimit);
      const pricesA = pricesResponse.data;
      if (pricesA.length) {
        setCSVExportName(
          `Precios producto ${product.productEan} día ${moment(date).format(
            'L',
          )}`,
        );
        await exportPrices(pricesA);
      }
      onClose();
    } catch (error) {
      console.log(error);
      onClose();
    }
  };

  const exportPriceMetrics = async () => {
    onOpen();
    const fields = [
      'EAN',
      'Marca',
      'Descripción',
      'Formato',
      'Notificar',
      'Categoria 1',
      'Categoria 2',
      'Categoria 3',
      'Categoria 4',
      'Fecha',
      'Precio mínimo',
      'Precio máximo',
      'Precio medio',
      'Moda',
      'Cobertura',
      'Cobertura (%)',
      'Provincia',
      'Canal',
    ];
    let outputPrices = [];

    let cat_1 = categories.find(c => c.categoryInternalId == product.cat_1);
    let cat_2 = categories.find(c => c.categoryInternalId == product.cat_2);
    let cat_3 = categories.find(c => c.categoryInternalId == product.cat_3);
    let cat_4 = categories.find(c => c.categoryInternalId == product.cat_4);

    for (let price of priceMetrics) {
      outputPrices[price.id] = {
        EAN: product.productEan + '\t',
        Marca:
          product.ProductBrand !== 'undefined'
            ? product.productBrand
            : 'Sin marca',
        Descripción: product.productName,
        Formato: product.productFormat,
        Notificar: product.relevant ? 'Si' : 'No',
        'Categoria 1': cat_1.categoryName,
        'Categoria 2': cat_2.categoryName,
        'Categoria 3': cat_3.categoryName,
        'Categoria 4': cat_4.categoryName,
        Fecha: formatter.formatDate(price.metric_date),
        'Precio mínimo': formatter.formatNumberDecimals(price.minPrice, 2),
        'Precio máximo': formatter.formatNumberDecimals(price.maxPrice, 2),
        'Precio medio': formatter.formatNumberDecimals(price.medPrice, 2),
        Moda: formatter.formatNumberDecimals(price.modePrice, 2),
        Cobertura: price.coverage + ' / ' + totalBranches + '\t',
        'Cobertura (%)':
          totalBranches == 0
            ? 'Sin cobertura'
            : formatter.formatNumberDecimals(
                (price.coverage / totalBranches) * 100,
                3,
              ) + '%',
        Provincia: price.province ? price.province : 'Todos',
        Canal: price.branchType ? price.branchType : 'Todos',
      };
    }
    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputPrices);
    setCSVExportName(
      `Métricas producto ${product.productEan} del ${moment(
        filters.where.day_from,
      ).format('L')} al ${moment(filters.where.day_to).format('L')}`,
    );
    setCSVdata(csv);
    onClose();
  };

  const getRandomColor = () => {
    let R = Math.floor(Math.random() * 127 + 90);
    let G = Math.floor(Math.random() * 127 + 90);
    let B = Math.floor(Math.random() * 127 + 90);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  const getPriceMetrics = () => {
    setIsLoadingPrices(true);
    try {
      let filtersWithLimit = {
        ...filters,
        limit: 1000,
      };

      apiHelpers.getPricesMetrics(filtersWithLimit).then(response => {
        if (!response.data.length) {
          setPriceMetrics([]);
          setIsLoadingPrices(false);
          return;
        }
        console.log('PRICE METRICS: ', response.data);
        setPriceMetrics(
          response.data.sort((a, b) =>
            moment(a.metric_date).isBefore(moment(b.metric_date)) ? 1 : -1,
          ),
        );
        setIsLoadingPrices(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getPriceMetricsByProvince = () => {
    setIsLoadingProvinces(true);
    try {
      let filtersWithLimit = {
        ...filters,
        limit: 1000,
      };

      filtersWithLimit.where.provinceArray = 'ALL';
      apiHelpers.getPricesMetrics(filtersWithLimit).then(response => {
        if (!response.data.length) {
          setPriceMetricsByProvince([]);
          setIsLoadingProvinces(false);
          return;
        }
        let data = response.data;
        console.log('PRICE METRICS: ', response.data);
        data = data.sort(function (a, b) {
          if (a.province.toLowerCase() < b.province.toLowerCase()) {
            return -1;
          }
          if (a.province.toLowerCase() < b.province.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        let pricePerProvince = response.data.filter((item, index) => {
          let lastIndex = index == 0 ? index : index - 1;
          return (
            item.province !== data[lastIndex].province ||
            data.indexOf(item) == 0
          );
        });
        let colors = colorsProvinces;
        let pricesForChart = [];

        for (let priceProvince of pricePerProvince) {
          let newColor = getRandomColor();
          let prices = [];

          prices = response.data.filter(price => {
            if (price.province === priceProvince.province) {
              price.color = newColor;
              return price;
            }
          });

          prices.length && prices[0]?.color && pricesForChart.push(prices);
        }

        let seriesData = [];
        pricesForChart.map(prices => {
          let data = [];
          prices.map(price => {
            data.push({
              max: price.maxPrice,
              min: price.minPrice,
              media: price.medPrice,
              fecha: moment(price.metric_date)
                .startOf('day')
                .toDate()
                .getTime(),
              moda: price.modePrice,
              color: price.color,
              province: price.province,
            });
          });
          seriesData.push(data);
        });

        setColorsProvinces(colors);
        setPriceMetricsByProvince(seriesData);
        setIsLoadingProvinces(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const buttonExportPrices = (
    <Box className="my-sixth-step" minW={'220px'}>
      <Button
        bg="white"
        color={mainColor}
        w={'320px'}
        h={'45px'}
        onClick={() => {
          onOpen();
          setTimeout(() => {
            exportPriceMetrics();
            onClose();
          });
        }}
        type="submit"
        isLoading={isLoading || isLoadingPrices || isLoadingProvinces}>
        <Text px={2} display={{ base: 'none', sm: 'block' }}>
          Exportar precios
        </Text>
        <DownloadIcon />
      </Button>
    </Box>
  );

  const ButtonShowDetail = (
    <Box className="my-fifth-step" minW={'220px'}>
      <Button
        _hover={{
          fontSize: '15px',
        }}
        fontSize="16px"
        w="full"
        colorScheme="orange"
        onClick={() => setShowDetail(!showDetail)}
        type="submit">
        <Text mx={2} display={{ base: 'none', sm: 'block' }}>
          {showDetail ? 'Ocultar detalle' : 'Mostrar detalle'}
        </Text>
        {showDetail ? <ViewOffIcon /> : <ViewIcon />}
      </Button>
    </Box>
  );
  const handleJoyrideCallback = data => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunOnboarding(false);
      let user = currentUser;
      apiHelpers.patchUser(user.id, {
        username: user.username,
        role: user.role,
        onboardingComprativeScreen: user.onboardingComprativeScreen,
        onboardingProduct: user.onboardingProduct,
        onboardingProductDetail: false,
        onboardingCategories: user.onboardingCategories,
        onboardingProcesses: user.onboardingProcesses,
      });
    }
  };
  return (
    <Flex
      direction={'column'}
      minH="94vh"
      minW={{ lg: '200vh' }}
      pb={8}
      w={'100%'}
      justify="center">
      <VStack w={'100%'}>
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={runOnboarding}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          locale={{
            next: <span>Continuar</span>,
            skip: '',
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              backgroundColor: mainColor,
              color: 'white',
            },
            buttonBack: {
              color: '#000000',
            },
            buttonClose: {
              backgroundColor: '#008080',
              color: '#000000',
            },
          }}
        />
        {!isLoading && (
          <Box w="full">
            <Center bgColor={mainColor} mb={1} py={4}>
              <Heading className="my-first-step" color="white">
                {product.productName + ' (' + product.productEan + ')'}
              </Heading>
            </Center>
            <CSVLink
              ref={download}
              data={CSVdata}
              filename={CSVExportName}
              className="hidden"></CSVLink>
            <Flex
              direction={'column'}
              bgColor={mainColor}
              justify={'center'}
              alignItems={'center'}>
              <Flex
                bgColor={mainColor}
                pt={{ base: '10px', lg: 16 }}
                pb={{ base: '8px', lg: 7 }}
                gap={{ base: '10px', lg: 14 }}
                justify={'center'}
                alignItems={'center'}
                direction={{ base: 'column', lg: 'row' }}>
                <Popover
                  placement="bottom-end"
                  border="4px solid"
                  borderColor="primary.200"
                  _focus={{
                    border: '4px solid',
                    borderColor: 'primary.200',
                  }}>
                  <PopoverTrigger>
                    <Button
                      variant="secondary-button"
                      onClick={() => {
                        setShowDetail(!showDetail);
                      }}>
                      <Text mr={2}>Mostrar detalles</Text>
                      {!showDetail ? (
                        <i className="fa fa-solid fa-eye"></i>
                      ) : (
                        <i className="fa fa-solid fa-eye-slash"></i>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    bg="white"
                    color="black"
                    w={['330px', '480px', '630px', '980px', '1230px']}
                    border="4px solid"
                    borderColor={'primary.200'}
                    _focus={{
                      border: '4px solid',
                      borderColor: 'primary.200',
                    }}>
                    <PopoverHeader as="Flex" align="center">
                      <Heading>Detalles</Heading>
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody
                      w={['300px', '450px', '600px', '950px', '1200px']}>
                      <SimpleGrid row={3} columns={{ base: 1, sm: 2, lg: 3 }}>
                        <Box mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            EAN
                          </Text>
                          <Text
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            p={2}
                            minH={'40px'}
                            placeholder="EAN"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}>
                            {product.productEan}
                          </Text>
                        </Box>
                        <Box mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Fecha de alta
                          </Text>
                          <Text
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            p={2}
                            minH={'40px'}
                            placeholder="EAN"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}>
                            {formatter.formatDateTime(product.createdAt)}
                          </Text>
                        </Box>
                        <Box mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Formato
                          </Text>
                          <Input
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            bg={bgFocus}
                            name="productFormat"
                            p={2}
                            minH={'40px'}
                            placeholder="Formato"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}
                            value={productDetails.productFormat}
                            onChange={onChangeHandler}
                            disabled={changeDisabled}
                            _disabled={{
                              bg: 'white',
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                              cursor: 'default',
                            }}></Input>
                        </Box>

                        <Box my={1} mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Categoria 1
                          </Text>
                          <Text
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            minH={'40px'}
                            maxH={'60px'}
                            placeholder="Formato"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}>
                            {' '}
                            <SelectCategoryDetailProduct
                              placeholder="Categoria 1"
                              level={1}
                              isDisabled={true}
                              value={product.cat_1}
                              name={'cat_1'}></SelectCategoryDetailProduct>
                          </Text>
                        </Box>

                        <Box my={1} mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Categoria 2
                          </Text>
                          <Text
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            minH={'40px'}
                            placeholder="Formato"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}>
                            {' '}
                            <SelectCategoryDetailProduct
                              placeholder="Categoria 2"
                              level={2}
                              isDisabled={true}
                              value={product.cat_2}
                              name={'cat_2'}></SelectCategoryDetailProduct>
                          </Text>
                        </Box>

                        <Box my={1} mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Categoria 3
                          </Text>
                          <Text
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            minH={'40px'}
                            placeholder="Formato"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}>
                            {' '}
                            <SelectCategoryDetailProduct
                              placeholder="Categoria 3"
                              level={3}
                              isDisabled={true}
                              value={product.cat_3}
                              name={'cat_3'}></SelectCategoryDetailProduct>
                          </Text>
                        </Box>

                        <Box my={1} mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Categoria 4
                          </Text>
                          <Text
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            minH={'40px'}
                            placeholder="Formato"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}>
                            <SelectCategoryDetailProduct
                              placeholder="Categoria 4"
                              level={4}
                              isDisabled={true}
                              value={product.cat_4}
                              name={'cat_4'}></SelectCategoryDetailProduct>
                          </Text>
                        </Box>
                        <Box my={1} mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Marca
                          </Text>
                          <Input
                            borderRadius={'md'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            p={2}
                            minH={'40px'}
                            placeholder="Marca"
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}
                            value={productDetails.productBrand}
                            onChange={onChangeHandler}
                            disabled={changeDisabled}
                            name="productBrand"
                            _disabled={{
                              bg: 'white',
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                              cursor: 'default',
                            }}
                            bg={bgFocus}></Input>
                        </Box>
                        <Box my={1} mr={2}>
                          <Text ml={1} as="b" color="#4A5568">
                            Descripción
                          </Text>
                          <Input
                            p={2}
                            minH={'40px'}
                            borderRadius={'md'}
                            whiteSpace={'normal'}
                            border={'1px solid'}
                            borderColor={color}
                            _placeholder={{
                              color: color,
                            }}
                            _focus={{
                              bg: bgFocus,
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}
                            _disabled={{
                              bg: 'white',
                              color: 'black',
                              border: '1.3px solid',
                              borderColor: color,
                              boxShadow: 'none',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                              cursor: 'default',
                            }}
                            bg={bgFocus}
                            variant="outline"
                            placeholder="Descripción"
                            value={productDetails.productName}
                            onChange={onChangeHandler}
                            name="productName"
                            disabled={changeDisabled}></Input>
                        </Box>
                        <Box mr={2} w={'100%'}>
                          <Text ml={1} as="b" color="#4A5568">
                            Notificar
                          </Text>
                          <Text
                            borderRadius={'md'}
                            _placeholder={{
                              color: color,
                            }}
                            minH={'40px'}>
                            <ButtonGroup
                              spacing={0}
                              gap={0}
                              isDisabled={isOperative}
                              bg={'grey'}
                              w="full"
                              borderRadius="lg"
                              isAttached={true}>
                              <Button
                                onClick={async () => {
                                  await apiHelpers.patchProduct(
                                    product.productEan,
                                    {
                                      relevant: true,
                                    },
                                  );
                                  getProduct();
                                }}
                                variant={
                                  product.relevant
                                    ? 'graphic-button-active'
                                    : 'graphic-button-inactive'
                                }>
                                <Text
                                  color={
                                    product.relevant
                                      ? colorButtonRelevant
                                      : colorButtonNotRelevant
                                  }>
                                  Si
                                </Text>
                              </Button>
                              <Button
                                onClick={async () => {
                                  await apiHelpers.patchProduct(
                                    product.productEan,
                                    {
                                      relevant: false,
                                    },
                                  );
                                  getProduct();
                                }}
                                variant={
                                  !product.relevant
                                    ? 'graphic-button-active'
                                    : 'graphic-button-inactive'
                                }>
                                <Text
                                  color={
                                    !product.relevant
                                      ? colorButtonRelevant
                                      : colorButtonNotRelevant
                                  }>
                                  No
                                </Text>
                              </Button>
                            </ButtonGroup>
                          </Text>
                        </Box>
                      </SimpleGrid>
                      {apiHelpers.isAdmin() ? (
                        <Box mt={2}>
                          <ButtonGroup textAlign={'right'}>
                            <Button
                              onClick={
                                changeDisabled
                                  ? availableChanges
                                  : disableChanges
                              }
                              variant="primary-button">
                              {changeDisabled
                                ? 'Habilitar edición'
                                : 'Desabilitar edición'}
                            </Button>
                            <Button
                              onClick={patchProduct}
                              variant="primary-button">
                              Guardar cambios
                            </Button>
                          </ButtonGroup>
                        </Box>
                      ) : (
                        ''
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                <Popover>
                  <PopoverTrigger>
                    <Button variant="secondary-button">
                      <Text mr={2}>Filtros</Text>
                      <i class="fa fa-solid fa-list"></i>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    bg="white"
                    color="black"
                    w={['350px', '450px', '700px', '1000px', '1000px']}
                    border="4px solid"
                    borderColor={'primary.200'}
                    _focus={{
                      border: '4px solid',
                      borderColor: 'primary.200',
                    }}>
                    <PopoverHeader as="Flex" align="center">
                      <Heading>Filtros</Heading>
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody
                      w={['330px', '450px', '700px', '1000px', '1000px']}>
                      <ProductDetailFilters
                        // extraButtons={[ButtonShowDetail, buttonExportPrices]}
                        ean={param}
                        loadData={e => handleChangeFilters(e)}
                        isLoading={
                          isLoadingPrices ||
                          isLoadingProvinces ||
                          isLoading ||
                          branchContext.isLoading
                        }
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                {buttonExportPrices}
              </Flex>
              <Text mb={6} fontSize={'lg'} color={'white'}>
                {filters
                  ? `Información entre el ${formatter.formatDate(
                      filters.where.day_from,
                    )} y el ${formatter.formatDate(filters.where.day_to)}`
                  : ''}
              </Text>
            </Flex>
          </Box>
        )}

        <Box w="full">
          {!isLoading && !branchContext.isLoading ? (
            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
              <GridItem
                colStart={{ base: 1, sm: 2, lg: 1 }}
                m={{ base: 2, sm: 0, lg: 0 }}
                colEnd={{ base: 6, sm: 5, lg: 4 }}>
                <Box
                  bgColor={boxBg}
                  boxShadow={boxShadow}
                  borderRadius="lg"
                  h="full"
                  my={2}>
                  <Flex w={'100%'}>
                    <VStack
                      w={'100%'}
                      m={{ base: 0, md: 2, lg: 4, xl: 5 }}
                      direction="column"
                      py={0}>
                      <Box className="my-fourth-step" w="full">
                        <Text align={'left'} as="b" color="#CCCCCC">
                          Gráfico de precios
                        </Text>
                      </Box>
                      <Divider />

                      {priceMetrics.length && !isLoadingPrices ? (
                        <PriceChart
                          colorText={color}
                          data={priceMetrics}
                          totalBranches={totalBranches}
                          initialDate={filters.where.day_from}
                          finishDate={filters.where.day_to}
                        />
                      ) : isLoadingPrices ? (
                        <Center minW={'full'}>
                          <Text fontSize={20} textAlign={'center'} p={6}>
                            <Flex
                              align="center"
                              justify="center"
                              flexDirection="column">
                              <Spinner size="lg" />
                              Cargando precios...
                            </Flex>
                          </Text>
                        </Center>
                      ) : (
                        <Center minW={'full'}>
                          <Text fontSize={20} textAlign={'center'} p={6}>
                            No se encontraron precios para el gráfico
                          </Text>
                        </Center>
                      )}
                    </VStack>
                  </Flex>
                </Box>
              </GridItem>

              <GridItem
                m={{ base: 2, sm: 0, lg: 0 }}
                colStart={{ base: 1, sm: 2, lg: 4 }}
                colEnd={{ base: 6, sm: 5, lg: 6 }}>
                <Box
                  mt={{ base: 2 }}
                  bgColor={boxBg}
                  boxShadow={boxShadow}
                  borderRadius="lg"
                  h="full">
                  <VStack
                    m={{ base: 0, md: 2, lg: 0, xl: 2 }}
                    direction="column">
                    <Box
                      mt={{ base: 0, md: 2, lg: 4, xl: 5 }}
                      ml={{ base: 0, md: 2, lg: 4, xl: 5 }}
                      w="full">
                      <Text align={'left'} as="b" color="#CCCCCC">
                        Listado de precios
                      </Text>
                    </Box>
                    <Divider />

                    <TableContainer
                      className="my-second-step"
                      overflowX={isMobile ? 'scroll' : 'auto'}
                      w="full">
                      <Table size="ssm">
                        {priceMetrics.length && !isLoadingPrices ? (
                          <>
                            <Thead>
                              <Tr pl="0px">
                                <Th isNumeric={true} fontSize={10}>
                                  Fecha
                                </Th>
                                <Th isNumeric={true} fontSize={10} p={1}>
                                  Mínimo
                                </Th>
                                <Th isNumeric={true} fontSize={10} p={1}>
                                  Máximo
                                </Th>
                                <Th isNumeric={true} fontSize={10} p={1}>
                                  Medio
                                </Th>
                                <Th isNumeric={true} fontSize={10} p={1}>
                                  Moda
                                </Th>
                                <Th isNumeric={true} fontSize={10}>
                                  Cobertura
                                </Th>
                                <Th isNumeric={true} fontSize={10} p={1}>
                                  Cobertura(%)
                                </Th>
                                <Th isNumeric={false} fontSize={10} p={1}>
                                  Precios
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {priceMetrics.map(price => {
                                return (
                                  <Tr
                                    key={price.id}
                                    my="0rem"
                                    mx="0rem"
                                    pl="0px">
                                    <Td isNumeric={true} fontSize={12}>
                                      {moment(price.metric_date).format(
                                        'DD/MM',
                                      )}
                                    </Td>
                                    <Td
                                      fontSize={12}
                                      p={1}
                                      color="red"
                                      isNumeric={true}>
                                      {'$' +
                                        formatter.formatNumberDecimals(
                                          price.minPrice,
                                          2,
                                        )}
                                    </Td>
                                    <Td
                                      fontSize={12}
                                      p={1}
                                      color="blue"
                                      isNumeric={true}>
                                      {'$' +
                                        formatter.formatNumberDecimals(
                                          price.maxPrice,
                                          2,
                                        )}
                                    </Td>
                                    <Td
                                      fontSize={12}
                                      p={1}
                                      color="green"
                                      isNumeric={true}>
                                      {'$' +
                                        formatter.formatNumberDecimals(
                                          price.medPrice,
                                          2,
                                        )}
                                    </Td>
                                    <Td
                                      fontSize={12}
                                      isNumeric={true}
                                      color="purple">
                                      {'$' +
                                        formatter.formatNumberDecimals(
                                          price.modePrice,
                                          2,
                                        )}
                                    </Td>
                                    <Td fontSize={12} isNumeric={true}>
                                      {price.coverage +
                                        ' / ' +
                                        (price.totalCoverage
                                          ? price.totalCoverage
                                          : totalBranches)}
                                    </Td>
                                    <Td fontSize={12} p={1} isNumeric={true}>
                                      {totalBranches == 0
                                        ? 'Sin cobertura'
                                        : formatter.formatNumberDecimals(
                                            (price.coverage /
                                              (price.totalCoverage
                                                ? price.totalCoverage
                                                : totalBranches)) *
                                              100,
                                            2,
                                          ) + '%'}
                                    </Td>
                                    <Td fontSize={12} p={1} isNumeric={false}>
                                      <Button
                                        p="0px"
                                        bg="transparent"
                                        onClick={() =>
                                          getPrices(price.metric_date)
                                        }>
                                        <DownloadIcon
                                          w={6}
                                          h={6}
                                          color="generic.1"
                                          cursor="pointer"
                                        />
                                      </Button>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </>
                        ) : isLoadingPrices ? (
                          <Stack my={3} w="full">
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                          </Stack>
                        ) : (
                          !priceMetrics.length && (
                            <Center minW={'full'}>
                              <Text fontSize={20} textAlign={'center'} p={6}>
                                No se encontraron precios
                              </Text>
                            </Center>
                          )
                        )}
                      </Table>
                    </TableContainer>
                  </VStack>
                </Box>
              </GridItem>
              <GridItem
                colStart={{ base: 1, sm: 2, lg: 1 }}
                m={{ base: 2, sm: 0, lg: 0 }}
                colEnd={{ base: 6, sm: 6, lg: 6 }}>
                <Box
                  bgColor={boxBg}
                  boxShadow={boxShadow}
                  borderRadius="lg"
                  h="full"
                  my={2}>
                  <Flex w={'100%'}>
                    <VStack
                      w={'100%'}
                      m={{ base: 0, md: 2, lg: 4, xl: 5 }}
                      direction="column"
                      py={0}>
                      <Box className="my-seventh-step" w="full">
                        <Text align={'left'} as="b" color="#CCCCCC">
                          Gráfico de moda por provincia
                        </Text>
                      </Box>
                      <Divider />
                      {priceMetricsByProvince.length && !isLoadingProvinces ? (
                        <PriceModaByProvinceChart
                          colorText={color}
                          data={priceMetricsByProvince}
                          initialDate={filters.where.day_from}
                          finishDate={filters.where.day_to}
                        />
                      ) : isLoadingPrices ? (
                        <Center minW={'full'}>
                          <Text fontSize={20} textAlign={'center'} p={6}>
                            <Flex
                              align="center"
                              justify="center"
                              flexDirection="column">
                              <Spinner size="lg" />
                              Cargando precios...
                            </Flex>
                          </Text>
                        </Center>
                      ) : (
                        <Center minW={'full'}>
                          <Text fontSize={20} textAlign={'center'} p={6}>
                            No se encontraron precios para el gráfico
                          </Text>
                        </Center>
                      )}
                    </VStack>
                  </Flex>
                </Box>
              </GridItem>
            </Grid>
          ) : (
            <Box my={'15%'} h={'150px'}>
              <Center>
                <Stack>
                  {' '}
                  <CircularProgress
                    isIndeterminate
                    color="red"
                    size="120px"
                    thickness="3px"
                  />
                </Stack>
              </Center>
            </Box>
          )}
        </Box>
      </VStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex align="center" justify="center" flexDirection="column">
              <Spinner size="lg" />
              ...Exportando archivo
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
export default ProductDetail;
