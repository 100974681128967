import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './components/app/App';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import * as serviceWorker from './serviceWorker';

render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
