import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useColorMode } from '@chakra-ui/react';
import moment from 'moment';
import formatter from '../../utils/formatter';

const PriceChart = props => {
  const color = useColorMode('black', 'lightgrey');
  const [data, setData] = useState([]);
  const [seriesA, setSerieA] = useState(null);
  const [seriesB, setSerieB] = useState(null);
  const [seriesC, setSerieC] = useState(null);
  const [seriesD, setSerieD] = useState(null);
  const [series, setSeries] = useState([]);
  console.log(data);
  useEffect(() => {
    am5.ready(function () {
      let root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          pinchZoomX: true,
        }),
      );

      let xRenderer = am5xy.AxisRendererX.new(root, {});
      xRenderer.grid.template.setAll({ forceHidden: true, fill: '#888888' });
      xRenderer.labels.template.setAll({
        multiLocation: 0,
        location: 0,
        fill: '#888888',
      });

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'hour', count: 10 },
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
          extraMin: 0.01,
          extraMax: 0.01,
          tooltipLocation: 0,
        }),
      );

      let yRenderer = am5xy.AxisRendererY.new(root, {});
      yRenderer.grid.template.setAll({ forceHidden: true, fill: '#888888' });
      yRenderer.labels.template.setAll({
        multiLocation: 0,
        location: 0,
        fill: '#888888',
      });

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          renderer: yRenderer,
        }),
      );
      let yRenderer2 = am5xy.AxisRendererY.new(root, {
        opposite: true,
      });
      let yAxis2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer2,
          min: 0,
          max: 120,
          syncWithAxis: yAxis,
          strictMinMax: true,
        }),
      );
      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
          xAxis: xAxis,
        }),
      );
      cursor.lineX.set('stroke', '#888888');
      cursor.lineY.set('stroke', '#888888');

      let seriesA = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'media',
          valueXField: 'fecha',
          locationX: 0,
          stroke: 'green',

          seriesTooltipTarget: 'bullet',
          showTooltipOn: 'always',
          tooltip: am5.Tooltip.new(root, {
            labelText: '${valueY}',
          }),
        }),
      );

      let seriesB = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          stroke: 'red',
          valueYField: 'min',
          valueXField: 'fecha',
          locationX: 0,
          seriesTooltipTarget: 'bullet',
          tooltip: am5.Tooltip.new(root, {
            labelText: '${valueY}',
          }),
        }),
      );

      let seriesC = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'max',
          valueXField: 'fecha',
          locationX: 0,
          tooltip: am5.Tooltip.new(root, {
            labelText: '${valueY}',
          }),
        }),
      );

      let seriesD = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'moda',
          valueXField: 'fecha',
          locationX: 0,
          tooltip: am5.Tooltip.new(root, {
            labelText: '${valueY}',
          }),
        }),
      );

      seriesA.bullets.push(function () {
        let circleTemplate = am5.Template.new({
          radius: 6,
          templateField: 'bulletSettings',
          fill: 'green',
          strokeWidth: 2,
          tooltipText: '${valueY}',
          stroke: root.interfaceColors.get('background'),
        });

        let circle = am5.Circle.new(root, {}, circleTemplate);

        return am5.Bullet.new(root, {
          sprite: circle,
          locationX: 0,
        });
      });

      seriesB.bullets.push(function () {
        let circleTemplate = am5.Template.new({
          radius: 6,
          templateField: 'bulletSettings',
          fill: 'red',
          strokeWidth: 2,
          tooltipText: '${valueY}',
          stroke: root.interfaceColors.get('background'),
        });

        let circle = am5.Circle.new(root, {}, circleTemplate);

        return am5.Bullet.new(root, {
          sprite: circle,
          locationX: 0,
        });
      });

      seriesC.bullets.push(function () {
        let circleTemplate = am5.Template.new({
          radius: 6,
          templateField: 'bulletSettings',
          fill: seriesC.get('fill'),
          strokeWidth: 2,
          tooltipText: '${valueY}',
          stroke: root.interfaceColors.get('background'),
        });

        let circle = am5.Circle.new(root, {}, circleTemplate);

        return am5.Bullet.new(root, {
          sprite: circle,
          locationX: 0,
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}',
          }),
        });
      });

      seriesD.bullets.push(function () {
        let circleTemplate = am5.Template.new({
          radius: 6,
          templateField: 'bulletSettings',
          fill: 'purple',
          strokeWidth: 2,
          tooltipText: '${valueY}',
          stroke: root.interfaceColors.get('background'),
        });

        let circle = am5.Circle.new(root, {}, circleTemplate);

        return am5.Bullet.new(root, {
          sprite: circle,
          locationX: 0,
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}',
          }),
        });
      });

      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'series',
          xAxis: xAxis,
          yAxis: yAxis2,
          valueYField: 'coverage',
          valueXField: 'fecha',
          stroke: 'green',
          fill: 'green',
          categoryXField: 'fecha',
        }),
      );

      series.columns.template.setAll({
        tooltipText: '{coverageTooltip}' + '% ',
        width: am5.percent(90),
        tooltipY: 0,
      });

      series.data.setAll(data);
      series.appear(1000);
      seriesA.data.setAll(data);
      seriesA.appear(1000);
      seriesB.data.setAll(data);
      seriesB.appear(1000);
      seriesC.data.setAll(data);
      seriesC.appear(1000);
      seriesD.data.setAll(data);
      seriesD.appear(1000);
      setSerieA(seriesA);
      setSerieB(seriesB);
      setSerieC(seriesC);
      setSerieD(seriesD);
      setSeries(series);

      chart.appear(1000, 100);
      return () => root.dispose();
    });
  }, []);

  useEffect(() => {
    let data = [];
    props.data.map(price => {
      data.push({
        max: price.maxPrice,
        min: price.minPrice,
        media: price.medPrice,
        fecha: moment(price.metric_date).startOf('day').toDate().getTime(),
        moda: price.modePrice,
        coverage: (price.coverage / props.totalBranches) * 100,
        coverageTooltip: formatter.formatNumberDecimals(
          (price.coverage / props.totalBranches) * 100,
          2,
        ),
      });
    });
    data = data.sort((a, b) => {
      if (moment(a.fecha).isBefore(moment(b.fecha))) return 1;
      if (moment(a.fecha).isAfter(moment(b.fecha))) return -1;
      return 0;
    });

    const firstDateData = {
      fecha: moment(props.initialDate).startOf('day').toDate().getTime(),
    };
    data.push(firstDateData);
    const lastDateDate = {
      fecha: moment(props.finishDate).startOf('day').toDate().getTime(),
    };
    data.unshift(lastDateDate);
    if (seriesA !== null) {
      setData(data);
      seriesA.data.setAll(data);
      seriesA.appear(1000);
      seriesB.data.setAll(data);
      seriesB.appear(1000);
      seriesC.data.setAll(data);
      seriesC.appear(1000);
      seriesD.data.setAll(data);
      seriesD.appear(1000);
      setSerieA(seriesA);
      setSerieB(seriesB);
      setSerieC(seriesC);
      setSerieD(seriesD);
      series.data.setAll(data);
      series.appear(1000);
      setSeries(series);
    }
  }, [props.data, seriesA, series]);

  return (
    <div
      id="chartdiv"
      style={{
        width: '100%',
        height: '390px',
        background: color,
      }}
    />
  );
};
export default PriceChart;
