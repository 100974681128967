import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { colors } from '../../../theme';

const SelectProvince = props => {
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState('');
  const [value, setValue] = useState(null);
  const [currentFilters, setCurrentFilters] = useState(null);
  const color = 'black';
  const colorBg = 'white';
  const colorHover = colors.primary['50'];
  const colorHoverOption = colors.primary['200'];
  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value) {
      userValue = options.find(
        option => String(option.value) === String(value),
      );

      userValue ? setValue(userValue) : loadDefaultOptions(value);
    }
    if (!props.value) {
      setValue(null);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.defaultEnable) {
      defaultOptions();
    }
  }, [props.defaultEnable]);

  useEffect(() => {
    defaultOptions();
  }, [props.combinationFilters]);

  const handleChange = selectedValue => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-options-nameless';
    event.target.value = selectedValue.value;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  const defaultOptions = async () => {
    let filters = {
      where: {
        storeBrand: props.combinationFilters.storeBrand
          ? props.combinationFilters.storeBrand
          : null,
        branchType: props.combinationFilters.branchType
          ? props.combinationFilters.branchType
          : null,
        productEan: props.combinationFilters.productEan
          ? props.combinationFilters.productEan
          : null,
        fieldSelected: 'province',
      },
    };

    if (JSON.stringify(filters) == JSON.stringify(currentFilters)) return;

    setCurrentFilters(filters);
    apiHelper
      .getProductFilters(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.province,
            label: entity.province,
          };
        });

        setOptions(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadOptions = (input, callback) => {
    if (!input.length) return;
    let filters = {
      where: {
        storeBrand: props.combinationFilters.storeBrand
          ? props.combinationFilters.storeBrand
          : null,
        branchType: props.combinationFilters.branchType
          ? props.combinationFilters.branchType
          : null,
        productEan: props.combinationFilters.productEan
          ? props.combinationFilters.productEan
          : null,
        province: input,
        fieldSelected: 'province',
      },
    };

    apiHelper
      .getProductFilters(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.province,
            label: entity.province,
          };
        });
        setOptions(entitiesForSelect);
        callback(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadDefaultOptions = value => {
    if (!value) return;
    let filters = {
      where: {
        storeBrand: props.combinationFilters.storeBrand
          ? props.combinationFilters.storeBrand
          : null,
        branchType: props.combinationFilters.branchType
          ? props.combinationFilters.branchType
          : null,
        productEan: props.combinationFilters.productEan
          ? props.combinationFilters.productEan
          : null,
        province: input,
        fieldSelected: 'province',
      },
    };

    apiHelper
      .getProductFilters(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.province,
            label: entity.province,
          };
        });

        setOptions(entitiesForSelect);
        setValue(entitiesForSelect[0]);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const customStyles = {
    control: provide => ({
      ...provide,
      border: '0px solid black',
      backgroundColor: 'none',
      outline: 'none',
      '&:hover': { backgroundColor: colorHover },
    }),
    placeholder: () => ({
      color: color,
      marginLeft: '8px',
      '&:hover': { fontSize: '15px' },
      fontSize: '16px',
    }),

    singleValue: () => ({
      color: color,
    }),

    menuList: provide => ({
      ...provide,
      color: color,
      backgroundColor: colorBg,
    }),
    option: (provider, state) => ({
      ...provider,
      '&:hover': { backgroundColor: colorHoverOption },
      backgroundColor: state.isFocused ? colorHoverOption : 'none',
      color: color,
    }),
  };

  let components = props.isDisabled && {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  return (
    <AsyncSelect
      styles={customStyles}
      onChange={handleChange}
      defaultOptions={options}
      loadOptions={loadOptions}
      value={value}
      name={props.name}
      required={props.required}
      components={components}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder ? props.placeholder : 'Categoria'}
    />
  );
};

export default SelectProvince;
