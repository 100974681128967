import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Tooltip,
  Button,
  useColorModeValue,
  Input,
  SimpleGrid,
  Spacer,
  ButtonGroup,
  InputRightElement,
  InputGroup,
  Center,
  Flex,
} from '@chakra-ui/react';
import {
  DeleteIcon,
  SearchIcon,
  DownloadIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import SelectProvince from '../common/selects/SelectProvince';
import SelectStore from '../common/selects/SelectStore';
import SelectBranch from '../common/selects/SelectBranch';
import SelectDates from '../common/selects/SelectDates';
import moment from 'moment';
import { colors } from '../../theme';

const ProductDetailFilters = props => {
  const color = useColorModeValue('black', 'white');
  const [datesFilter, setDatesFilter] = useState([null, null]);
  const colorHover = useColorModeValue('#DDDDDD', '#6B6B6B');
  const initialState = {
    productEan: props.ean,
    branchType: '',
    province: '',
    storeBrand: '',
    days: 30,
    day_from: '',
    day_to: '',
    coverage: '',
  };
  const [state, setState] = useState(initialState);
  const extraButtons = props.extraButtons;
  const [mounted, setMounted] = useState(false);

  const mainColor = colors.generic['1'];

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      props.loadData(getFiltersObject(state));
    }
  }, [mounted]);

  const onFiltersSubmit = () => {
    props.loadData(getFiltersObject(state, datesFilter));
  };

  const getFiltersObject = (values, datesFilter) => {
    return {
      where: {
        productEan: props.ean,
        branchType: values.branchType ? values.branchType : null,
        province: values.province ? values.province : null,
        storeBrand: values.storeBrand ? values.storeBrand : null,
        coverage: values.coverage ? values.coverage : null,
        day_from:
          datesFilter && datesFilter[0]
            ? moment(datesFilter[0]).toString()
            : moment().subtract(31, 'days').toString(),
        day_to:
          datesFilter && datesFilter[1]
            ? moment(datesFilter[1]).toString()
            : moment().subtract(1, 'days').toString(),
      },
    };
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  const onFiltersReset = () => {
    setState(initialState);
    setDatesFilter([null, null]);
    props.loadData(getFiltersObject(initialState));
  };

  return (
    <Box>
      <SimpleGrid
        spacing={1}
        columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
        rows={{ lg: 2 }}>
        <Tooltip
          label="Filtrar por puntos de venta mínimos"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box my={1}>
            <InputGroup>
              <Input
                value={state.coverage}
                border="2px solid"
                borderColor={mainColor}
                _placeholder={{
                  color: color,
                }}
                _hover={{
                  fontSize: '15px',
                }}
                placeholder="Puntos de venta mínimos"
                name="coverage"
                onChange={handleChange}
                w={'300px'}
                h={'40px'}
              />
              {state.coverage !== '' && (
                <InputRightElement
                  children={
                    <Button
                      p={0}
                      m={0}
                      boxSize={0}
                      onClick={() =>
                        setState(prevState => {
                          return { ...prevState, coverage: '' };
                        })
                      }
                      bg={'none'}>
                      <CloseIcon color="grey" boxSize={3} />
                    </Button>
                  }
                />
              )}
            </InputGroup>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por rango de fecha"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            my={1}
            w="full"
            borderRadius="md"
            border="2px solid"
            borderColor={mainColor}
            _hover={{
              fontSize: '15px',
              bg: colorHover,
            }}
            w={'300px'}
            h={'40px'}>
            <SelectDates
              value={datesFilter}
              onChange={setDatesFilter}
              excludeToday={true}
            />
          </Box>
        </Tooltip>

        <Tooltip
          label="Filtrar por canal"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            my={1}
            fontSize="16px"
            borderRadius="md"
            border="2px solid"
            borderColor={mainColor}
            placeholder="Canal"
            _placeholder={{
              color: color,
            }}
            _hover={{
              fontSize: '15px',
            }}
            id="branchType"
            w={'300px'}
            h={'40px'}>
            <SelectBranch
              placeholder="Canal"
              value={state.branchType}
              combinationFilters={{
                storeBrand: state.storeBrand,
                province: state.province,
                productEan: state.productEan,
              }}
              name={'branchType'}
              onChange={handleChange}></SelectBranch>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por cadena"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            my={1}
            borderRadius="md"
            border="2px solid"
            borderColor={mainColor}
            placeholder="Cadena"
            _placeholder={{
              color: color,
            }}
            fontSize="16px"
            _hover={{
              fontSize: '15px',

              backgroundColor: 'none',
            }}
            id="storeBrand"
            w={'300px'}
            h={'40px'}>
            {' '}
            <SelectStore
              placeholder="Cadena"
              value={state.storeBrand}
              name={'storeBrand'}
              combinationFilters={{
                province: state.province ? state.province : null,
                branchType: state.branchType ? state.branchType : null,
                productEan: state.productEan ? state.productEan : null,
              }}
              onChange={handleChange}></SelectStore>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por provincia"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            my={1}
            fontSize="16px"
            borderRadius="md"
            border="2px solid"
            borderColor={mainColor}
            placeholder="Provincia"
            _placeholder={{
              color: color,
            }}
            _hover={{
              fontSize: '15px',
            }}
            id="province"
            w={'300px'}
            h={'40px'}>
            {' '}
            <SelectProvince
              placeholder="Provincia"
              value={state.province}
              name={'province'}
              combinationFilters={{
                storeBrand: state.storeBrand,
                branchType: state.branchType,
                productEan: state.productEan,
              }}
              onChange={handleChange}></SelectProvince>
          </Box>
        </Tooltip>
      </SimpleGrid>
      <Flex
        gap={{ base: '10px', md: '50px' }}
        direction={{ base: 'column', md: 'row' }}>
        {extraButtons}
        <Box>
          <Button
            variant="reset-filters-button"
            w={'300px'}
            onClick={onFiltersReset}
            type="reset"
            isLoading={props.isLoading}>
            <Text
              color={'black'}
              px={4}
              display={{ base: 'none', sm: 'block' }}>
              Limpiar filtros
            </Text>
            <DeleteIcon color={'black'} />
          </Button>
        </Box>
        <Box>
          <Button
            variant="submit-filters-button"
            w={300}
            onClick={() => onFiltersSubmit()}
            type="submit"
            isLoading={props.isLoading}>
            <Text
              color={'black'}
              px={4}
              display={{ base: 'none', sm: 'block' }}>
              Aplicar filtros
            </Text>
            <SearchIcon color={'black'} />
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default ProductDetailFilters;
