import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';

const SelectCategoryDetailProduct = props => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const color = useColorModeValue('black', 'white');
  const colorBg = useColorModeValue('white', 'black');
  const colorHoverOption = useColorModeValue('#F57978', '#607A7A');

  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value) {
      userValue = options.find(
        option => String(option.value) === String(value),
      );

      userValue ? setValue(userValue) : loadDefaultOptions(value);
    }
    if (!props.value) {
      setValue(null);
    }
  }, [props.value]);

  // useEffect(() => {
  //   defaultOptions();
  // }, []);

  const handleChange = selectedValue => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-options-nameless';
    event.target.value = selectedValue.value;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  const defaultOptions = async () => {
    let filters = {};

    if (props.level !== 'all') {
      filters = {
        where: {
          categoryLevel: props.level,
        },
      };
    }

    apiHelper
      .getCategories(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.categoryInternalId,
            label: entity.categoryName,
          };
        });

        setOptions(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadDefaultOptions = async value => {
    let array = [];
    array.push(value);

    let filters = {
      where: {
        categoryLevel: props.level,
        categoryInternalId: array,
      },
    };

    apiHelper
      .getCategories(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.categoryInternalId,
            label: entity.categoryName,
          };
        });
        setValue(entitiesForSelect[0]);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadOptions = (input, callback) => {
    if (!input.length) return;

    let filters = {
      where: {
        categoryName: input,
        prefix: props.prefix,
      },
    };

    if (props.level !== 'all') {
      filters = {
        where: {
          categoryLevel: props.level,
          prefix: props.prefix,
          categoryName: input,
        },
      };
    }

    apiHelper
      .getCategories(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.categoryInternalId,
            label: entity.categoryName,
          };
        });
        setOptions(
          entitiesForSelect.length
            ? entitiesForSelect.filter(
                entity => entity !== entitiesForSelect[0],
              )
            : entitiesForSelect,
        );
        setValue(entitiesForSelect[0]);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '0px solid black',
      backgroundColor: 'none',
      outline: 'none',
    }),
    placeholder: () => ({
      color: color,
      fontSize: '16px',
      marginLeft: '8px',
      '&:hover': { fontSize: '15px' },
    }),

    singleValue: () => ({
      color: color,
    }),

    menuList: provide => ({
      ...provide,
      color: color,
      backgroundColor: colorBg,
    }),
    option: (provider, state) => ({
      ...provider,
      '&:hover': { backgroundColor: colorHoverOption },
      backgroundColor: state.isFocused ? colorHoverOption : 'none',
      color: color,
    }),
  };

  let components = props.isDisabled && {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  return (
    <AsyncSelect
      styles={customStyles}
      onChange={handleChange}
      defaultOptions={options}
      loadOptions={loadOptions}
      value={value}
      name={props.name}
      required={props.required}
      components={components}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder ? props.placeholder : 'Categoria'}
    />
  );
};

export default SelectCategoryDetailProduct;
