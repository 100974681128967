import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Flex,
  useDisclosure,
  useBreakpointValue,
  useColorModeValue,
  GridItem,
  Divider,
  VStack,
  SimpleGrid,
  Text,
  Button,
  Stack,
  Heading,
  Grid,
  TableContainer,
  Tr,
  Thead,
  Table,
  Th,
  Skeleton,
  Tbody,
  Center,
  Td,
  TableCaption,
  Spacer,
  ModalContent,
  Modal,
  ModalFooter,
  ModalBody,
  CircularProgress,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  IconButton,
  Tooltip,
  ButtonGroup,
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent,
  Switch,
} from '@chakra-ui/react';
import {
  CloseIcon,
  AddIcon,
  MinusIcon,
  ArrowRightIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ChevronLeftIcon,
  DownloadIcon,
} from '@chakra-ui/icons';
import PriceComparativeChart from './PriceComparativeChart';
import apiHelpers from '../../utils/apiHelpers';
import moment from 'moment';
import formatter from '../../utils/formatter';
import ProductComparativeFilters from './ProductComparativeFilters';
import ProductComparativePricesFilter from './ProductComparativePricesFilters';
import { CSVLink } from 'react-csv';
import { Parser } from 'json2csv';
import Joyride, { STATUS } from 'react-joyride';
import ProductFilters from './ProductFilters';
import { useLocation } from 'react-router-dom';
import Pagination from '../common/Pagination';
import BranchesContext from '../../contexts/BranchesContext';
import { colors } from '../../theme';
import SelectProductsByEanOrName from '../common/selects/SelectProductsByEanOrName';

const ProductComparativeScreen = () => {
  const download = useRef(null);
  const branchContext = useContext(BranchesContext);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [products, setProducts] = useState([]);
  const [priceMetrics, setPriceMetrics] = useState([]);
  const [priceMetricsList, setPriceMetricsList] = useState([]);
  const boxBg = useColorModeValue('white', '#1E2626');
  const color = useColorModeValue('black', 'white');
  const boxShadow = useColorModeValue('dark-lg', '0 8px 25px #5B7D76');
  const [filters, setFilters] = useState(null);
  const [pricesFilters, setPricesFilters] = useState(null);
  const [totalBranches, setTotalBranches] = useState(0);
  const [CSVdata, setCSVdata] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const TheadBg = colors.generic['1'];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [count, setCount] = useState(0);
  const [eans, setEans] = useState([]);
  const [colorsEan, setColorsEan] = useState([]);
  const [priceSelected, setPriceSelected] = useState('moda');
  const [categories, setCategories] = useState([]);
  const [descriptionsOfProducts, setDescriptions] = useState([]);
  const [chartPrices, setChartPrices] = useState(true);
  const [chartCoverage, setChartCoverage] = useState(false);
  const colorButtonEdit = colors.generic['1'];
  const { state } = useLocation();
  const [renderFilters, setRenderFilters] = useState(false);
  const [selectProductsPopover, setSelectProductsPopover] = useState(false);

  const mainColor = colors.generic['1'];
  const notEvenRowColor = '#e4e4e4';
  const evenRowColor = '#d8d8d8';

  const [stepsProducts, setStepsProducts] = useState({
    steps: [
      {
        target: '.my-first-step',
        content: (
          <Text as="b" fontSize="18px">
            Bienvenido a la pantalla comparativa de productos, aquí podrás
            comparar los precios de distintos productos.
          </Text>
        ),
      },
      {
        target: '.my-second-step',
        content: (
          <Text as="b" fontSize="18px">
            Haciendo clic aquí podrás seleccionar los productos que desees
            comparar.{' '}
          </Text>
        ),
      },
      {
        target: '.my-other2-step',
        content: (
          <Text as="b" fontSize="18px">
            Haciendo clic en este botón podrás comparar los productos
            previamente seleccionados.
          </Text>
        ),
      },
    ],
  });
  const [steps, setSteps] = useState({
    steps: [
      {
        target: '.my-other-step',
        content: (
          <Text as="b" fontSize="18px">
            Aquí encontrarás los productos seleccionados marcados por color.
          </Text>
        ),
      },
      {
        target: '.my-third-step',
        content: (
          <Text as="b" fontSize="18px">
            Cuentas con diferentes filtros para los precios de los productos
            seleccionados.
          </Text>
        ),
      },
      {
        target: '.my-fourth-step',
        content: (
          <Text as="b" fontSize="18px">
            Tendrás los precios por color del producto al que corresponde, en
            forma de gráfico.
          </Text>
        ),
      },
      {
        target: '.my-fifth-step',
        content: (
          <Text as="b" fontSize="18px">
            Aquí podrás seleccionar el precio que desees ver en el gráfico.
          </Text>
        ),
      },
      {
        target: '.my-seventh-step',
        content: (
          <Text as="b" fontSize="18px">
            Haciendo clic aquí podrás exportar los precios de los productos
            seleccionados en formato CSV.
          </Text>
        ),
      },
    ],
  });
  const [runOnboardingProduct, setRunOnboardingProduct] = useState(false);
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [countLoading, setCountLoading] = useState(false);
  const [pickedProducts, setPickedProducts] = useState([]);

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(0)"
    />
  );

  useEffect(() => {
    if (window.navigator.userAgentData.mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    apiHelpers.getCurrentUser().then(response => {
      setCurrentUser(response.data);
      setRunOnboardingProduct(response.data.onboardingComprativeScreen);
    });

    if (state && state.eans.length) {
      let picked = [];
      for (let i = 0; i < state.eans.length; i++) {
        let newColor = getRandomColor();

        while (newColor === undefined) {
          newColor = getRandomColor();
        }
        colorsEan.push(newColor);
        picked.push({
          value: state.eans[i],
          description: [state.description[i]],
        });
      }
      setEans(state.eans);
      setPickedProducts(picked);
      setDescriptions(state.description);
    } else {
      // onOpen();
    }
    getCategories();
  }, []);

  useEffect(() => {
    CSVdata.length && download.current.link.click();
  }, [CSVdata]);

  useEffect(() => {
    getProducts();
  }, [filters]);

  useEffect(() => {
    !isOpen && getPriceMetrics();
    getBranches();
  }, [pricesFilters]);

  useEffect(() => {
    if (selectProductsPopover == false) {
      getProducts();
    }
  }, [selectProductsPopover]);

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  const handleChangeFilters = filters => {
    setIsLoading(true);
    setFilters(filters);
  };

  const handleChangePricesFilters = filters => {
    setIsLoadingPrices(true);
    setPricesFilters(filters);
  };

  const getProducts = async () => {
    setIsLoading(true);
    setCountLoading(true);
    const filtersWithPaging = {
      ...filters,
      limit: pageSize,
      skip: (currentPage - 1) * pageSize,
    };
    try {
      let products = await apiHelpers.getProducts(filtersWithPaging);
      setProducts(products.data);
      setIsLoading(false);
      let count = await apiHelpers.getProductsCount(filters);
      setCount(count.data.count);
      setCountLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const filterBranches = () => {
    if (!pricesFilters) {
      return branchContext.allBranches;
    }
    const filteredBranches = branchContext.allBranches.filter(branch => {
      if (
        pricesFilters.where.storeBrand &&
        pricesFilters.where.storeBrand !== branch.storeBrand
      )
        return false;
      if (
        pricesFilters.where.province &&
        pricesFilters.where.province !== branch.province
      )
        return false;
      if (
        pricesFilters.where.branchType &&
        pricesFilters.where.branchType !== branch.branchType
      )
        return false;
      return true;
    });
    return filteredBranches;
  };

  const getBranches = () => {
    setTotalBranches(filterBranches().length);
  };

  const getCategories = () => {
    apiHelpers
      .getCategories({
        limit: 10000,
      })
      .then(response => {
        setCategories(response.data);
      });
  };

  const getPriceMetrics = () => {
    if (!eans.length) return;
    setIsLoadingPrices(true);
    try {
      let filterWithEans;
      if (pricesFilters && pricesFilters.where) {
        filterWithEans = pricesFilters;
        filterWithEans.where.productEanArray = eans;
      } else {
        filterWithEans = {
          where: {
            productEanArray: eans,
          },
        };
      }
      let filtersWithLimit = {
        ...filterWithEans,
        limit: 1000,
      };
      apiHelpers.getPricesMetrics(filtersWithLimit).then(response => {
        if (!response.data.length) {
          setPriceMetrics([]);
          setIsLoadingPrices(false);
          setPriceMetricsList([]);
          return;
        }

        setPriceMetricsList(
          response.data.sort((a, b) =>
            a.productEan === b.productEan ? 1 : -1,
          ),
        );

        let pricesForChart = [];
        for (let ean of eans) {
          let index = eans.indexOf(ean);

          response.data.sort((a, b) =>
            moment(a.metric_date).isBefore(moment(b.metric_date)) ? 1 : -1,
          );
          let prices = response.data.filter(price => {
            if (price.productEan === ean) {
              price.color = colorsEan[index];
              return price;
            }
          });
          prices.length && prices[0]?.color && pricesForChart.push(prices);
        }
        setPriceMetrics(pricesForChart);
        setIsLoadingPrices(false);
      });
    } catch (error) {
      console.log(error);
      setIsLoadingPrices(false);
    }
  };

  const handleChangePage = pageOfItems => {
    setCurrentPage(pageOfItems);
  };

  const getNumberOfPages = () => {
    return Math.ceil(count / pageSize);
  };

  const handleSelectProduct = products => {
    const selectedEans = pickedProducts.map(p => p.value);
    if (selectedEans.includes(products.value)) {
      return;
    }
    setPickedProducts([...pickedProducts, products]);
    setEans([...eans, products.value]);
    setDescriptions([...descriptionsOfProducts, products.description]);
    setColorsEan([...colorsEan, getRandomColor()]);
  };

  const colorSimilar = (R, G, B) => {
    let isSimilar = false;
    if (colorsEan.length > 1 && colorsEan.length < 20) {
      for (let i = 0; i < colorsEan.length; i++) {
        let lastColor = parseInt(colorsEan[i].substring(1), 16);
        let lastR = (lastColor & 0x00ff0000) >>> 16;
        let lastG = (lastColor & 0x0000ff00) >>> 8;
        let lastB = lastColor & 0x000000ff;
        if (
          Math.abs(R - lastR) + Math.abs(G - lastG) + Math.abs(B - lastB) <
          70
        ) {
          isSimilar = true;
        }
      }
    } else if (colorsEan.length >= 20 && colorsEan.length < 40) {
      for (let i = 0; i < colorsEan.length; i++) {
        let lastColor = parseInt(colorsEan[i].substring(1), 16);
        let lastR = (lastColor & 0x00ff0000) >>> 16;
        let lastG = (lastColor & 0x0000ff00) >>> 8;
        let lastB = lastColor & 0x000000ff;
        if (
          Math.abs(R - lastR) + Math.abs(G - lastG) + Math.abs(B - lastB) <
          20
        ) {
          isSimilar = true;
        }
      }
    } else {
      for (let i = 0; i < colorsEan.length; i++) {
        let lastColor = parseInt(colorsEan[i].substring(1), 16);
        let lastR = (lastColor & 0x00ff0000) >>> 16;
        let lastG = (lastColor & 0x0000ff00) >>> 8;
        let lastB = lastColor & 0x000000ff;
        if (
          Math.abs(R - lastR) == 0 &&
          Math.abs(G - lastG) == 0 &&
          Math.abs(B - lastB) == 0
        ) {
          isSimilar = true;
        }
      }
    }
    let rgb = (R << 16) + (G << 8) + B;

    if (isSimilar) {
      return getRandomColor();
    } else {
      return `#${rgb.toString(16)}`;
    }
  };

  const getRandomColor = () => {
    let R = Math.floor(Math.random() * 127 + 90);
    let G = Math.floor(Math.random() * 127 + 90);
    let B = Math.floor(Math.random() * 127 + 90);
    let color = colorSimilar(R, G, B);
    if (color) return color;
  };

  const addAndRemoveProduct = (productEan, description) => {
    if (eans.includes(productEan)) {
      for (let i = 0; i < eans.length; i++) {
        if (eans[i] === productEan) {
          setEans(eans.filter((_, j) => j != i));
          setDescriptions(descriptionsOfProducts.filter((_, j) => j != i));
          setColorsEan(colorsEan.filter((_, j) => j != i));
        }
      }
    } else {
      let newColor = getRandomColor();
      while (newColor === undefined) {
        newColor = getRandomColor();
      }
      setEans([...eans, productEan]);
      setDescriptions([...descriptionsOfProducts, description]);
      setColorsEan([...colorsEan, newColor]);
    }
  };

  const removeProductAndGetPrices = (productEan, description) => {
    if (eans.includes(productEan)) {
      for (let i = 0; i < eans.length; i++) {
        if (eans[i] === productEan) {
          eans.splice(i, 1);
          descriptionsOfProducts.splice(i, 1);
          colorsEan.splice(i, 1);
        }
      }
    } else {
      let newEans = JSON.parse(JSON.stringify(eans));
      let colors = JSON.parse(JSON.stringify(colorsEan));
      let descriptions = JSON.parse(JSON.stringify(descriptionsOfProducts));
      let newColor = getRandomColor();
      while (newColor === undefined) {
        newColor = getRandomColor();
      }
      colors.push(newColor);
      newEans.push(productEan);
      descriptions.push(description);
      setDescriptions(descriptions);
      setColorsEan(colors);
      setEans(newEans);
    }
    getProducts();
    if (priceMetrics.length) getPriceMetrics();
  };

  const getPrices = (date, productEan) => {
    try {
      setIsLoadingPrices(true);
      let filtersWithLimit = {
        where: {
          productEan: productEan,
          createdAt: formatter.formatDateForAPI(date),
        },
        limit: 10000,
      };

      apiHelpers.getPrices(filtersWithLimit).then(response => {
        exportPriceMetrics(response.data);
        setIsLoadingPrices(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const exportPriceMetrics = async pricesForExport => {
    const fields = [
      'EAN',
      'Marca',
      'Descripción',
      'Formato',
      'Notificar',
      'Categoria 1',
      'Categoria 2',
      'Categoria 3',
      'Categoria 4',
      'Fecha',
      'Precio mínimo',
      'Precio máximo',
      'Precio medio',
      'Moda',
      'Variación de cobertura',
      'Variación de precio',
      'Cobertura',
      'Cobertura (%)',
      'Provincia',
      'Canal',
      'Cadena',
    ];
    let outputPrices = [];

    for (let price of pricesForExport) {
      let priceWithCategory = pricesForExport.find(
        p =>
          p.productEan === price.productEan &&
          p.cat_1 &&
          p.cat_2 &&
          p.cat_3 &&
          p.cat_4,
      );
      if (!price.cat_1) price.cat_1 = priceWithCategory.cat_1;
      if (!price.cat_2) price.cat_2 = priceWithCategory.cat_2;
      if (!price.cat_3) price.cat_3 = priceWithCategory.cat_3;
      if (!price.cat_4) price.cat_4 = priceWithCategory.cat_4;

      let cat_1 = categories.find(c => c.categoryInternalId == price.cat_1);
      let cat_2 = categories.find(c => c.categoryInternalId == price.cat_2);
      let cat_3 = categories.find(c => c.categoryInternalId == price.cat_3);
      let cat_4 = categories.find(c => c.categoryInternalId == price.cat_4);

      outputPrices[price.id] = {
        EAN: price.productEan + '\t',
        Marca:
          price.ProductBrand !== 'undefined' ? price.productBrand : 'Sin marca',
        Descripción: price.productName,
        Formato: price.productFormat,
        Notificar: price.relevant ? 'Si' : 'No',
        'Categoria 1': cat_1 ? cat_1.categoryName : '',
        'Categoria 2': cat_2 ? cat_2.categoryName : '',
        'Categoria 3': cat_3 ? cat_3.categoryName : '',
        'Categoria 4': cat_4 ? cat_4.categoryName : '',
        Fecha: formatter.formatDate(price.metric_date),
        'Precio mínimo': formatter.formatNumberDecimals(price.minPrice, 2),
        'Precio máximo': formatter.formatNumberDecimals(price.maxPrice, 2),
        'Precio medio': formatter.formatNumberDecimals(price.medPrice, 2),
        Moda: formatter.formatNumberDecimals(price.modePrice, 2),
        'Variación de precio':
          formatter.formatNumberDecimals(price.priceVariation, 2) + '%',
        'Variación de cobertura':
          formatter.formatNumberDecimals(price.coverageVariation, 2) + '%',
        Cobertura: price.coverage + ' / ' + totalBranches + '\t',
        'Cobertura (%)':
          totalBranches == 0
            ? 'Sin cobertura'
            : formatter.formatNumberDecimals(
                (price.coverage / totalBranches) * 100,
                3,
              ) + '%',
        Provincia: price.province ? price.province : 'Todos',
        Canal: price.branchType ? price.branchType : 'Todos',
        Cadena: price.storeBrand ? price.storeBrand : 'Todos',
      };
    }
    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputPrices);

    setCSVdata(csv);
  };

  const buttonExportPrices = (
    <Box className="my-seventh-step" minW={'220px'}>
      <Button
        variant="primary-button"
        onClick={() => exportPriceMetrics(priceMetricsList)}
        type="submit"
        isLoading={isLoading || isLoadingPrices || branchContext.isLoading}
        _loading={{
          visibility: 'hidden',
        }}>
        <Text px={4} display={{ base: 'none', sm: 'block' }}>
          Exportar precios
        </Text>
        <DownloadIcon />
      </Button>
    </Box>
  );
  const selectedProducts = (
    <SimpleGrid row={{ lg: 3 }} columns={{ base: 1, sm: 1, lg: 5 }}>
      {eans.map((ean, index) => {
        return (
          <Tooltip arrowSize={5} hasArrow label={descriptionsOfProducts[index]}>
            <Box
              bg={colorsEan[index]}
              mb={4}
              mr={2}
              borderRadius="5px 5px 5px 5px"
              border={'1px solid'}
              borderColor={color}
              borderBlock="1.3px solid "
              borderInline="1.3px solid"
              _placeholder={{
                color: color,
              }}
              color={color}>
              {' '}
              <Flex>
                <Box p="2">
                  <Text fontSize={14} pl={1}>
                    {`${descriptionsOfProducts[index]} - ${ean}`}
                  </Text>
                </Box>
                <Spacer />
                <Box py="2">
                  <Button
                    p={0}
                    m={0}
                    boxSize={0}
                    onClick={() =>
                      removeProductAndGetPrices(
                        ean,
                        descriptionsOfProducts[index],
                      )
                    }
                    bg={'none'}>
                    <CloseIcon boxSize={3} />
                  </Button>{' '}
                </Box>
              </Flex>
            </Box>
          </Tooltip>
        );
      })}
    </SimpleGrid>
  );

  const table = (
    <TableContainer
      overflowX={isMobile ? 'scroll' : 'auto'}
      whiteSpace={'normal'}
      maxW={{ base: '350px', md: 'full', lg: 'full', xl: 'full' }}
      minW={{ md: 'full', lg: 'full', xl: 'full' }}
      mt={5}>
      <Table
        variant="simple"
        size={useBreakpointValue({ md: 'md', base: 'sm' })}>
        <TableCaption>Lista de productos</TableCaption>

        {!products.length && isLoading ? (
          <Stack my={3} w="full">
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : products.length ? (
          <>
            <Thead bgColor={TheadBg}>
              <Tr my=".8rem" pl="0px">
                <Th color="white">EAN</Th>
                <Th color="white">Descripción</Th>
                <Th color="white">Marca</Th>
                <Th color="white">Fecha de alta</Th>
                <Th color="white">Formato</Th>
                <Th color="white">Agregar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {products.map((product, i) => {
                return (
                  <Tr>
                    <Td bg={i % 2 == 0 ? notEvenRowColor : evenRowColor}>
                      {product.productEan}
                    </Td>
                    <Td
                      maxW={'300px'}
                      bg={i % 2 == 0 ? notEvenRowColor : evenRowColor}>
                      {product.productName}
                    </Td>
                    <Td bg={i % 2 == 0 ? notEvenRowColor : evenRowColor}>
                      {product.productBrand}
                    </Td>
                    <Td bg={i % 2 == 0 ? notEvenRowColor : evenRowColor}>
                      {formatter.formatDate(product.createdAt)}
                    </Td>
                    <Td bg={i % 2 == 0 ? notEvenRowColor : evenRowColor}>
                      {product.productFormat}
                    </Td>
                    <Td
                      className="my-second-step"
                      bg={i % 2 == 0 ? notEvenRowColor : evenRowColor}>
                      <Button
                        bgColor={
                          eans.includes(product.productEan)
                            ? 'generic.1'
                            : 'white'
                        }
                        borderColor="black"
                        border="2px"
                        borderRadius="0px"
                        onClick={() => {
                          addAndRemoveProduct(
                            product.productEan,
                            product.productName,
                          );
                        }}
                        _hover={{
                          backgroundColor: colors.primary['200'],
                          boxShadow: 'none',
                          outline: '0',
                        }}
                        _focus={{
                          backgroundColor: eans.includes(product.productEan)
                            ? 'generic.1'
                            : 'white',
                          boxShadow: 'none',
                          outline: '0px',
                        }}
                        _active={{
                          backgroundColor: colors.primary['600'],
                          boxShadow: 'none',
                          outline: '0',
                        }}>
                        {eans.includes(product.productEan) ? (
                          <MinusIcon />
                        ) : (
                          <AddIcon />
                        )}
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </>
        ) : (
          <Center minW={'full'}>
            <Text fontSize={20} textAlign={'center'} p={6}>
              No se encontraron resultados
            </Text>
          </Center>
        )}
      </Table>
      <Box>
        {!countLoading ? (
          <Pagination
            onChangePage={handleChangePage}
            currentPage={currentPage}
            totalPages={getNumberOfPages()}
          />
        ) : (
          <Center>
            <Skeleton height={'20px'} w={'220px'}></Skeleton>
            <Skeleton height={'20px'} w={'220px'}></Skeleton>
          </Center>
        )}
      </Box>
    </TableContainer>
  );

  const modalAddProduct = (
    <Modal
      isOpen={isOpen}
      size={'6xl'}
      scrollBehavior={'inside'}
      onClose={() => {
        onClose();
        setRunOnboardingProduct(false);
        if (eans.length && currentUser.onboardingComprativeScreen) {
          setRunOnboarding(true);
        }
        getPriceMetrics();
      }}>
      <OverlayOne />
      <ModalContent bgColor={boxBg}>
        <ModalCloseButton />
        <ModalBody>
          <Box w={'100%'}>{selectedProducts}</Box>
          <Flex
            bgColor={mainColor}
            w={'110%'}
            py={'35px'}
            justify="space-around">
            <Heading color="white">Agregar productos</Heading>
            <Popover
              placement="bottom-end"
              border="4px solid"
              borderColor="primary.200"
              _focus={{
                border: '4px solid',
                borderColor: 'primary.200',
              }}>
              <PopoverTrigger>
                <Button
                  variant="secondary-button"
                  onClick={() => setRenderFilters(true)}>
                  <Text mr={2}>Filtros</Text>
                  <i class="fa fa-solid fa-list"></i>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                bg="white"
                color="black"
                w={['330px', '480px', '630px', '980px', '1230px']}
                border="4px solid"
                borderColor={'primary.200'}
                _focus={{
                  border: '4px solid',
                  borderColor: 'primary.200',
                }}>
                <PopoverHeader as="Flex" align="center">
                  <Heading>Filtros</Heading>
                </PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody w={['300px', '450px', '600px', '950px', '1200px']}>
                  {renderFilters && (
                    <ProductFilters
                      loadData={handleChangeFilters}
                      isLoading={isLoading}
                    />
                  )}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
          {table}
        </ModalBody>
        <ModalFooter>
          <SimpleGrid
            w="full"
            spacing={1}
            columns={{ base: 1, sm: 3, lg: 3 }}
            rows={{ lg: 2 }}>
            <Spacer />
            <Spacer />

            <Button
              variant="primary-button"
              isDisabled={isLoading || branchContext.isLoading}
              onClick={() => {
                getBranches();
                getPriceMetrics();
                onClose();
                setRunOnboardingProduct(false);
                if (eans.length && currentUser.onboardingComprativeScreen) {
                  setRunOnboarding(true);
                }
              }}
              isLoading={isLoading}>
              {' '}
              <Text
                mr={1}
                justifyContent="center"
                _hover={{
                  fontSize: '15px',
                }}>
                Comparar
              </Text>
            </Button>
          </SimpleGrid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const handleJoyrideCallbackModal = data => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunOnboardingProduct(false);
    }
  };
  const handleJoyrideCallback = data => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunOnboarding(false);
      let user = currentUser;
      apiHelpers.patchUser(user.id, {
        username: user.username,
        role: user.role,
        onboardingComprativeScreen: false,
        onboardingProduct: user.onboardingProduct,
        onboardingProductDetail: user.onboardingProductDetail,
        onboardingCategories: user.onboardingCategories,
        onboardingProcesses: user.onboardingProcesses,
      });
    }
  };

  const rebuildChart = graphicValue => {
    setIsLoadingPrices(true);
    setTimeout(() => {
      setPriceSelected(graphicValue);
      setIsLoadingPrices(false);
    });
  };

  return (
    <Flex direction={'column'} minH="94vh" w={'100%'} pb={8}>
      <VStack w="100%">
        <Joyride
          callback={handleJoyrideCallbackModal}
          continuous
          hideCloseButton
          run={runOnboardingProduct}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={stepsProducts.steps}
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              backgroundColor: '#008080',
              color: '#ffffff',
            },
            buttonBack: {
              color: '#000000',
            },
            buttonClose: {
              backgroundColor: '#008080',
              color: '#000000',
            },
          }}
        />
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={runOnboarding}
          scrollToFirstStep
          showProgress
          showSkipButton
          disableScrolling
          steps={steps.steps}
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              backgroundColor: '#008080',
              color: '#ffffff',
            },
            buttonBack: {
              color: '#000000',
            },
            buttonClose: {
              backgroundColor: '#008080',
              color: '#000000',
            },
          }}
        />

        <Box w={'100%'}>
          <Flex
            bgColor={mainColor}
            py={'35px'}
            justify="space-around"
            w={'100%'}>
            <Heading color="white">Comparativa de productos</Heading>
            <Flex gap={12}>
              {!isOpen && (
                <Popover
                  placement="bottom-end"
                  border="4px solid"
                  borderColor="primary.200"
                  _focus={{
                    border: '4px solid',
                    borderColor: 'primary.200',
                  }}>
                  <PopoverTrigger>
                    <Button
                      variant="secondary-button"
                      onClick={() => setRenderFilters(true)}>
                      <Text mr={2}>Filtros</Text>
                      <i class="fa fa-solid fa-list"></i>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    bg="white"
                    color="black"
                    w={['330px', '480px', '630px', '980px', '1230px']}
                    border="4px solid"
                    borderColor={'primary.200'}
                    _focus={{
                      border: '4px solid',
                      borderColor: 'primary.200',
                    }}>
                    <PopoverHeader as="Flex" align="center">
                      <Heading>Filtros</Heading>
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody
                      w={['300px', '450px', '600px', '950px', '1200px']}>
                      {!isLoading && (
                        <ProductComparativePricesFilter
                          loadData={handleChangePricesFilters}
                          isLoading={
                            isLoading ||
                            isLoadingPrices ||
                            branchContext.isLoading
                          }
                        />
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
              <Popover
                placement="bottom-end"
                border="4px solid"
                borderColor="primary.200"
                _focus={{
                  border: '4px solid',
                  borderColor: 'primary.200',
                }}
                isOpen={selectProductsPopover}
                onClose={() => {
                  setSelectProductsPopover(false);
                }}>
                <PopoverTrigger>
                  <Button
                    variant="secondary-button"
                    //Old Moldal
                    // onClick={() => {
                    //   onOpen();
                    // }}
                    onClick={() => setSelectProductsPopover(true)}>
                    Agregar productos
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  bg="white"
                  color="black"
                  w={'370px'}
                  border="4px solid"
                  borderColor={'primary.200'}
                  _focus={{
                    border: '4px solid',
                    borderColor: 'primary.200',
                  }}>
                  <PopoverBody>
                    <Box layerStyle={'input-box'}>
                      <SelectProductsByEanOrName
                        handleSelect={handleSelectProduct}
                        selectedProducts={pickedProducts}
                        name="eans"
                        id="eans"
                        isDisabled={false}
                        value={pickedProducts}
                      />
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          </Flex>

          {!isLoading && (
            <CSVLink
              ref={download}
              data={CSVdata}
              filename={'Métricas de precios.csv'}
              className="hidden"></CSVLink>
          )}
        </Box>
        {/* {modalAddProduct} */}

        <Box w="full">
          <Box mx={'20px'} className="my-other-step">
            {' '}
            {selectedProducts}
          </Box>
          <Center>{buttonExportPrices}</Center>

          {!isLoading ? (
            <Grid templateColumns="repeat(5, 1fr)" gap={2} mx={'20px'}>
              {eans.length ? (
                <>
                  <GridItem
                    colStart={{ base: 1, sm: 2, lg: 1 }}
                    m={{ base: 2, sm: 0, lg: 0 }}
                    colEnd={{ base: 6, sm: 5, lg: 6 }}>
                    <Box
                      bgColor={boxBg}
                      boxShadow={boxShadow}
                      borderRadius="lg"
                      h="full"
                      my={2}>
                      <Flex w={'100%'}>
                        <VStack
                          w={'100%'}
                          m={{ base: 0, md: 2, lg: 4, xl: 4 }}
                          direction="column"
                          py={0}>
                          <Box className="my-fourth-step" w="full">
                            <Text align={'left'} as="b" color="#CCCCCC">
                              {' '}
                            </Text>
                          </Box>
                          <Flex
                            className="my-fifth-step"
                            justifyContent={{
                              lg: 'left',
                              base: 'center',
                              sm: 'center',
                              md: 'left',
                            }}
                            w="full">
                            <SimpleGrid
                              w={'97%'}
                              columns={{ base: 1, sm: 1, lg: 6 }}
                              rows={{ lg: 2 }}>
                              <Box>
                                <ButtonGroup
                                  w="full"
                                  mt={1}
                                  spacing={0}
                                  gap={0.5}
                                  borderRadius="lg"
                                  isAttached={true}>
                                  <Button
                                    variant={
                                      chartPrices
                                        ? 'graphic-button-active'
                                        : 'graphic-button-inactive'
                                    }
                                    onClick={() => {
                                      if (chartPrices) {
                                        setChartPrices(false);
                                      } else {
                                        setChartPrices(true);
                                        rebuildChart('moda');
                                        setPriceSelected('moda');
                                        setChartCoverage(false);
                                      }
                                    }}>
                                    Precios
                                  </Button>

                                  <Button
                                    mr={2}
                                    px={3}
                                    variant={
                                      chartCoverage
                                        ? 'graphic-button-active'
                                        : 'graphic-button-inactive'
                                    }
                                    onClick={() => {
                                      if (chartCoverage) {
                                        setChartCoverage(false);
                                      } else {
                                        setChartCoverage(true);
                                        setChartPrices(false);
                                        rebuildChart('coverage');
                                        setPriceSelected('coverage');
                                      }
                                    }}>
                                    Cobertura
                                  </Button>
                                </ButtonGroup>
                              </Box>
                              <Spacer></Spacer>
                              {chartCoverage && (
                                <>
                                  <Spacer></Spacer>
                                  <Spacer></Spacer>
                                </>
                              )}

                              {chartCoverage && (
                                <>
                                  <Box mt={1} mr={2}>
                                    <Button
                                      onClick={() => {
                                        rebuildChart('coverageCount');
                                      }}
                                      variant={
                                        priceSelected === 'coverageCount'
                                          ? 'graphic-button-active'
                                          : 'graphic-button-inactive'
                                      }>
                                      {'Cobertura (PV)'}
                                    </Button>
                                  </Box>
                                  <Box mt={1} mr={2}>
                                    <Button
                                      onClick={() => {
                                        rebuildChart('coverage');
                                      }}
                                      variant={
                                        priceSelected === 'coverage'
                                          ? 'graphic-button-active'
                                          : 'graphic-button-inactive'
                                      }>
                                      Cobertura (%)
                                    </Button>
                                  </Box>
                                </>
                              )}
                              {chartPrices && (
                                <>
                                  <Box mt={1} mr={2}>
                                    <Button
                                      onClick={() => {
                                        rebuildChart('min');
                                      }}
                                      variant={
                                        priceSelected === 'min'
                                          ? 'graphic-button-active'
                                          : 'graphic-button-inactive'
                                      }>
                                      Mínimo
                                    </Button>
                                  </Box>
                                  <Box mt={1} mr={2}>
                                    <Button
                                      onClick={() => {
                                        rebuildChart('max');
                                      }}
                                      variant={
                                        priceSelected === 'max'
                                          ? 'graphic-button-active'
                                          : 'graphic-button-inactive'
                                      }>
                                      Máximo
                                    </Button>
                                  </Box>
                                  <Box mt={1} mr={2}>
                                    <Button
                                      onClick={() => {
                                        rebuildChart('media');
                                      }}
                                      variant={
                                        priceSelected === 'media'
                                          ? 'graphic-button-active'
                                          : 'graphic-button-inactive'
                                      }>
                                      Medio
                                    </Button>
                                  </Box>
                                  <Box mt={1} mr={2}>
                                    <Button
                                      onClick={() => {
                                        rebuildChart('moda');
                                      }}
                                      variant={
                                        priceSelected === 'moda'
                                          ? 'graphic-button-active'
                                          : 'graphic-button-inactive'
                                      }>
                                      Moda
                                    </Button>
                                  </Box>
                                </>
                              )}
                            </SimpleGrid>
                          </Flex>
                          <Divider />

                          {priceMetrics.length && !isLoadingPrices ? (
                            <PriceComparativeChart
                              priceSelected={priceSelected}
                              data={priceMetrics}
                              totalBranches={totalBranches}
                              chartCoverage={chartCoverage}
                              chartPrices={chartPrices}
                            />
                          ) : isLoadingPrices ? (
                            <Center minW={'full'}>
                              <Text fontSize={20} textAlign={'center'} p={6}>
                                Cargando precios...
                              </Text>
                            </Center>
                          ) : (
                            <Center minW={'full'}>
                              <Text fontSize={20} textAlign={'center'} p={6}>
                                No se encontraron precios para el gráfico
                              </Text>
                            </Center>
                          )}
                        </VStack>
                      </Flex>
                    </Box>
                  </GridItem>

                  <GridItem
                    m={{ base: 2, sm: 0, lg: 0 }}
                    colStart={{ base: 1, sm: 1, lg: 1 }}
                    colEnd={{ base: 6, sm: 5, lg: 6 }}>
                    <Box
                      mt={{ base: 2 }}
                      bgColor={boxBg}
                      boxShadow={boxShadow}
                      borderRadius="lg"
                      h="full">
                      <VStack
                        m={{ base: 0, md: 2, lg: 0, xl: 2 }}
                        direction="column">
                        <Box
                          mt={{ base: 0, md: 2, lg: 4, xl: 5 }}
                          ml={{ base: 0, md: 2, lg: 4, xl: 5 }}
                          w="full">
                          <Text align={'left'} as="b" color="#CCCCCC">
                            Listado de precios
                          </Text>
                        </Box>
                        <Divider />

                        <TableContainer
                          overflowX={isMobile ? 'scroll' : 'auto'}
                          w="full">
                          <Table size="ssm">
                            {priceMetricsList.length && !isLoadingPrices ? (
                              <>
                                <Thead>
                                  <Tr pl="0px">
                                    <Th fontSize={14}>Ean</Th>
                                    <Th isNumeric={true} fontSize={14}>
                                      Fecha
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      Mínimo
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      Máximo
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      Medio
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      Moda
                                    </Th>
                                    <Th isNumeric={true} fontSize={14}>
                                      Cobertura
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      Cobertura(%)
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      <Text>Variación</Text>
                                      <Text>de cobertura</Text>
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      <Text>Variación</Text>
                                      <Text>de precio</Text>
                                    </Th>
                                    <Th isNumeric={true} fontSize={14} p={1}>
                                      Precios
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {priceMetricsList.map((price, index) => {
                                    return (
                                      <Tr
                                        bg={price.color}
                                        key={price.id}
                                        my="0rem"
                                        mx="0rem"
                                        pl="0px">
                                        <Tooltip label={price.productName}>
                                          <Td fontSize={14}>
                                            {price.productEan}
                                          </Td>
                                        </Tooltip>
                                        <Td isNumeric={true} fontSize={14}>
                                          {moment(price.metric_date).format(
                                            'DD/MM',
                                          )}
                                        </Td>
                                        <Td
                                          fontSize={14}
                                          p={1}
                                          isNumeric={true}>
                                          {'$' +
                                            formatter.formatNumberDecimals(
                                              price.minPrice,
                                              2,
                                            )}
                                        </Td>
                                        <Td
                                          fontSize={14}
                                          p={1}
                                          isNumeric={true}>
                                          {'$' +
                                            formatter.formatNumberDecimals(
                                              price.maxPrice,
                                              2,
                                            )}
                                        </Td>
                                        <Td
                                          fontSize={14}
                                          p={1}
                                          isNumeric={true}>
                                          {'$' +
                                            formatter.formatNumberDecimals(
                                              price.medPrice,
                                              2,
                                            )}
                                        </Td>
                                        <Td fontSize={14} isNumeric={true}>
                                          {'$' +
                                            formatter.formatNumberDecimals(
                                              price.modePrice,
                                              2,
                                            )}
                                        </Td>
                                        <Td fontSize={14} isNumeric={true}>
                                          {price.coverage +
                                            ' / ' +
                                            totalBranches}
                                        </Td>
                                        <Td
                                          fontSize={14}
                                          p={1}
                                          isNumeric={true}>
                                          {totalBranches == 0
                                            ? 'Sin cobertura'
                                            : formatter.formatNumberDecimals(
                                                (price.coverage /
                                                  totalBranches) *
                                                  100,
                                                2,
                                              ) + '%'}
                                        </Td>
                                        <Td
                                          fontSize={14}
                                          p={1}
                                          isNumeric={true}>
                                          {price.coverageVariation
                                            ? formatter.formatNumberDecimals(
                                                price.coverageVariation,
                                                2,
                                              ) + '%'
                                            : 'No definido'}
                                        </Td>
                                        <Td
                                          fontSize={14}
                                          p={1}
                                          isNumeric={true}>
                                          {price.priceVariation
                                            ? formatter.formatNumberDecimals(
                                                price.priceVariation,
                                                2,
                                              ) + '%'
                                            : 'No definido'}
                                        </Td>
                                        <Td
                                          fontSize={12}
                                          p={1}
                                          isNumeric={true}>
                                          <Button
                                            p="0px"
                                            bg="primary.100"
                                            onClick={() =>
                                              getPrices(
                                                price.metric_date,
                                                price.productEan,
                                              )
                                            }>
                                            <DownloadIcon
                                              w={6}
                                              h={6}
                                              color={colorButtonEdit}
                                              cursor="pointer"
                                            />
                                          </Button>
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                                </Tbody>
                              </>
                            ) : isLoadingPrices ? (
                              <Stack my={3} w="full">
                                <Skeleton height="20px" />
                                <Skeleton height="20px" />
                                <Skeleton height="20px" />
                                <Skeleton height="20px" />
                              </Stack>
                            ) : (
                              !priceMetrics.length && (
                                <Center minW={'full'}>
                                  <Text
                                    fontSize={20}
                                    textAlign={'center'}
                                    p={6}>
                                    No se encontraron precios
                                  </Text>
                                </Center>
                              )
                            )}
                          </Table>
                        </TableContainer>
                      </VStack>
                    </Box>
                  </GridItem>
                </>
              ) : (
                <GridItem
                  colStart={{ base: 1, sm: 2, lg: 1 }}
                  m={{ base: 2, sm: 0, lg: 0 }}
                  colEnd={{ base: 6, sm: 5, lg: 6 }}>
                  <Box
                    bgColor={boxBg}
                    boxShadow={boxShadow}
                    borderRadius="lg"
                    h="full"
                    my={2}>
                    <Flex w={'100%'}>
                      <VStack
                        w={'100%'}
                        m={{ base: 0, md: 2, lg: 4, xl: 5 }}
                        direction="column"
                        py={0}>
                        <Center minW={'full'}>
                          <Text fontSize={20} textAlign={'center'} p={6}>
                            No hay productos seleccionados
                          </Text>
                        </Center>
                      </VStack>
                    </Flex>
                  </Box>
                </GridItem>
              )}
            </Grid>
          ) : (
            <Box my={'15%'} h={'150px'}>
              <Center>
                <Stack>
                  {' '}
                  <CircularProgress
                    isIndeterminate
                    color="red"
                    size="120px"
                    thickness="3px"
                  />
                </Stack>
              </Center>
            </Box>
          )}
        </Box>
      </VStack>
    </Flex>
  );
};
export default ProductComparativeScreen;
