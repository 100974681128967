import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  Td,
  Th,
  Tr,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Button,
  Thead,
  VStack,
  Stack,
  Spacer,
  Heading,
  Center,
  Skeleton,
  Divider,
  ButtonGroup,
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent,
  Switch,
  HStack,
} from '@chakra-ui/react';
import Pagination from '../common/Pagination';
import { DownloadIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import apiHelpers from '../../utils/apiHelpers';
import CategoryFilters from './CategoryFilters';
import { CSVLink } from 'react-csv';
import { Parser } from 'json2csv';
import Joyride, { STATUS, EVENTS, ACTIONS } from 'react-joyride';
import JoyrideContext from '../../contexts/JoyrideContext';
import OrderArrows from '../common/OrderArrows';
import { colors } from '../../theme';

const Categories = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const { isJoyrideActive, setJoyride } = useContext(JoyrideContext);
  const boxBg = colors.generic['3'];
  const TheadBg = colors.generic['1'];
  const [pageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(null);
  const [count, setCount] = useState(1);
  const [categories, setCategories] = useState([]);
  const download = useRef(null);
  const [CSVdata, setCSVdata] = useState([]);
  const [isOperative, setIsOperative] = useState(false);
  const [actualOrdering, setActualOrdering] = useState({});

  const mainColor = colors.generic['1'];
  const notEvenRowColor = '#e4e4e4';
  const evenRowColor = '#d8d8d8';

  const [steps, setSteps] = useState({
    steps: [
      {
        target: '.my-first-step',
        content: (
          <Text as="b" fontSize="18px">
            En listado de categorías, vas a poder encontrar todo lo relacionado
            con las categorías en sistema
          </Text>
        ),
        title: (
          <Flex flexDirection={'column'}>
            <i class="fa fa-solid fa-list" style={{ fontSize: '4em' }}></i>
            <Text as="b" fontSize={'40px'}>
              ¡Bienvenido!
            </Text>
          </Flex>
        ),
        disableBeacon: true,
      },
      {
        target: '.my-second-step',
        content: (
          <Text as="b" fontSize="18px">
            Cuentas con diferentes filtros para hacer la búsqueda en el listado
            más dinámica
          </Text>
        ),
        title: (
          <Text as="b" fontSize={'40px'}>
            Filtros
          </Text>
        ),
        disableBeacon: true,
      },
      {
        target: '.my-other-step',
        content: (
          <Text as="b" fontSize="18px">
            Haciendo clic aquí, podrás exportar las categorías en un archivo
            CSV, con toda la información disponible en sistema.
          </Text>
        ),
        title: (
          <Text as="b" fontSize={'40px'}>
            Categorias
          </Text>
        ),
        disableBeacon: true,
      },
      {
        target: '.my-third-step',
        content: (
          <Text as="b" fontSize="18px">
            En el listado encontrarás toda la información sobre las categorías.
          </Text>
        ),
        title: (
          <Text as="b" fontSize={'40px'}>
            Listado
          </Text>
        ),
        disableBeacon: true,
      },
    ],
  });
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    apiHelpers.getCurrentUser().then(response => {
      setCurrentUser(response.data);
      if (response.data.role == 'OPERATIVE') {
        setIsOperative(true);
      } else {
        let newSteps = steps.steps;
        newSteps.push({
          target: '.my-fifth-step',
          content: (
            <Text as="b" fontSize="18px">
              Podrás marcar la relevancia de una categoria, lo cual permite
              señalar la importancia de una categoría para el proceso de
              obtención de precios.
            </Text>
          ),
        });
        setSteps({ steps: newSteps });
        setIsOperative(false);
      }
      setRunOnboarding(response.data.onboardingCategories);
    });
    getCategories();
  }, []);

  useEffect(() => {
    getCategories();
  }, [filters]);

  useEffect(() => {
    getCategories();
  }, [currentPage]);

  useEffect(() => {
    CSVdata.length && download.current.link.click();
  }, [CSVdata]);

  const getCategories = async () => {
    setIsLoading(true);
    const filtersWithPaging = {
      ...filters,
      limit: pageSize,
      skip: (currentPage - 1) * pageSize,
      order: actualOrdering,
    };

    try {
      try {
        let categories = await apiHelpers.getCategories(filtersWithPaging);
        if (categories.data) {
          let count = await apiHelpers.getCategoriesCount(filters);
          setCount(count.data.count);
          setCategories(categories.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const handleChangePage = pageOfItems => {
    setCurrentPage(pageOfItems);
    getCategories();
  };
  const getNumberOfPages = () => {
    return Math.ceil(count / pageSize);
  };

  const exportCategories = async () => {
    const fields = ['Descripción', 'Nivel', 'Categoria padre', 'Relevante'];
    let outputCategories = [];

    let categoriesCsv = await apiHelpers.getCategories({
      ...filters,
      limit: count,
    });

    for (let index = 0; index < categoriesCsv.data.length; index++) {
      for (let category of categoriesCsv.data) {
        outputCategories[category.id] = {
          Descripción: category.categoryName,
          Nivel: category.categoryLevel,
          'Categoria padre': category.categoryParents,
          Relevante: category.relevant ? 'Si' : 'No',
        };
        index++;
      }
    }
    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputCategories);

    setCSVdata(csv);
  };

  const exportButton = (
    <Box>
      <Button
        className="my-other-step"
        variant="primary-button"
        onClick={() => exportCategories()}
        type="submit"
        isLoading={isLoading}>
        <Text px={2} display={{ base: 'none', sm: 'block' }}>
          Exportar categorías
        </Text>
        <DownloadIcon />
      </Button>
    </Box>
  );
  const orderBy = (element, order, remove) => {
    let newOrdering = actualOrdering;

    if (remove) {
      newOrdering[element] && delete newOrdering[element];
    } else {
      newOrdering[element] = order;
    }
    setActualOrdering(newOrdering);
    getCategories();
  };

  const handleJoyrideCallback = data => {
    const { status, action, index, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
      setJoyride(true);
    } else if (action == 'close') {
      setJoyride(false);
    }
  };

  return (
    <Flex w="100%" justify="center">
      <VStack w="100%">
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={isJoyrideActive}
          stepIndex={stepIndex}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps.steps}
          locale={{
            next: <span>Continuar</span>,
            skip: '',
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              backgroundColor: mainColor,
              color: 'white',
            },
            buttonBack: {
              color: '#000000',
            },
            buttonClose: {
              backgroundColor: '#008080',
              color: '#000000',
            },
          }}
        />
        <Flex
          bg={mainColor}
          w="100%"
          color={'white'}
          minH={'100px'}
          py={4}
          justify="space-around"
          align="center"
          direction={{ base: 'column', md: 'row' }}>
          <Heading className="my-first-step" ml={2}>
            Lista de Categorías
          </Heading>
          <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
            <Popover
              placement="bottom-end"
              border="4px solid"
              borderColor="primary.200"
              _focus={{
                border: '4px solid',
                borderColor: 'primary.200',
              }}>
              <PopoverTrigger>
                <Button variant="secondary-button" className="my-second-step">
                  <Text mr={2}>Filtros</Text>
                  <i class="fa fa-solid fa-list"></i>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                bg="white"
                color="black"
                w={['330px', '480px', '630px', '980px', '1230px']}
                border="4px solid"
                borderColor={'primary.200'}
                _focus={{
                  border: '4px solid',
                  borderColor: 'primary.200',
                }}>
                <PopoverHeader as="Flex" align="center">
                  <Heading>Filtros</Heading>
                </PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody w={['300px', '450px', '600px', '950px', '1200px']}>
                  <CategoryFilters
                    loadData={handleChangeFilters}
                    isLoading={isLoading}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
        <Flex>{exportButton}</Flex>

        <Spacer />
        <Box bgColor={boxBg} borderRadius="lg" border={'none'}>
          <Flex alignItems="center" justifyContent="center">
            <VStack
              m={{ base: 0, md: 2, lg: 3, xl: 3 }}
              direction="column"
              py={0}>
              <Stack w="full">
                <CSVLink
                  ref={download}
                  data={CSVdata}
                  filename={'categorias.csv'}
                  className="hidden"></CSVLink>
              </Stack>
              <TableContainer
                className="my-third-step"
                whiteSpace={'normal'}
                maxW={{ md: 'full', lg: '1180px', xl: '1180px' }}
                minW={{ md: 'full', lg: '1180px', xl: '1180px' }}>
                <Table
                  variant="simple"
                  size={useBreakpointValue({ md: 'md', base: 'sm' })}>
                  <TableCaption>Lista de categorías</TableCaption>

                  {isLoading && !categories.length ? (
                    <Stack my={3} w="full">
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                    </Stack>
                  ) : categories.length ? (
                    <>
                      <Thead bgColor={TheadBg}>
                        <Tr my=".8rem" pl="0px">
                          <Th color="white">
                            <Flex alignContent={'left'}>
                              <VStack pb={2}>
                                <Text my={2} textAlign={'center'}>
                                  Nombre
                                </Text>
                                <OrderArrows
                                  element={'categoryName'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}></OrderArrows>
                              </VStack>
                            </Flex>{' '}
                          </Th>
                          <Th color="white">
                            <Flex alignContent={'left'} pl={5}>
                              <VStack pb={2}>
                                <Text my={2} textAlign={'center'}>
                                  Nivel
                                </Text>
                                <OrderArrows
                                  element={'categoryLevel'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}></OrderArrows>
                              </VStack>
                            </Flex>
                          </Th>
                          <Th color="white">
                            <Flex alignContent={'left'}>
                              <VStack pb={2}>
                                <Text my={2}>Categoría Superior</Text>
                                <OrderArrows
                                  element={'categoryParents'}
                                  selectedOrder={actualOrdering}
                                  orderBy={orderBy}></OrderArrows>
                              </VStack>
                            </Flex>
                          </Th>
                          {!isOperative && (
                            <Th className="my-fifth-step" color="white">
                              <Flex alignContent={'left'}>
                                <VStack pb={2}>
                                  <Text my={2}>Relevante</Text>
                                  <OrderArrows
                                    element={'relevant'}
                                    selectedOrder={actualOrdering}
                                    orderBy={orderBy}></OrderArrows>
                                </VStack>
                              </Flex>
                            </Th>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {categories.map((category, i) => {
                          return (
                            <Tr>
                              <Td
                                maxW={'400px'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {category.categoryName}
                              </Td>
                              <Td
                                textAlign={'center'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {category.categoryLevel}
                              </Td>
                              <Td
                                maxW={'400px'}
                                bg={
                                  i % 2 == 0 ? notEvenRowColor : evenRowColor
                                }>
                                {category.categoryParents}
                              </Td>
                              {!isOperative && (
                                <Td
                                  textAlign={'center'}
                                  bg={
                                    i % 2 == 0 ? notEvenRowColor : evenRowColor
                                  }>
                                  <HStack pl={2}>
                                    {!category.relevant && <Text>No</Text>}
                                    <Switch
                                      mx={2}
                                      mt={1}
                                      isDisabled={isOperative}
                                      sx={{
                                        'span.chakra-switch__track:not([data-checked])':
                                          { backgroundColor: 'red' },
                                      }}
                                      isChecked={category.relevant}
                                      onChange={async () => {
                                        await apiHelpers.patchCategory(
                                          category.id,
                                          {
                                            relevant: !category.relevant,
                                          },
                                        );
                                        getCategories();
                                      }}
                                      colorScheme={
                                        !category.relevant ? 'red' : 'green'
                                      }></Switch>
                                    {category.relevant && <Text>Si</Text>}
                                  </HStack>
                                </Td>
                              )}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </>
                  ) : (
                    <Center minW={'full'}>
                      <Text fontSize={20} textAlign={'center'} p={6}>
                        No se encontraron resultados
                      </Text>
                    </Center>
                  )}
                </Table>
                <Box className="my-fourth-step">
                  <Pagination
                    onChangePage={handleChangePage}
                    currentPage={currentPage}
                    totalPages={getNumberOfPages()}
                  />
                </Box>
              </TableContainer>
            </VStack>
          </Flex>
        </Box>
      </VStack>
    </Flex>
  );
};
export default Categories;
