import React from 'react';
import { Navigate, Route, Outlet } from 'react-router-dom';
import apiHelpers from '../utils/apiHelpers';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = apiHelpers.isAuthenticated();

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
