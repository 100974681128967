import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import { Link, useNavigate } from 'react-router-dom';
import './User.css';
import UserFilters from './UserFilters';
import { colors } from '../../theme';
import {
  Flex,
  VStack,
  Heading,
  Spacer,
  TableContainer,
  Table,
  TableCaption,
  Stack,
  Skeleton,
  Thead,
  Tr,
  Th,
  Text,
  Box,
  useBreakpointValue,
  Center,
  Tbody,
  Td,
  Switch,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useAlert } from 'react-alert';

const User = () => {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [pageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState([false]);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    if (window.navigator.userAgentData.mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    getUsers();
  }, []);

  const patchUserNotification = async userToUpdate => {
    let userIndex = users.findIndex(u => u.id == userToUpdate.id);
    try {
      const response = await apiHelper.patchUserNotification(userToUpdate.id, {
        notification: !userToUpdate.notification,
      });
      let updatedUsers = [...users];
      updatedUsers[userIndex] = response.data.user;
      setUsers(updatedUsers);
      alert.success('Usuario modificado correctamente');
    } catch (error) {
      alert.error('Ocurrio un error al modificar el usuario');
    }
  };

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [usersResponse] = await Promise.all([
        await apiHelper.getUsers(filtersWithPaging),
        // await apiHelper.getUsersCount(filters),
      ]);
      setUsers(usersResponse.data);
      setCount(usersResponse.data.length);
      setIsLoading(false);
    } catch (e) {
      console.log('Error loading users');
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await apiHelper.deleteUser(userToDelete.id);
      let updatedUsers = users.filter(u => u.id != userToDelete.id);
      setUsers(updatedUsers);
      setUserToDelete(null);
      setDeleteUserModalOpen(false);
      alert.success('Usuario eliminado correctamente');
    } catch (error) {
      console.log(error);
      alert.error('Ocurrio un error al eliminar el usuario');
    }
  };

  return (
    <Flex w="100%" justify="center">
      <VStack w="100%">
        <Flex
          my={4}
          bg={colors.generic['admin-headers']}
          w="100%"
          color={'white'}
          minH={'100px'}
          py={4}
          justify="space-between"
          align="center"
          px={'70px'}
          direction={{ base: 'column', md: 'row' }}>
          <Heading>Usuarios</Heading>
          <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
            <Button
              variant="primary-button"
              onClick={() => {
                navigate('/users/new');
              }}
              w={240}>
              <Text mr={4}>Crear Usuario</Text>
              <AddIcon mb={1} />
            </Button>
          </Flex>
        </Flex>
        <Spacer />

        {/* <UserFilters loadData={handleChangeFilters} /> */}

        <TableContainer
          className="my-third-step"
          overflowX={isMobile ? 'scroll' : 'auto'}
          whiteSpace={'normal'}
          maxW={{ md: 'full', lg: '1200px', xl: '1200px' }}
          minW={{ md: 'full', lg: '1200px', xl: '1200px' }}>
          <Table
            variant="simple"
            size={useBreakpointValue({ md: 'md', base: 'sm' })}
            mb={'20px'}>
            {isLoading || (isLoading && !users.length) ? (
              <Stack my={3} w="full">
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            ) : users.length ? (
              <>
                <Thead bgColor={colors.generic['admin-table-headers']}>
                  <Tr m="0px" p="0px">
                    <Th color={colors.generic[3]}>Nombre</Th>
                    <Th color={colors.generic[3]}>Apellido</Th>
                    <Th color={colors.generic[3]}>Usuario</Th>
                    <Th color={colors.generic[3]}>Tipo de usuario</Th>
                    <Th color={colors.generic[3]}>Notificar</Th>
                    <Th color={colors.generic[3]}>Modificar</Th>
                    <Th color={colors.generic[3]}>Eliminar</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((user, i) => {
                    return (
                      <Tr m="0" p="0px">
                        <Td
                          bg={
                            i % 2 == 0
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          {user.firstName}
                        </Td>
                        <Td
                          bg={
                            i % 2 == 0
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          {user.lastName}
                        </Td>
                        <Td
                          bg={
                            i % 2 == 0
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          {user.username}
                        </Td>
                        <Td
                          bg={
                            i % 2 == 0
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          {user.role}
                        </Td>
                        <Td
                          bg={
                            i % 2 == 0
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          <Flex>
                            {!user.notification && <Text>No</Text>}
                            <Switch
                              mx={2}
                              mt={1}
                              sx={{
                                'span.chakra-switch__track:not([data-checked])':
                                  { backgroundColor: 'red' },
                              }}
                              isChecked={user.notification}
                              onChange={() => {
                                patchUserNotification(user);
                              }}
                              colorScheme={
                                !user.notification ? 'red' : 'green'
                              }></Switch>
                            {user.notification && <Text>Si</Text>}
                          </Flex>
                        </Td>
                        <Td
                          bg={
                            i % 2 == 0
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          <Flex
                            w={'100%'}
                            justifyContent={'flex-start'}
                            pl={'28px'}>
                            <Link
                              to={`${process.env.PUBLIC_URL}/users/${user.id}`}>
                              <i
                                className="fa fa-edit"
                                style={{ fontSize: '20px' }}
                              />
                            </Link>
                          </Flex>
                        </Td>
                        <Td
                          bg={
                            i % 2 == 0
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          <Flex w={'100%'} justifyContent={'flex-start'}>
                            <Button
                              bg={
                                i % 2 == 0
                                  ? colors.generic['not-even-row']
                                  : colors.generic['even-row']
                              }
                              _hover={{ color: 'red' }}
                              onClick={() => {
                                setUserToDelete(user);
                                setDeleteUserModalOpen(true);
                              }}>
                              <i
                                className="fa fa-user-slash"
                                style={{ fontSize: '20px' }}
                              />
                            </Button>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </>
            ) : (
              <Center minW={'full'}>
                <Text fontSize={20} textAlign={'center'} p={6}>
                  No se encontraron resultados
                </Text>
              </Center>
            )}
          </Table>
        </TableContainer>
      </VStack>
      <Modal
        isOpen={deleteUserModalOpen}
        onClose={() => {
          setDeleteUserModalOpen(false);
          setUserToDelete(null);
        }}
        size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {userToDelete &&
              `¿Desea eliminar al usuario ${userToDelete.firstName} ${userToDelete.lastName}?`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent={'flex-end'} gap={3}>
              <Button
                variant="primary-button"
                w={'120px'}
                h={'45px'}
                onClick={handleDelete}>
                Eliminar
              </Button>
              <Button
                onClick={() => {
                  setDeleteUserModalOpen(false);
                  setUserToDelete(null);
                }}
                colorScheme="red"
                w={'120px'}
                h={'45px'}>
                Cancelar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default User;
