import React from 'react';
import { Flex, IconButton, Tooltip, Text } from '@chakra-ui/react';
import {
  ArrowRightIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowLeftIcon,
} from '@chakra-ui/icons';

const Pagination = ({ totalPages, currentPage, onChangePage }) => {
  if (!totalPages) return null;

  return (
    <Flex justifyContent="center" alignItems="center">
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            onClick={() => onChangePage(1)}
            isDisabled={currentPage === 1}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            onClick={() => onChangePage(currentPage - 1)}
            isDisabled={currentPage <= 1}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center" p={4}>
        <Text flexShrink="0">
          Página {currentPage} de {totalPages}
        </Text>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            onClick={() => onChangePage(currentPage + 1)}
            isDisabled={currentPage >= totalPages}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            onClick={() => onChangePage(totalPages)}
            isDisabled={currentPage >= totalPages}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Pagination;
