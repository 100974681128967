import React, { useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  FormControl,
  Link,
  Text,
  useColorModeValue,
  Image,
  Center,
  Stack,
  Input,
  Alert,
  useBreakpointValue,
  AlertIcon,
  AlertDescription,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import BranchesContext from '../../contexts/BranchesContext';
import { colors } from '../../theme';
const config = require('../../config/config');

const Login = props => {
  let navigate = useNavigate();
  const boxBg = useColorModeValue('#d8d8d8', '#1E2626');
  const forgotPasswordTextColor = useColorModeValue('#858589', 'white');
  const mainColor = colors.generic['1'];
  const [loading, setLoading] = useState(false);
  const captchaTheme = useColorModeValue('light', 'dark');
  const [state, setState] = useState({
    email: '',
    password: '',
    errorMessage: null,
    key: '',
  });

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCaptchaComplete = value => {
    setState(prevState => ({
      ...prevState,
      ['key']: value,
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      setLoading(true);
      await apiHelper.login({
        email: state.email,
        password: state.password,
        key: state.key,
      });
      setLoading(false);
      props.loadContexts();
      navigate('/');
    } catch (error) {
      setLoading(false);

      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 401) {
        errorMessage = 'Email o contraseña invalidas';
      } else if (statusCode === 403) {
        errorMessage = 'Captcha invalido';
      } else {
        errorMessage = error.message;
      }

      setState(prevState => ({
        ...prevState,
        ['errorMessage']: errorMessage,
      }));
    }
  };

  let alert;

  if (state.errorMessage) {
    alert = (
      <Alert w={{ base: '100%', md: '310px' }} status="error">
        <AlertIcon />
        <AlertDescription>{state.errorMessage}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Box
      bgColor={boxBg}
      borderRadius="lg"
      boxShadow={useColorModeValue('dark-lg', '0 8px 22px #5B7D76')}
      mb={5}
      m={useBreakpointValue({ base: 5, md: 4 })}
      w={{ base: '100%', md: '350px' }}>
      <Flex alignItems="center" justifyContent="center" w={'100%'}>
        <Stack direction="column" py={3}>
          <Box>
            <VStack>
              <Center mt={2}>
                <Image
                  src={useColorModeValue(
                    process.env.PUBLIC_URL + '/img/login_logo_light.png',
                    process.env.PUBLIC_URL + '/img/login_logo_light.png',
                  )}
                  width="260px"
                  alt="logo"
                />
              </Center>

              <Text
                color={useColorModeValue('white', 'white')}
                fontFamily={'Kanit,sans-serif'}
                fontSize={32}
                fontWeight={500}>
                Ingrese sus datos
              </Text>
            </VStack>
          </Box>
          <VStack spacing={6}>
            <FormControl px={4}>
              <VStack spacing={8}>
                <Input
                  borderColor={mainColor}
                  errorBorderColor={mainColor}
                  focusBorderColor={mainColor}
                  border="3px solid"
                  background="white"
                  placeholder="Correo electrónico"
                  _focus={{
                    border: '3px solid',
                    borderColor: mainColor,
                  }}
                  _hover={{
                    border: '3px solid',
                    borderColor: mainColor,
                  }}
                  padding={4}
                  paddingY="21px"
                  fontSize={17}
                  isRequired
                  type="text"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                />
                <Input
                  placeholder="Contraseña"
                  borderColor={mainColor}
                  errorBorderColor={mainColor}
                  focusBorderColor={mainColor}
                  border="3px solid"
                  background="white"
                  _focus={{
                    border: '3px solid',
                    borderColor: mainColor,
                  }}
                  _hover={{
                    border: '3px solid',
                    borderColor: mainColor,
                  }}
                  padding={4}
                  paddingY="21px"
                  fontSize={17}
                  type="password"
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                  isRequired
                />
              </VStack>
              <Box py={3}>
                <ReCAPTCHA
                  label="captcha"
                  id="captcha"
                  name="captcha"
                  sitekey={config.captcha}
                  onChange={onCaptchaComplete}
                  theme={captchaTheme}
                />
              </Box>
              <Button
                variant="primary-button"
                disabled={loading}
                color={useColorModeValue('white', 'black')}
                onClick={handleSubmit}
                formAction="index.html">
                Ingresar
                {loading && <Spinner ml={5}></Spinner>}
              </Button>
            </FormControl>
            {alert}
          </VStack>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            m={8}>
            <Text color={forgotPasswordTextColor} fontWeight={100}>
              Olvidó su contraseña?
              <Link
                href="/recover_password"
                ms="5px"
                color={forgotPasswordTextColor}
                fontWeight="700"
                _hover={{ color: 'primary.700', fontWeight: 500 }}>
                Restablecer
              </Link>
            </Text>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Login;
