import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Tooltip,
  Button,
  useColorModeValue,
  Input,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react';
import { DeleteIcon, SearchIcon, DownloadIcon } from '@chakra-ui/icons';
import SelectCategory from '../common/selects/SelectCategory';
import SelectRelevant from '../common/selects/SelectRelevant';
import SelectCategoryLevel from '../common/selects/SelectCategoryLevel';
import SelectCategoryParent from '../common/selects/SelectCategoryParent';

const CategoryFilters = props => {
  const color = useColorModeValue('black', 'white');
  const initialState = {
    relevant: null,
    categoryInternalId: '',
    categoryLevel: null,
    categoryParents: null,
  };
  const [parentLevel, setParentLevel] = useState(null);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    console.log(parentLevel);
  }, [parentLevel]);

  const onFiltersSubmit = () => {
    props.loadData(getFiltersObject(state));
  };
  const extraButton = props.extraButton;

  const getFiltersObject = values => {
    return {
      where: {
        relevant: values.relevant,
        categoryInternalId: values.categoryInternalId
          ? values.categoryInternalId
          : null,
        categoryLevel: values.categoryLevel ? values.categoryLevel : null,
        categoryParents: values.categoryParents ? values.categoryParents : null,
      },
    };
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  const onFiltersReset = () => {
    setState(initialState);
    props.loadData(getFiltersObject(initialState));
    setParentLevel(null);
  };

  return (
    <Box>
      <Text ml={2} as="b" color="#CCCCCC">
        Filtros
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, lg: 4 }} rows={{ lg: 2 }}>
        <Tooltip
          label="Filtrar por nombre"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box
              borderRadius="md"
              border={'1px solid'}
              borderColor={color}
              placeholder="nombre"
              _placeholder={{
                color: color,
              }}
              _hover={{
                fontSize: '15px',
              }}
              maxW={'380px'}
              id="categoryInternalId"
              minW={'220px'}>
              {' '}
              <SelectCategory
                placeholder="Nombre"
                level={'all'}
                value={state.categoryInternalId}
                name={'categoryInternalId'}
                onChange={handleChange}></SelectCategory>
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por nivel"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box
              borderRadius="md"
              border={'1px solid'}
              borderColor={color}
              placeholder="Nivel"
              value={state.relevant}
              _placeholder={{
                color: color,
              }}
              _hover={{
                fontSize: '15px',
              }}
              id="relevant"
              maxW={'380px'}
              minW={'220px'}>
              <SelectCategoryLevel
                placeholder="Nivel"
                value={state.categoryLevel}
                name={'categoryLevel'}
                onChange={handleChange}
                parentLevel={parentLevel}></SelectCategoryLevel>
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por Padre"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box
              borderRadius="md"
              border={'1px solid'}
              borderColor={color}
              placeholder="padre"
              _placeholder={{
                color: color,
              }}
              _hover={{
                fontSize: '15px',
              }}
              maxW={'380px'}
              id="categoryParents"
              minW={'220px'}>
              {' '}
              <SelectCategoryParent
                placeholder="Padre"
                level={state.categoryLevel}
                value={state.categoryParents}
                name={'categoryParents'}
                onChange={handleChange}
                setParentLevel={setParentLevel}></SelectCategoryParent>
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por si es relevante o no"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box
              borderRadius="md"
              border={'1px solid'}
              borderColor={color}
              placeholder="Relevante"
              value={state.relevant}
              _placeholder={{
                color: color,
              }}
              _hover={{
                fontSize: '15px',
              }}
              id="relevant"
              maxW={'380px'}
              minW={'220px'}>
              <SelectRelevant
                placeholder="Relevante"
                value={state.relevant}
                name={'relevant'}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Tooltip>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, sm: 1, lg: 4 }} rows={{ lg: 1 }}>
        {extraButton && (
          <Box my={1} mx={1}>
            {extraButton}
          </Box>
        )}
        <Spacer></Spacer>
        <Box my={1} mx={1}>
          <Button
            variant="reset-filters-button"
            onClick={onFiltersReset}
            type="reset"
            isLoading={props.isLoading}>
            <Text color="black" px={4} display={{ base: 'none', sm: 'block' }}>
              Limpiar filtros
            </Text>
            <DeleteIcon color={'black'} />
          </Button>
        </Box>
        <Box my={1} mx={1}>
          <Button
            variant="submit-filters-button"
            onClick={onFiltersSubmit}
            type="submit"
            isLoading={props.isLoading}>
            <Text px={4} display={{ base: 'none', sm: 'block' }}>
              Aplicar filtros
            </Text>
            <SearchIcon />
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default CategoryFilters;
