import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { colors } from '../../../theme';
const SelectCategoryLevel = props => {
  const [options, setOptions] = useState(
    [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
    ].filter(l => (props.parentLevel ? l.value > props.parentLevel : true)),
  );
  const [input, setInput] = useState('');
  const [value, setValue] = useState(null);
  const color = 'black';
  const colorBg = 'white';
  const colorHover = colors.primary['50'];
  const colorHoverOption = colors.primary['200'];
  useEffect(() => {
    let { value } = props;
    setValue(value);
  }, [props.value]);

  useEffect(() => {
    setOptions(
      [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
      ].filter(l =>
        props.parentLevel ? l.value == props.parentLevel + 1 : true,
      ),
    );
  }, [props.parentLevel]);
  const handleChange = selectedValue => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-options-nameless';
    event.target.value = selectedValue.value;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '0px solid black',
      backgroundColor: 'none',
      outline: 'none',
      '&:hover': { backgroundColor: colorHover },
    }),
    placeholder: () => ({
      color: color,
      marginLeft: '8px',
      fontSize: '16px',
      '&:hover': { fontSize: '15px' },
    }),

    singleValue: () => ({
      color: color,
    }),

    menuList: () => ({
      color: color,
      backgroundColor: colorBg,
    }),
    option: (provider, state) => ({
      ...provider,
      '&:hover': { backgroundColor: colorHoverOption },
      backgroundColor: state.isFocused ? colorHoverOption : 'none',
      color: color,
    }),
  };

  return (
    <AsyncSelect
      styles={customStyles}
      onChange={handleChange}
      defaultOptions={options}
      value={
        value !== null ? options.find(option => option.value === value) : null
      }
      name={props.name}
      required={props.required}
      isDisabled={props.disabled}
      placeholder={props.placeholder ? props.placeholder : 'Es relevante'}
    />
  );
};

export default SelectCategoryLevel;
