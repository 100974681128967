import {
  Box,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';
import apiHelpers from '../../utils/apiHelpers';

export default function PrivateLayout({ children }) {
  const [links, setLinks] = useState([
    { label: 'Productos', href: '/products' },
    { label: 'Categorias', href: '/categories' },
  ]);

  useEffect(() => {
    apiHelpers.getCurrentUser().then(response => {
      response.data.role == 'ADMIN'
        ? setLinks([
            { label: 'Panel', href: '/panel' },
            { label: 'Productos', href: '/products' },
            { label: 'Categorias', href: '/categories' },
            { label: 'Reporte dinámico', href: '/dinamicReport' },
            { label: 'Usuarios', href: '/users' },
            { label: 'Notificaciones', href: '/notifications' },

            // { label: 'Procesos', href: '/manageCronProcesses' },
          ])
        : setLinks([
            { label: 'Panel', href: '/panel' },
            { label: 'Productos', href: '/products' },
            { label: 'Categorias', href: '/categories' },
            { label: 'Reporte dinámico', href: '/dinamicReport' },
          ]);
    });
  }, []);

  return (
    <Box as="section" minH="100vh">
      <NavBar publicLayout={false} links={links} />
      <Flex
        spacing={2}
        h="full"
        w="full"
        bgColor={useColorModeValue('#ffffff', '#404040d2')}
        direction={'column'}
        minH="86vh">
        <VStack>{children}</VStack>
      </Flex>
      <Footer />
    </Box>
  );
}
