import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Tooltip,
  Button,
  useColorModeValue,
  Input,
  SimpleGrid,
  Spacer,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  DeleteIcon,
  SearchIcon,
  DownloadIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import SelectRelevant from '../common/selects/SelectRelevant';
import SelectProductFormat from '../common/selects/SelectProductFormat';
import moment from 'moment';
import SelectDates from '../common/selects/SelectDates';
import SelectBrand from '../common/selects/SelectBrand';
import SelectCategoriesFilter from '../common/SelectCategoriesFIlter';

const ProductFilters = props => {
  const color = useColorModeValue('black', 'white');
  const colorTextButton = useColorModeValue('white', 'black');
  const colorHover = useColorModeValue('#DDDDDD', '#6B6B6B');
  const initialState = {
    productEan: '',
    productName: '',
    productBrand: [],
    productFormat: [],
    day_from: null,
    day_to: null,
    low_date_from: null,
    low_date_to: null,
    relevant: null,
    cat_1: null,
    cat_2: null,
    cat_3: null,
    cat_4: null,
    coverage: '',
  };
  const [state, setState] = useState(initialState);
  const [datesFilter, setDatesFilter] = useState([null, null]);
  const [datesLowFilter, setDatesLowFilter] = useState([null, null]);

  const mainColor = useColorModeValue('#E31D1C', 'black');

  const extraButton = props.extraButton;

  const onFiltersSubmit = () => {
    props.loadData(getFiltersObject(state));
  };

  const getFiltersObject = values => {
    return {
      where: {
        productEan: values.productEan ? values.productEan : null,
        productName: values.productName ? values.productName : null,
        productBrand: values.productBrand ? values.productBrand : null,
        productFormat: values.productFormat ? values.productFormat : null,
        day_from:
          datesFilter && datesFilter[0]
            ? moment(datesFilter[0]).toISOString('es-AR')
            : null,
        day_to:
          datesFilter && datesFilter[1]
            ? moment(datesFilter[1]).toISOString('es-AR')
            : null,
        low_date_from:
          datesLowFilter && datesLowFilter[0]
            ? moment(datesLowFilter[0]).toISOString('es-AR')
            : null,
        low_date_to:
          datesLowFilter && datesLowFilter[1]
            ? moment(datesLowFilter[1]).toISOString('es-AR')
            : null,
        relevant: values.relevant ? values.relevant : null,
        cat_1: values.cat_1 ? values.cat_1 : null,
        cat_2: values.cat_2 ? values.cat_2 : null,
        cat_3: values.cat_3 ? values.cat_3 : null,
        cat_4: values.cat_4 ? values.cat_4 : null,
        coverage: values.coverage ? values.coverage : null,
      },
    };
  };

  const getFiltersReset = () => {
    return {
      where: {
        productEan: null,
        productName: null,
        productBrand: null,
        productFormat: null,
        day_from: null,
        day_to: null,
        low_date_from: null,
        low_date_to: null,
        relevant: null,
        cat_1: null,
        cat_2: null,
        cat_3: null,
        cat_4: null,
      },
    };
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  const onFiltersReset = () => {
    setState(initialState);
    setDatesFilter([null, null]);
    setDatesLowFilter([null, null]);
    props.loadData(getFiltersReset(initialState));
  };

  return (
    <Box>
      <Text ml={2} as="b" color="#CCCCCC">
        Filtros
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 4 }} rows={{ lg: 3 }}>
        <Tooltip
          label="Filtrar por fecha de alta"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box layerStyle={'input-box'} id="datesFilter">
              <SelectDates
                // color={color}
                placeholder="Fecha de alta"
                value={datesFilter}
                onChange={setDatesFilter}
              />
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por fecha de alta"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box layerStyle={'input-box'} id="datesFilterLow">
              <SelectDates
                placeholder="Fecha de baja"
                value={datesLowFilter}
                onChange={setDatesLowFilter}
              />
            </Box>
          </Box>
        </Tooltip>
        <Tooltip label="Filtrar por EAN" placement="top" arrowSize={5} hasArrow>
          <Box mb={1} mx={1}>
            <InputGroup>
              <Input
                placeholder="EAN"
                variant="primary-input"
                value={state.productEan}
                id="productEan"
                name="productEan"
                onChange={handleChange.bind(this)}
                _placeholder={{ color: 'black' }}
              />
              {state.productEan !== '' && (
                <InputRightElement
                  children={
                    <Button
                      p={0}
                      m={0}
                      boxSize={0}
                      onClick={() =>
                        setState(prevState => {
                          return { ...prevState, productEan: '' };
                        })
                      }
                      bg={'none'}>
                      <CloseIcon color="grey" boxSize={3} />
                    </Button>
                  }
                />
              )}
            </InputGroup>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por descripción"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <InputGroup>
              <Input
                variant="primary-input"
                value={state.productName}
                placeholder="Descripción"
                name="productName"
                onChange={handleChange}
                _placeholder={{ color: 'black' }}
              />
              {state.productName !== '' && (
                <InputRightElement
                  children={
                    <Button
                      p={0}
                      m={0}
                      boxSize={0}
                      onClick={() =>
                        setState(prevState => {
                          return { ...prevState, productName: '' };
                        })
                      }
                      bg={'none'}>
                      <CloseIcon color="grey" boxSize={3} />
                    </Button>
                  }
                />
              )}
            </InputGroup>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por marca"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box layerStyle={'input-box'} placeholder="Marca" id="productBrand">
              <SelectBrand
                placeholder={'Marca'}
                name={'productBrand'}
                id="productBrand"
                value={state.productBrand}
                onChange={handleChange}></SelectBrand>
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por formato"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box layerStyle={'input-box'} id="productFormat">
              <SelectProductFormat
                key={
                  !state.productFormat
                    ? `clearer__${state.productFormat}`
                    : 'productFormat'
                }
                placeholder="Formato"
                value={state.productFormat}
                name={'productFormat'}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Tooltip>

        <Tooltip
          label="Filtrar por productos de los cuales recibe o no notificaciones"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <Box
              layerStyle={'input-box'}
              placeholder="Notificar"
              value={state.relevant}
              id="relevant">
              <SelectRelevant
                placeholder="Notificar"
                value={state.relevant}
                name={'relevant'}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por puntos de venta mínimos"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box mb={1} mx={1}>
            <InputGroup>
              <Input
                variant="primary-input"
                _placeholder={{ color: 'black' }}
                value={state.coverage}
                placeholder="Puntos de venta Mínimos"
                name="coverage"
                onChange={handleChange}
              />
              {state.coverage !== '' && (
                <InputRightElement
                  children={
                    <Button
                      p={0}
                      m={0}
                      boxSize={0}
                      onClick={() =>
                        setState(prevState => {
                          return { ...prevState, coverage: '' };
                        })
                      }
                      bg={'none'}>
                      <CloseIcon color="grey" boxSize={3} />
                    </Button>
                  }
                />
              )}
            </InputGroup>
          </Box>
        </Tooltip>
      </SimpleGrid>
      <SelectCategoriesFilter
        onChange={handleChange}
        state={state}></SelectCategoriesFilter>
      <SimpleGrid columns={{ base: 1, sm: 1, lg: 4 }} rows={{ lg: 1 }}>
        {extraButton && extraButton}
        <Spacer></Spacer>
        <Box className="my-clean-filter-step" my={1} mx={1}>
          <Button
            variant="reset-filters-button"
            onClick={onFiltersReset}
            type="reset"
            isLoading={props.isLoading}>
            <Text px={4} display={{ base: 'none', sm: 'block' }}>
              Limpiar filtros
            </Text>
            <DeleteIcon color={'black'} />
          </Button>
        </Box>
        <Box className="my-filter-step" my={1} mx={1}>
          <Button
            variant="submit-filters-button"
            onClick={onFiltersSubmit}
            type="submit"
            isLoading={props.isLoading}>
            <Text px={4} display={{ base: 'none', sm: 'block' }}>
              Aplicar filtros
            </Text>
            <SearchIcon />
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default ProductFilters;
