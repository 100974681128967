import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useColorMode } from '@chakra-ui/react';
import moment from 'moment';

const PriceModaByProvinceChart = props => {
  const color = useColorMode('black', 'lightgrey');
  const [dataArray, setDataArray] = useState([]);
  const [seriesArray, setSeriesArray] = useState([]);

  useEffect(() => {
    for (let pricesOfProductEan of props.data) {
      const firstDateData = {
        fecha: moment(props.initialDate).startOf('day').toDate().getTime(),
        color: pricesOfProductEan[0].color,
        province: pricesOfProductEan[0].province,
      };

      const lastDateDate = {
        fecha: moment(props.finishDate).startOf('day').toDate().getTime(),
        color: pricesOfProductEan[0].color,
        province: pricesOfProductEan[0].province,
      };
      pricesOfProductEan.push(firstDateData);
      pricesOfProductEan.unshift(lastDateDate);
      let newArray = dataArray;

      newArray.push(pricesOfProductEan);
      setDataArray(newArray);
    }

    am5.ready(function () {
      let root = am5.Root.new('chartdivModa');

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          layout: root.verticalLayout,
          pinchZoomX: true,
        }),
      );

      let xRenderer = am5xy.AxisRendererX.new(root, {});
      xRenderer.grid.template.setAll({ forceHidden: true, fill: '#888888' });
      xRenderer.labels.template.setAll({
        multiLocation: 0,
        location: 0,
        fill: '#888888',
      });

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'hour', count: 10 },
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
          extraMin: 0.01,
          extraMax: 0.01,
          tooltipLocation: 0,
        }),
      );

      let yRenderer = am5xy.AxisRendererY.new(root, {});
      yRenderer.grid.template.setAll({ forceHidden: true, fill: '#888888' });
      yRenderer.labels.template.setAll({
        multiLocation: 0,
        location: 0,
        fill: '#888888',
      });

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          renderer: yRenderer,
        }),
      );

      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
          xAxis: xAxis,
        }),
      );
      cursor.lineX.set('stroke', '#888888');
      cursor.lineY.set('stroke', '#888888');

      let newSeries = [];

      for (let i = 0; i < dataArray.length; i++) {
        let series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: dataArray[i][0].province,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'moda',
            valueXField: 'fecha',
            locationX: 0,
            stroke: am5.color(dataArray[i][0].color),
            seriesTooltipTarget: 'bullet',
            showTooltipOn: 'always',
            tooltip: am5.Tooltip.new(root, {
              labelText: dataArray[i][0].province + ' ' + '${valueY}',
            }),
          }),
        );
        series.bullets.push(function () {
          let circleTemplate = am5.Template.new({
            radius: 6,
            templateField: 'bulletSettings',
            fill: am5.color(dataArray[i][0].color),
            strokeWidth: 2,
            tooltipText: '${valueY}',
            stroke: am5.color(dataArray[i][0].color),
          });

          let circle = am5.Circle.new(root, {}, circleTemplate);

          return am5.Bullet.new(root, {
            sprite: circle,
            locationX: 0,
            tooltip: am5.Tooltip.new(root, {
              labelText: '{valueY}',
            }),
          });
        });

        series.set('setStateOnChildren', true);
        series.states.create('hover', {});

        series.mainContainer.set('setStateOnChildren', true);
        series.mainContainer.states.create('hover', {});

        series.strokes.template.states.create('hover', {
          strokeWidth: 4,
        });

        series.appear(1000);

        series.data.setAll(dataArray[i]);
        series.appear(1000);
        newSeries.push(series);
      }

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      );

      legend.itemContainers.template.states.create('hover', {});

      legend.itemContainers.template.events.on('pointerover', function (e) {
        e.target.dataItem.dataContext.hover();
      });
      legend.itemContainers.template.events.on('pointerout', function (e) {
        e.target.dataItem.dataContext.unhover();
      });

      legend.data.setAll(chart.series.values);

      setSeriesArray(newSeries);
      chart.appear(1000, 100);

      return () => root.dispose();
    });
  }, []);

  useEffect(() => {
    if (seriesArray !== null && seriesArray.length) {
      seriesArray.map((series, index) => {
        if (props.data.length) series.data.setAll(dataArray[index]);
        series.appear(1000);
      });

      setSeriesArray(seriesArray);
    }
  }, [props.data, seriesArray]);

  return (
    <div
      id="chartdivModa"
      style={{
        width: '100%',
        height: '390px',
        background: color,
      }}
    />
  );
};
export default PriceModaByProvinceChart;
