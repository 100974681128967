import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

export default () => {
  return (
    <Box bg="generic.1" px={4} w={'full'}>
      <Flex h="6vh" alignItems={'center'} justifyContent={'center'}>
        <Text textColor={useColorModeValue('white', 'white')}>
          Made with <span style={{ color: 'white' }}>♥</span> by VenTuring
        </Text>
      </Flex>
    </Box>
  );
};
