import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  Td,
  Th,
  Tr,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Button,
  Thead,
  VStack,
  Stack,
  Spacer,
  Heading,
  Center,
  Skeleton,
  Divider,
  ButtonGroup,
  Input,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import apiHelpers from '../../utils/apiHelpers';
import Joyride, { STATUS, EVENTS, ACTIONS } from 'react-joyride';
import JoyrideContext from '../../contexts/JoyrideContext';

const CronProcesses = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const { isJoyrideActive, setJoyride } = useContext(JoyrideContext);
  const boxBg = useColorModeValue('white', '#1E2626');
  const TheadBg = useColorModeValue('#E31D1C', '#00655E');
  const color = useColorModeValue('black', 'white');
  const colorHover = useColorModeValue('#DDDDDD', '#6B6B6B');
  const colorButtonRelevant = useColorModeValue('white', 'Black');
  const colorButtonNotRelevant = useColorModeValue('#757575', '#DBDBDB');
  const [currentUser, setCurrentUser] = useState(null);
  const [priceMetricsDate, setPriceMetricsDate] = useState(null);
  const [preserveMetrics, setPreserveMetrics] = useState(true);
  const [missingPricesDate, setMissingPricesDate] = useState(null);
  const [runOnboarding, setRunOnboarding] = useState(false);

  const mainColor = useColorModeValue('#E31D1C', 'black');
  const notEvenRowColor = useColorModeValue('#e4e4e4', 'black');
  const evenRowColor = useColorModeValue('#d8d8d8', 'black');

  const [steps, setSteps] = useState({
    steps: [
      {
        target: '.my-first-step',
        content: (
          <Text as="b" fontSize="18px">
            Bienvenido al listado de procesos, aquí encontrarás todo lo
            relacionado con los procesos automáticos.
          </Text>
        ),
        title: (
          <Flex flexDirection={'column'}>
            <i class="fa fa-solid fa-list" style={{ fontSize: '4em' }}></i>
            <Text as="b" fontSize={'40px'}>
              ¡Bienvenido!
            </Text>
          </Flex>
        ),
        disableBeacon: true,
      },
      {
        target: '.my-second-step',
        content: (
          <Text as="b" fontSize="18px">
            En el listado encontrarás la descripción de los procesos y la opción
            para ejecutarlos.
          </Text>
        ),
        title: (
          <Text as="b" fontSize={'40px'}>
            Listado
          </Text>
        ),
        disableBeacon: true,
      },
      {
        target: '.my-third-step',
        content: (
          <Text as="b" fontSize="18px">
            Haciendo clic en los botones de "iniciar" o "notificar", podrás
            empezar la ejecución del proceso seleccionado.
          </Text>
        ),
        title: (
          <Text as="b" fontSize={'40px'}>
            Acciones
          </Text>
        ),
        disableBeacon: true,
      },
    ],
  });

  useEffect(() => {
    apiHelpers.getCurrentUser().then(response => {
      setCurrentUser(response.data);
      setRunOnboarding(response.data.onboardingProcesses);
    });
  }, []);

  const handleJoyrideCallback = data => {
    const { status, action, index, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
      setJoyride(true);
    } else if (action == 'close') {
      setJoyride(false);
    }
  };

  const handleChangePriceMetricsDate = event => {
    setPriceMetricsDate(event.target.value);
  };

  const handleChangeMissingPricesDate = event => {
    setMissingPricesDate(event.target.value);
  };

  return (
    <Flex w="100%" justify="center">
      <VStack w="100%">
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={isJoyrideActive}
          scrollToFirstStep
          stepIndex={stepIndex}
          showProgress
          showSkipButton
          steps={steps.steps}
          locale={{
            next: <span>Continuar</span>,
            skip: '',
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              backgroundColor: mainColor,
              color: 'white',
            },
            buttonBack: {
              color: '#000000',
            },
            buttonClose: {
              backgroundColor: '#008080',
              color: '#000000',
            },
          }}
        />
        <Flex
          bg={mainColor}
          w="100%"
          color={'white'}
          minH={'100px'}
          py={4}
          justify="space-around"
          align="center"
          direction={{ base: 'column', md: 'row' }}>
          <Heading ml={2} className="my-first-step">
            Lista de Procesos
          </Heading>
        </Flex>
        <Spacer />
        <Box bgColor={boxBg} border={'none'}>
          <Flex alignItems="center" justifyContent="center">
            <VStack
              m={{ base: 0, md: 2, lg: 3, xl: 3 }}
              direction="column"
              py={0}>
              <TableContainer
                className="my-second-step"
                whiteSpace={'normal'}
                maxW={{ md: 'full', lg: '1180px', xl: '1180px' }}
                minW={{ md: 'full', lg: '1180px', xl: '1180px' }}>
                <Table
                  variant="simple"
                  colorScheme="telegram"
                  size={useBreakpointValue({ md: 'md', base: 'sm' })}>
                  <TableCaption>Lista de procesos</TableCaption>
                  <>
                    <Thead bgColor={TheadBg}>
                      <Tr my=".8rem" pl="0px">
                        <Th color="white">Nombre</Th>
                        <Th color="white">Descripción</Th>
                        <Th className="my-third-step" color="white">
                          Acciones
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td bg={notEvenRowColor}>Sucursales</Td>
                        <Td bg={notEvenRowColor}>
                          Ejecutar proceso de sucursales
                        </Td>
                        <Td bg={notEvenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.getCrawlStores();
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Iniciar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={evenRowColor}>Categorias</Td>
                        <Td bg={evenRowColor}>
                          Ejecutar proceso de categorías
                        </Td>
                        <Td bg={evenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.getCrawlerCategories();
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Iniciar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={notEvenRowColor}>Precios</Td>
                        <Td bg={notEvenRowColor}>
                          Ejecutar proceso para obtención de precios{' '}
                        </Td>
                        <Td bg={notEvenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.getCrawlPrices();
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Iniciar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={evenRowColor}>Métricas de precios</Td>
                        <Td bg={evenRowColor}>
                          Ejecutar proceso para el cálculo de metricas de
                          precios para la fecha
                          <Input
                            placeholder="YYYY-MM-DD"
                            borderColor={color}
                            borderBlock="1.3px solid "
                            borderInline="1.3px solid"
                            bg={boxBg}
                            _focus={{
                              bg: useColorModeValue('#B5D8C9', '#607A7A'),
                              color: 'black',
                              border: '1.3px solid rgb(97, 97, 97)',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}
                            _hover={{
                              fontSize: '14px',
                              bg: colorHover,
                            }}
                            _placeholder={{
                              color: color,
                            }}
                            w={'9rem'}
                            ml={4}
                            isRequired
                            type="text"
                            name="priceMetricsDate"
                            value={priceMetricsDate}
                            onChange={handleChangePriceMetricsDate}
                          />
                          <ButtonGroup
                            spacing={0}
                            gap={0}
                            bg={'grey'}
                            ml={4}
                            borderRadius="lg"
                            isAttached={true}>
                            <Button
                              onClick={e => setPreserveMetrics(true)}
                              m="0px"
                              p={2}
                              colorScheme={
                                preserveMetrics ? 'teal' : 'whiteAlpha'
                              }
                              color={
                                preserveMetrics
                                  ? colorButtonRelevant
                                  : colorButtonNotRelevant
                              }>
                              Conservar
                            </Button>
                            <Button
                              onClick={e => setPreserveMetrics(false)}
                              m="0px"
                              p={2}
                              colorScheme={
                                !preserveMetrics ? 'teal' : 'whiteAlpha'
                              }
                              color={
                                !preserveMetrics
                                  ? colorButtonRelevant
                                  : colorButtonNotRelevant
                              }>
                              Eliminar
                            </Button>
                          </ButtonGroup>
                        </Td>
                        <Td bg={evenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.getCrawlerPricemetrics(
                                priceMetricsDate,
                                preserveMetrics,
                              );
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Iniciar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={notEvenRowColor}>Precios faltantes</Td>
                        <Td bg={notEvenRowColor}>
                          Ejecutar proceso para la generación de precios
                          faltantes para la fecha
                          <Input
                            placeholder="YYYY-MM-DD"
                            borderColor={color}
                            borderBlock="1.3px solid "
                            borderInline="1.3px solid"
                            bg={boxBg}
                            _focus={{
                              bg: useColorModeValue('#B5D8C9', '#607A7A'),
                              color: 'black',
                              border: '1.3px solid rgb(97, 97, 97)',
                              borderRadius: '5px 5px 5px 5px',
                              caretColor: '#004729',
                            }}
                            _hover={{
                              fontSize: '14px',
                              bg: colorHover,
                            }}
                            _placeholder={{
                              color: color,
                            }}
                            w={'9rem'}
                            ml={4}
                            isRequired
                            type="text"
                            name="missingPricesDate"
                            value={missingPricesDate}
                            onChange={handleChangeMissingPricesDate}
                          />
                        </Td>
                        <Td bg={notEvenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.getMissingPrices(
                                missingPricesDate,
                              );
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Iniciar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={evenRowColor}>
                          Notificacion Nuevos productos{' '}
                        </Td>
                        <Td bg={evenRowColor}>
                          Notificación de nuevos productos en las categorías
                          relevantes
                        </Td>
                        <Td bg={evenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.notifynewproducts();
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Notificar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={notEvenRowColor}> Productos cancelados </Td>
                        <Td bg={notEvenRowColor}>
                          Notificación de productos que ya no están disponibles
                          en ningún punto de venta, pertenecientes a las
                          categorías relevantes
                        </Td>
                        <Td bg={notEvenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.notifyCancelledProducts();
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Notificar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={evenRowColor}>Productos con baja cobertura</Td>
                        <Td bg={evenRowColor}>
                          Notificación de productos cuya cobertura ha caído más
                          de un 10% respecto al promedio de los últimos 30 días
                          a nivel nacional, pertenecientes a las categorías
                          relevantes
                        </Td>
                        <Td bg={evenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.notifyLowCoverageProducts();
                            }}
                            m="0px"
                            p={2}
                            bgColor={mainColor}
                            color="white"
                            minW="125px"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Notificar
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td bg={notEvenRowColor}>Fluctuaciones de precios </Td>
                        <Td bg={notEvenRowColor}>
                          Notificación de productos cuyo precio ha variado más
                          de un 3% respecto al promedio de los últimos 30 días a
                          nivel nacional, pertenecientes a las categorías
                          relevantes
                        </Td>
                        <Td bg={notEvenRowColor}>
                          <Button
                            onClick={async () => {
                              await apiHelpers.notifyPriceShiftedProducts();
                            }}
                            m="0px"
                            p={2}
                            minW="125px"
                            bgColor={mainColor}
                            color="white"
                            fontWeight={'500'}
                            minH={'42px'}>
                            Notificar
                          </Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  </>
                </Table>
              </TableContainer>
            </VStack>
          </Flex>
        </Box>
      </VStack>
    </Flex>
  );
};
export default CronProcesses;
