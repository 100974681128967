import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { Button, HStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';

export default function OrderArrows(props) {
  return (
    <HStack>
      <Button
        boxSize={0}
        bg="none"
        color={
          props.selectedOrder[props.element] &&
          props.selectedOrder[props.element] == 'ASC'
            ? 'white'
            : '#AEAEAE'
        }
        onClick={() => {
          if (
            props.selectedOrder[props.element] &&
            props.selectedOrder[props.element] === 'ASC'
          ) {
            props.orderBy(props.element, 'ASC', true);
          } else {
            props.orderBy(props.element, 'ASC', false);
          }
        }}>
        <ArrowDownIcon
          boxSize={5}
          color={
            props.selectedOrder[props.element] &&
            props.selectedOrder[props.element] == 'ASC'
              ? 'white'
              : '#AEAEAE'
          }
        />
      </Button>
      <Button
        boxSize={0}
        bg="none"
        color={
          props.selectedOrder[props.element] &&
          props.selectedOrder[props.element] == 'DESC'
            ? 'white'
            : '#AEAEAE'
        }
        onClick={() => {
          if (
            props.selectedOrder[props.element] &&
            props.selectedOrder[props.element] === 'DESC'
          ) {
            props.orderBy(props.element, 'DESC', true);
          } else {
            props.orderBy(props.element, 'DESC', false);
          }
        }}>
        <ArrowUpIcon
          boxSize={5}
          color={
            props.selectedOrder[props.element] &&
            props.selectedOrder[props.element] == 'DESC'
              ? 'white'
              : '#AEAEAE'
          }
        />
      </Button>
    </HStack>
  );
}
