import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

const FilterModal = ({
  isModalOpen,
  onClose,
  children,
  title,
  filterButtons,
}) => {
  return (
    <Modal isOpen={isModalOpen} size="4xl" onClose={onClose}>
      <ModalOverlay bg="" backdropFilter="blur(0px)" />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>{filterButtons}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FilterModal;
