import {
  Flex,
  VStack,
  Heading,
  Button,
  Spacer,
  Text,
  Divider,
  Tooltip,
  Box,
  InputRightElement,
  Input,
  InputGroup,
  HStack,
  useBreakpointValue,
  Table,
  TableContainer,
  Thead,
  Tr,
  Tbody,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { colors } from '../../theme';
import SelectCategoriesFilter from '../common/SelectCategoriesFIlter';
import { useEffect, useState, useRef, useContext } from 'react';
import SelectDates from '../common/selects/SelectDates';
import SelectBrand from '../common/selects/SelectBrand';
import moment from 'moment';
import {
  CloseIcon,
  DownloadIcon,
  EditIcon,
  RepeatIcon,
} from '@chakra-ui/icons';
import apiHelpers from '../../utils/apiHelpers';
import { Parser } from 'json2csv';
import BranchesContext from '../../contexts/BranchesContext';
import formatter from '../../utils/formatter';
import { CSVLink } from 'react-csv';
import { useAlert } from 'react-alert';
import SelectProductsByEanOrName from '../common/selects/SelectProductsByEanOrName';
// import { productExportLimit } from '../../config/config';
const productExportLimit = 20;
const DinamicReport = () => {
  const alert = useAlert();
  const initialFilters = {
    productEan: '',
    // productName: '',
    productBrand: [],
    // productFormat: [],
    day_from: null,
    day_to: null,
    low_date_from: null,
    low_date_to: null,
    // relevant: null,
    cat_1: null,
    cat_2: null,
    cat_3: null,
    cat_4: null,
    eans: [],
    // coverage: '',
  };
  const [filters, setFilters] = useState(initialFilters);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const download = useRef(null);
  const [datesFilter, setDatesFilter] = useState([null, null]);
  const initialFieldOptions = [
    { label: 'EAN', field: 'productEan' },
    { label: 'Marca', field: 'productBrand' },
    { label: 'Descripción', field: 'productName' },
    { label: 'Formato', field: 'productFormat' },
    { label: 'Categoría 1', field: 'cat_1' },
    { label: 'Categoría 2', field: 'cat_2' },
    { label: 'Categoría 3', field: 'cat_3' },
    { label: 'Categoría 4', field: 'cat_4' },
    { label: 'Fecha', field: 'createdAt' },
    { label: 'Precio', field: 'medPrice' },
    { label: 'ID de sucursal', field: 'branchId' },
    { label: 'Tipo de sucursal', field: 'branchType' },
    { label: 'Cadena de sucursal', field: 'storeBrand' },
    { label: 'Provincia', field: 'province' },
    { label: 'Localidad', field: 'locality' },
    { label: 'Dirección', field: 'address' },
    { label: 'Latitud', field: 'lat' },
    { label: 'Longitud', field: 'lng' },
    { label: 'Denominación Legal', field: 'storeLegalName' },
  ];
  const [fieldOptionsToPick, setFieldOptionsToPick] =
    useState(initialFieldOptions);
  const [pickedFieldOptions, setPickedFieldOptions] = useState([]);
  const [newReportName, setNewReportName] = useState('');
  const [newReportModal, setNewReportModal] = useState(false);
  const [CSVdata, setCSVdata] = useState([]);
  const [createdReports, setCreatedReports] = useState([]);
  const branchContext = useContext(BranchesContext);
  const [pickDatesModal, setPickDatesModal] = useState(false);
  const [exportDates, setExportDates] = useState([null, null]);

  const [CSVExportName, setCSVExportName] = useState('Precios Exportados');
  const [isExporting, setIsExporting] = useState(false);

  const [pickDatesModalOldReport, setPickDatesModalOldReport] = useState(false);
  const [exportDatesOldReport, setExportDatesOldReport] = useState([
    null,
    null,
  ]);
  const [pickedReport, setPickedReport] = useState(null);
  const [reportToUpdate, setReportToUpdate] = useState(null);

  useEffect(() => {
    getCreatedReports();
  }, []);

  useEffect(() => {
    if (CSVdata.length) {
      CSVdata.length && download.current.link.click();
      setCSVdata([]);
    }
  }, [CSVdata]);

  const handleRemoveSelectedProduct = toRemove => {
    setSelectedProducts(
      selectedProducts.filter(sp => {
        return toRemove.value != sp.value;
      }),
    );
  };

  const handleSelectProduct = products => {
    const selectedEans = selectedProducts.map(p => p.value);
    if (selectedEans.includes(products.value)) {
      return;
    }
    setSelectedProducts([...selectedProducts, products]);
  };

  const exportPrices = async (prices, products) => {
    const fields = pickedFieldOptions.map(picked => picked.label);
    let outputPrices = [];
    let branches = branchContext.allBranches;
    const categories = (await apiHelpers.getCategories({ limit: 10000 })).data;

    for (let price of prices) {
      const branch = branches.find(b => price.branchId == b.id);
      const product = products.find(p => p.productEan == price.productEan);

      const cat_1 = categories.find(
        c => c.categoryInternalId == product.cat_1,
      ).categoryName;
      const cat_2 = categories.find(
        c => c.categoryInternalId == product.cat_2,
      ).categoryName;
      const cat_3 = categories.find(
        c => c.categoryInternalId == product.cat_3,
      ).categoryName;
      const cat_4 = categories.find(
        c => c.categoryInternalId == product.cat_4,
      ).categoryName;
      let fullFields = {
        EAN: price.productEan + '\t',
        Marca:
          price.ProductBrand !== 'undefined' ? price.productBrand : 'Sin marca',
        Descripción: price.productName,
        Formato: price.productFormat,
        'Categoría 1': cat_1,
        'Categoría 2': cat_2,
        'Categoría 3': cat_3,
        'Categoría 4': cat_4,
        Fecha: formatter.formatDate(price.createdAt),
        Precio: formatter.formatNumberDecimals(price.medPrice, 2),
        'ID de sucursal': price.branchId,
        'Tipo de sucursal': price.branchType,
        'Cadena de sucursal': price.storeBrand,
        Provincia: price.province,
        Localidad: branch ? branch.locality : '',
        Dirección: branch ? branch.address : '',
        Latitud: branch ? branch.lat : '',
        Longitud: branch ? branch.lng : '',
        'Denominación Legal': branch ? branch.storeLegalName : '',
      };
      let fieldsToExport = {};
      for (let i = 0; i < fields.length; i++) {
        fieldsToExport[fields[i]] = fullFields[fields[i]];
      }
      outputPrices[price.id] = fieldsToExport;
    }
    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputPrices);

    setCSVdata(csv);
  };

  const exportPricesForOldReport = async (prices, report, products) => {
    let allFields = Object.keys(report);
    let prevFields = [];
    for (let i = 0; i < allFields.length; i++) {
      if (report[allFields[i]]) {
        prevFields.push({ field: allFields[i], index: report[allFields[i]] });
      }
    }
    prevFields.sort((a, b) => a.index - b.index);
    const dbFields = prevFields.map(field => field.field);
    let fields = [];
    for (let i = 0; i < dbFields.length; i++) {
      let f = initialFieldOptions.find(iField => iField.field == dbFields[i]);
      if (f) {
        fields.push(f.label);
      }
    }
    let outputPrices = [];
    let branches = branchContext.allBranches;
    const categories = (await apiHelpers.getCategories({ limit: 10000 })).data;

    for (let price of prices) {
      const branch = branches.find(b => price.branchId == b.id);
      const product = products.find(p => p.productEan == price.productEan);

      const cat_1 = categories.find(
        c => c.categoryInternalId == product.cat_1,
      ).categoryName;
      const cat_2 = categories.find(
        c => c.categoryInternalId == product.cat_2,
      ).categoryName;
      const cat_3 = categories.find(
        c => c.categoryInternalId == product.cat_3,
      ).categoryName;
      const cat_4 = categories.find(
        c => c.categoryInternalId == product.cat_4,
      ).categoryName;
      let fullFields = {
        EAN: price.productEan + '\t',
        Marca:
          price.ProductBrand !== 'undefined' ? price.productBrand : 'Sin marca',
        Descripción: price.productName,
        Formato: price.productFormat,
        'Categoría 1': cat_1,
        'Categoría 2': cat_2,
        'Categoría 3': cat_3,
        'Categoría 4': cat_4,
        Fecha: formatter.formatDate(price.createdAt),
        Precio: formatter.formatNumberDecimals(price.medPrice, 2),
        'ID de sucursal': price.branchId,
        'Tipo de sucursal': price.branchType,
        'Cadena de sucursal': price.storeBrand,
        Provincia: price.province,
        Localidad: branch ? branch.locality : '',
        Dirección: branch ? branch.address : '',
        Latitud: branch ? branch.lat : '',
        Longitud: branch ? branch.lng : '',
        'Denominación Legal': branch ? branch.storeLegalName : '',
      };
      let fieldsToExport = {};
      for (let i = 0; i < fields.length; i++) {
        fieldsToExport[fields[i]] = fullFields[fields[i]];
      }
      outputPrices[price.id] = fieldsToExport;
    }
    const opts = { fields, delimiter: ';' };
    const parser = new Parser(opts);
    const csv = parser.parse(outputPrices);

    setCSVdata(csv);
  };

  const getPricesForOldReport = async () => {
    setIsExporting(true);

    let eansResults = pickedReport.eans.split(',');
    const productsResponse = await apiHelpers.getProductsByEans({
      where: { productEan: eansResults },
    });
    let pricesFilters = {
      where: {
        productEan: eansResults,
        day_from:
          exportDatesOldReport && exportDatesOldReport[0]
            ? moment(exportDatesOldReport[0]).toISOString('es-AR')
            : null,
        day_to:
          exportDatesOldReport && exportDatesOldReport[1]
            ? moment(exportDatesOldReport[1]).toISOString('es-AR')
            : null,
      },
      limit: 10000,
    };
    const pricesResponse = await apiHelpers.getPricesReport(pricesFilters);
    exportPricesForOldReport(
      pricesResponse.data,
      pickedReport,
      productsResponse.data,
    );
    setExportDatesOldReport([null, null]);
    setPickDatesModalOldReport(false);
    setIsExporting(false);
  };

  const getCreatedReports = async () => {
    const user = await apiHelpers.getCurrentUser();
    const response = await apiHelpers.getPresetReportForUser(user.data.id);
    setCreatedReports(response.data);
  };

  const handleDelete = async report => {
    const reponse = await apiHelpers.deletePresetReportForUser(report.id);

    setCreatedReports(
      createdReports.filter(oldReport => {
        return report.id != oldReport.id;
      }),
    );
  };

  const storePreSetReport = async () => {
    let presetReportFields = {};
    for (let i = 0; i < pickedFieldOptions.length; i++) {
      presetReportFields[pickedFieldOptions[i].field] = i + 1;
    }
    presetReportFields.eans = selectedProducts.map(p => p.value).toString();
    const user = await apiHelpers.getCurrentUser();
    const response = await apiHelpers.postPresetReport({
      user: user.data,
      presetReportFields,
      name: newReportName,
    });
    setFieldOptionsToPick(initialFieldOptions);
    setPickedFieldOptions([]);
    setNewReportModal(false);
    setNewReportName('');
    setCreatedReports([...createdReports, response.data]);
  };

  const updatePreSetReport = async name => {
    let presetReportFields = {};
    for (let i = 0; i < pickedFieldOptions.length; i++) {
      presetReportFields[pickedFieldOptions[i].field] = i + 1;
    }
    presetReportFields.eans = selectedProducts.map(p => p.value).toString();
    const user = await apiHelpers.getCurrentUser();
    const response = await apiHelpers.postPresetReport({
      user: user.data,
      presetReportFields,
      name: name,
    });
    setFieldOptionsToPick(initialFieldOptions);
    setPickedFieldOptions([]);
    setNewReportModal(false);
    setNewReportName('');
    setCreatedReports([...createdReports, response.data]);
  };

  const handleChangeReportName = event => {
    setNewReportName(event.target.value);
  };

  const getPrices = async () => {
    setIsExporting(true);
    alert.info('Obteniendo precios');
    let eansResults = selectedProducts.map(p => p.value);
    const productsResponse = await apiHelpers.getProductsByEans({
      where: { productEan: eansResults },
    });

    let pricesFilters = {
      where: {
        productEan: eansResults,
        day_from:
          exportDates && exportDates[0]
            ? moment(exportDates[0]).toISOString('es-AR')
            : null,
        day_to:
          exportDates && exportDates[1]
            ? moment(exportDates[1]).toISOString('es-AR')
            : null,
      },
      limit: 10000,
    };
    const pricesResponse = await apiHelpers.getPricesReport(pricesFilters);
    await exportPrices(pricesResponse.data, productsResponse.data);
    setExportDates([null, null]);
    setPickDatesModal(false);
    setIsExporting(false);
    alert.success('Descarga completada');
  };

  const handleOnPickField = pickedField => {
    pickedField.index = pickedFieldOptions.length + 1;
    setPickedFieldOptions([...pickedFieldOptions, pickedField]);
    setFieldOptionsToPick(
      fieldOptionsToPick.filter(field => field.field != pickedField.field),
    );
  };

  const handleRemoveField = removedField => {
    const filteredFields = pickedFieldOptions.filter(
      field => field.field != removedField.field,
    );
    for (let i = 0; i < filteredFields.length; i++) {
      filteredFields[i].index = i + 1;
    }
    setPickedFieldOptions(filteredFields);
    setFieldOptionsToPick([...fieldOptionsToPick, removedField]);
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFilters({ ...filters, [name]: value });
  };
  return (
    <Flex w="100%" justify="center" pb={5}>
      <CSVLink
        ref={download}
        data={CSVdata}
        filename={CSVExportName}
        className="hidden"></CSVLink>
      <VStack w={'100%'} gap={5}>
        <Flex
          bg={colors.generic['admin-headers']}
          w="100%"
          mt={4}
          direction={'column'}
          minH={'100px'}>
          <Flex
            bg={colors.generic['admin-headers']}
            w="100%"
            color={'white'}
            minH={'50px'}
            pt={8}
            justify="space-around"
            align="center"
            pb={0}
            direction={{ base: 'column', md: 'row' }}>
            <Heading>Reporte dinámico</Heading>
            <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
              <Button variant="primary-button" visibility={'hidden'}>
                Agregar Usuario
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex w={'100%'} px={5}>
          <Flex bg={colors.generic['even-row']} w={'100%'}>
            <Flex
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              alignContent={'center'}
              minW={'1037px'}
              w={'100%'}
              gap={3}
              py={3}>
              <Text fontSize={'24px'} color={'white'}>
                Seleccione los productos a incluir, por EAN o descripción{' '}
                {` (Máximo ${productExportLimit})`}
              </Text>
              <Flex w={'100%'} direction={'row'} justifyContent={'center'}>
                <VStack>
                  <Tooltip
                    label={
                      productExportLimit <= selectedProducts.length
                        ? `Alcanzo el limite de productos exportables (${productExportLimit})`
                        : 'Filtrar por eans'
                    }
                    placement="top"
                    arrowSize={5}
                    hasArrow>
                    <Box mb={1} mx={1}>
                      <Box
                        layerStyle={'input-box'}
                        bg={'white'}
                        placeholder="Marca"
                        _hover={{
                          cursor:
                            productExportLimit <= selectedProducts.length
                              ? 'no-drop'
                              : 'auto',
                        }}
                        w={[778, 778, 778, 778, 778, 778]}
                        id="eans">
                        <SelectProductsByEanOrName
                          handleSelect={handleSelectProduct}
                          selectedProducts={selectedProducts}
                          filters={filters}
                          name={'eans'}
                          id="eans"
                          value={filters.eans}
                          isDisabled={
                            productExportLimit <= selectedProducts.length
                          }
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                  </Tooltip>
                  {selectedProducts.length == 0 ? (
                    <Text fontSize={'15px'} color={'white'}>
                      No se han seleccionado productos
                    </Text>
                  ) : (
                    <Box></Box>
                  )}
                </VStack>
                <Button
                  bg={'#b8e8e8'}
                  variant="reset-filters-button"
                  _hover={{
                    bg: colors.primary[200],
                  }}
                  w={'190px'}
                  onClick={() => {
                    setFilters(initialFilters);
                    setSelectedProducts([]);
                  }}>
                  <Text mr={2}>Borrar selección</Text>
                  <RepeatIcon />
                </Button>
              </Flex>
              <Flex
                mb={3}
                wrap={'wrap'}
                minW={'800px'}
                gap={2}
                w={'100%'}
                px={'20px'}>
                {selectedProducts.length > 0 ? (
                  selectedProducts.map(pickedProduct => {
                    return (
                      <Box
                        layerStyle={'field-to-pick'}
                        color={'black'}
                        _hover={{
                          fontSize: '14px',
                          bg: 'primary.300',
                          cursor: 'pointer',
                          color: 'red',
                        }}
                        onClick={() => {
                          handleRemoveSelectedProduct(pickedProduct);
                        }}>
                        <HStack>
                          <Text p={'2px'}>{`${pickedProduct.label} `}</Text>
                          <CloseIcon fontSize={'10px'}></CloseIcon>
                        </HStack>
                      </Box>
                    );
                  })
                ) : (
                  <Box></Box>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap={2} w={'100%'}>
          <Flex
            minW="300px"
            bg={colors.generic['even-row']}
            flex={3}
            px={5}
            pt={4}
            direction={'column'}
            ml={5}>
            <Text fontSize={'30px'} fontWeight={'600'} pl={5}>
              Seleccione los campos a incluir
            </Text>
            <Flex wrap={'wrap'} gap={2} px={'20px'} py={'30px'}>
              {fieldOptionsToPick.map(field => {
                return (
                  <Box
                    layerStyle={'field-to-pick'}
                    color={'#c7cbd1'}
                    onClick={() => {
                      handleOnPickField(field);
                    }}>
                    {field.label}
                  </Box>
                );
              })}
            </Flex>
          </Flex>

          <VStack
            minW={
              pickedFieldOptions.length > 5
                ? pickedFieldOptions.length > 9
                  ? pickedFieldOptions.length > 11
                    ? '1000'
                    : '800'
                  : '600px'
                : '400px'
            }
            bg={colors.generic['even-row']}
            flex={1}
            px={'30px'}
            pt={'10px'}
            mr={5}>
            <Text fontSize={'30px'} fontWeight={'600'}>
              Campos seleccionados{' '}
              {reportToUpdate && `(${reportToUpdate.name})`}
            </Text>
            <Flex
              px={'20px'}
              py={'30px'}
              direction={'row'}
              gap={2}
              wrap={'wrap'}
              justifyContent={'center'}>
              {pickedFieldOptions.map(field => {
                return (
                  <Box
                    layerStyle={'picked-field'}
                    bg={'#7dcecc'}
                    w={'215px'}
                    fontWeight={'600'}
                    color={'#405f69'}
                    onClick={() => handleRemoveField(field)}>
                    <Flex justifyContent={'center'}>
                      <Text>{`${field.index} - ${field.label}`}</Text>
                      <CloseIcon
                        fontSize={'16px'}
                        pt={'5px'}
                        pl={'7px'}></CloseIcon>
                    </Flex>
                  </Box>
                );
              })}
            </Flex>
            <Box pb={3}>
              {pickedFieldOptions.length ? (
                <Button
                  variant="primary-button"
                  w={'210px'}
                  h={'35px'}
                  onClick={() => {
                    setPickedFieldOptions([]);
                    setFieldOptionsToPick(initialFieldOptions);
                    setReportToUpdate(null);
                  }}>
                  Descartar campos <i className="fas fa-trash pl-2"></i>
                </Button>
              ) : (
                <Text fontSize={'18px'} color={'white'}>
                  No se han seleccionado campos
                </Text>
              )}
            </Box>
          </VStack>
        </Flex>
        <Flex w={'100%'} px={5}>
          <Flex
            bg={colors.generic['even-row']}
            w="100%"
            mt={4}
            direction={'row'}
            minH={'100px'}
            justifyContent={'space-evenly'}
            alignItems={'center'}>
            <Button
              variant="primary-button"
              onClick={async () => {
                if (selectedProducts.length == 0) {
                  return alert.error(
                    'Debe seleccionar almenos 1 (un) producto',
                  );
                } else if (pickedFieldOptions.length == 0) {
                  return alert.error(
                    'Debe seleccionar almenos 1 (una) columna para exportar',
                  );
                }
                if (reportToUpdate) {
                  await handleDelete(reportToUpdate);
                  await updatePreSetReport(reportToUpdate.name);
                  setTimeout(() => {
                    getCreatedReports();
                  });
                  setReportToUpdate(null);
                  setSelectedProducts([]);
                } else {
                  setNewReportModal(true);
                }
              }}>
              {reportToUpdate ? (
                <Text>
                  Actualizar reporte <i className="fas fa-edit"></i>
                </Text>
              ) : (
                <Text>
                  Guardar selección <i className="fas fa-save pl-1"></i>
                </Text>
              )}
            </Button>
            <Button
              variant="primary-button"
              onClick={() => {
                if (selectedProducts.length == 0) {
                  alert.error('Debe seleccionar almenos 1 (un) producto');
                } else if (pickedFieldOptions.length == 0) {
                  alert.error(
                    'Debe seleccionar almenos 1 (una) columna para exportar',
                  );
                } else {
                  setPickDatesModal(true);
                }
              }}>
              Exportar selección <i className="fas fa-file-download pl-2"></i>
            </Button>
            <Button
              variant="primary-button"
              onClick={() => {
                setPickedFieldOptions([]);
                setFieldOptionsToPick(initialFieldOptions);
                setSelectedProducts([]);
                setReportToUpdate(null);
              }}>
              Descartar todos los cambios <i className="fas fa-trash pl-2"></i>
            </Button>
          </Flex>
        </Flex>
        <TableContainer
          className="my-third-step"
          // overflowX={isMobile ? 'scroll' : 'auto'}
          whiteSpace={'normal'}
          w={'100%'}
          px={5}>
          <Table
            variant="simple"
            size={useBreakpointValue({ md: 'md', base: 'sm' })}>
            <>
              <Thead bgColor={colors.generic['not-even-row']}>
                <Tr>
                  <Td>
                    <Text
                      fontSize={'30px'}
                      fontWeight={'600'}
                      pt={4}
                      pl={'18px'}>
                      Reportes guardados
                    </Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                {createdReports.length
                  ? createdReports.map(report => {
                      return (
                        <Tr>
                          <Td bgColor={colors.generic['even-row']}>
                            <HStack>
                              <Text fontSize={'15px'} fontWeight={600}>
                                {report.name}
                              </Text>
                              <Button
                                border={'solid 2px #b2b4b8'}
                                p={0}
                                onClick={async () => {
                                  setFilters({
                                    ...filters,
                                    eans: report.eans.split(','),
                                  });
                                  const response =
                                    await apiHelpers.getProductsByEans({
                                      where: {
                                        productEan: report.eans,
                                      },
                                    });
                                  setSelectedProducts(
                                    response.data.map(item => {
                                      return {
                                        value: item.productEan,
                                        label: `${item.productName} - (${item.productEan})`,
                                      };
                                    }),
                                  );
                                  const dbReport = Object.keys(report);
                                  let csvFields = dbReport.filter(field => {
                                    return (
                                      initialFieldOptions.find(ifo => {
                                        return ifo.field == field;
                                      }) && report[field]
                                    );
                                  });
                                  let pickedFields = [];
                                  for (let i = 0; i < csvFields.length; i++) {
                                    let pick = initialFieldOptions.find(ifo => {
                                      return csvFields[i] == ifo.field;
                                    });
                                    pick.order = report[csvFields[i]];
                                    pickedFields.push(pick);
                                  }
                                  pickedFields.sort(
                                    (a, b) => a.order - b.order,
                                  );

                                  for (
                                    let i = 0;
                                    i < pickedFields.length;
                                    i++
                                  ) {
                                    pickedFields[i].index = i + 1;
                                  }

                                  setPickedFieldOptions(pickedFields);
                                  setFieldOptionsToPick(
                                    initialFieldOptions.filter(ifo => {
                                      return !pickedFields.find(
                                        p => p.field == ifo.field,
                                      );
                                    }),
                                  );
                                  setReportToUpdate(report);
                                }}>
                                <EditIcon></EditIcon>
                              </Button>
                              <Button
                                border={'solid 2px green'}
                                p={0}
                                bg={'#edf2f7'}
                                onClick={() => {
                                  setPickDatesModalOldReport(true);
                                  setPickedReport(report);
                                }}>
                                <DownloadIcon color={'green'} />
                              </Button>
                              <Button
                                bg={' #edf2f7'}
                                p={0}
                                border={'solid 2px red'}
                                onClick={() => {
                                  handleDelete(report);
                                }}>
                                <i
                                  className="fas fa-trash"
                                  style={{ color: 'red' }}></i>
                              </Button>
                            </HStack>
                          </Td>
                        </Tr>
                      );
                    })
                  : ''}
                {createdReports.length == 0 && (
                  <Tr>
                    <Td bgColor={colors.generic['even-row']}>
                      <Text fontSize={'18px'} fontWeight={600}>
                        No se han creado reportes
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </>
          </Table>
        </TableContainer>
      </VStack>
      <Modal
        isOpen={newReportModal}
        onClose={() => {
          setNewReportModal(false);
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Guardar nuevo reporte preseteado</ModalHeader>
          <ModalBody>
            <Input
              variant="primary-input"
              placeholder="Nombre del reporte"
              onChange={handleChangeReportName}
              value={newReportName}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary-button"
              w={'150px'}
              h={'35px'}
              onClick={() => {
                let repeatName = createdReports.find(
                  cr => cr.name == newReportName,
                );
                if (repeatName) {
                  alert.error('El nombre elegido ya se encuentra en uso');
                  return;
                }
                storePreSetReport();
              }}>
              Guardar
            </Button>
            <Button
              bg={'red'}
              w={'150px'}
              h={'35px'}
              color={'white'}
              onClick={() => {
                setNewReportModal(false);
                setNewReportName('');
              }}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={pickDatesModal}
        onClose={() => {
          setPickDatesModal(false);
        }}
        closeOnOverlayClick={!isExporting}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Seleccione las fechas de los precios a exportar
            {` (Máximo ${Math.floor(
              productExportLimit / selectedProducts.length,
            )})`}
          </ModalHeader>
          <ModalBody>
            {isExporting ? (
              <Center>
                <Spinner size="xl" color={colors.generic[1]}></Spinner>
              </Center>
            ) : (
              <Box layerStyle={'input-box'} id="datesFilter">
                <SelectDates
                  placeholder="Fecha inicial - Fecha final"
                  value={exportDates}
                  rangeLimit={Math.floor(
                    productExportLimit / selectedProducts.length,
                  )}
                  onChange={setExportDates}
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary-button"
              w={'150px'}
              h={'35px'}
              onClick={getPrices}
              // disabled={isExporting}
              isLoading={isExporting}
              _loading={{
                bg: colors.primary[100],
                color: colors.primary[600],
              }}>
              Exportar
            </Button>
            <Button
              bg={'red'}
              w={'150px'}
              h={'35px'}
              color={'white'}
              onClick={() => {
                setPickDatesModal(false);
                setExportDates([null, null]);
              }}
              disabled={isExporting}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={pickDatesModalOldReport}
        onClose={() => {
          setPickDatesModalOldReport(false);
        }}
        closeOnOverlayClick={!isExporting}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Seleccione las fechas de los precios a exportar{' '}
            {pickedReport
              ? ` (Máximo: ${Math.floor(
                  productExportLimit / pickedReport.eans.split(',').length,
                )})`
              : ''}
          </ModalHeader>
          <ModalBody>
            {isExporting ? (
              <Center>
                <Spinner size="xl" color={colors.generic[1]}></Spinner>
              </Center>
            ) : (
              <Box layerStyle={'input-box'} id="datesFilter">
                <SelectDates
                  placeholder="Fecha inicial - Fecha final"
                  rangeLimit={
                    pickedReport
                      ? Math.floor(
                          productExportLimit /
                            pickedReport.eans.split(',').length,
                        )
                      : null
                  }
                  value={exportDatesOldReport}
                  onChange={setExportDatesOldReport}
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary-button"
              w={'150px'}
              h={'35px'}
              onClick={getPricesForOldReport}
              // disabled={isExporting}
              isLoading={isExporting}
              _loading={{
                bg: colors.primary[100],
                color: colors.primary[600],
              }}>
              Exportar
            </Button>
            <Button
              bg={'red'}
              w={'150px'}
              h={'35px'}
              color={'white'}
              onClick={() => {
                setPickDatesModalOldReport(false);
                setExportDatesOldReport([null, null]);
              }}
              disabled={isExporting}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default DinamicReport;
