import React, { useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import {
  Box,
  Flex,
  Button,
  FormControl,
  Link,
  Input,
  Text,
  useColorModeValue,
  Image,
  Center,
  Stack,
  Spacer,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const RecoverPassword = props => {
  let navigate = useNavigate();
  const textColor = useColorModeValue('#000', 'white');
  const color = useColorModeValue('black', 'white');
  const boxBg = useColorModeValue('white', '#1E2626');
  const [state, setState] = useState({
    email: '',
    errorMessage: null,
  });

  const handleChange = event => {
    const target = event.target;
    const value = formatter.formatFromTarget(target);
    const name = target.name;

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      await apiHelper.resetPassword(state.email);
      navigate('/login');
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 401) {
        errorMessage = 'Email o contraseña invalidas';
      } else {
        errorMessage = error.message;
      }

      setState({
        errorMessage: errorMessage,
      });
    }
  };

  return (
    <Box
      bgColor={boxBg}
      m={useBreakpointValue({ base: 3, md: 9 })}
      boxShadow={useColorModeValue('dark-lg', '0 8px 22px #5B7D76')}
      borderRadius="lg"
      w={{ base: '100%', md: '400px' }}>
      <Flex alignItems="center" justifyContent="center" w={'100%'}>
        <Stack direction="column" py={6}>
          <Box mb={6}>
            <VStack>
              <Center>
                <Image
                  src={useColorModeValue(
                    process.env.PUBLIC_URL + '/img/login_logo_light.png',
                    process.env.PUBLIC_URL + '/img/login_logo_light.png',
                  )}
                  width="150px"
                  alt="logo"
                />
              </Center>
            </VStack>
          </Box>
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Text px={6} fontFamily={'Kanit,sans-serif'} fontSize={26}>
              ¿Olvidaste tu contraseña?
            </Text>
            <Text px={6}>Ingresa tu direccion de email para recuperarla</Text>
          </Stack>

          <FormControl px={6}>
            <VStack spacing={2}>
              <Input
                placeholder="Dirección de Email"
                variant="primary-input"
                name="email"
                type="email"
                value={state.email}
                onChange={handleChange}></Input>
              <Spacer />
              <Button
                fontSize="1rem"
                type="submit"
                variant="primary-button"
                onClick={handleSubmit}>
                Recuperar contraseña
              </Button>
            </VStack>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            m={8}>
            <Text color={textColor} fontWeight="medium">
              <Link
                href="/login"
                ms="5px"
                fontWeight="700"
                color="generic.1"
                _hover={{ color: 'generic.1', fontWeight: 500 }}>
                Volver al Login
              </Link>
            </Text>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  );
};
export default RecoverPassword;
