import React from 'react';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { Box, SimpleGrid, Tooltip, useColorModeValue } from '@chakra-ui/react';
import apiHelper from '../../utils/apiHelpers';

const SelectCategoriesFilter = props => {
  const [value, setValue] = useState(null);
  const color = 'black';
  const colorBg = 'white';
  const colorHover = '#ddfcfc';
  const colorHoverOption = '#99e0e2';
  const [categories, setCategories] = useState([]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [options4, setOptions4] = useState([]);
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  const [value4, setValue4] = useState(null);

  const mainColor = useColorModeValue('#E31D1C', 'black');

  useEffect(() => {
    defaultOptions();
  }, []);

  useEffect(() => {
    if (
      !props.state.cat_1 &&
      !props.state.cat_2 &&
      !props.state.cat_3 &&
      !props.state.cat_4
    ) {
      setValue1(null);
      setValue4(null);
      setValue2(null);
      setValue3(null);
    }
  }, [props.state]);

  const handleChange = (selectedValue, level) => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = level;
    event.target.value = selectedValue ? selectedValue.map(r => r.value) : [];
    event.target.type = 'react-select';

    if (level == 'cat_1') {
      selectedValue ? setValue1(selectedValue) : setValue1(null);
    } else {
      if (level == 'cat_2') {
        selectedValue ? setValue2(selectedValue) : setValue2(null);
      } else {
        if (level == 'cat_3') {
          selectedValue ? setValue3(selectedValue) : setValue3(null);
        } else {
          selectedValue ? setValue4(selectedValue) : setValue4(null);
        }
      }
    }

    props.onChange(event);
    defaultOptions();
  };

  const defaultOptions = async () => {
    apiHelper
      .getRelevantAndRelatedCategories()
      .then(response => {
        setCategories(response.data);

        let entitiesForSelectAll = response.data.map(cat => {
          cat.map(entity => {
            return {
              value: entity.categoryInternalId,
              label: entity.categoryName,
            };
          });
        });

        let entitiesForSelect1 = response.data[0].map(entity => {
          return {
            value: entity.categoryInternalId,
            label: entity.categoryName,
          };
        });

        let entitiesForSelect2 = response.data[1].map(entity => {
          return {
            value: entity.categoryInternalId,
            label: entity.categoryName,
          };
        });

        let entitiesForSelect3 = response.data[2].map(entity => {
          return {
            value: entity.categoryInternalId,
            label: entity.categoryName,
          };
        });

        let entitiesForSelect4 = response.data[3].map(entity => {
          return {
            value: entity.categoryInternalId,
            label: entity.categoryName,
          };
        });
        setOptions1(entitiesForSelect1);
        setOptions2(entitiesForSelect2);
        setOptions3(entitiesForSelect3);
        setOptions4(entitiesForSelect4);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadOptions_1 = (input, callback) => {
    if (!input.length) return;
    let words = input.split(' ');

    let entitiesForSelect1 = categories[0].map(entity => {
      return {
        value: entity.categoryInternalId,
        label: entity.categoryName,
      };
    });

    words.map(word => {
      entitiesForSelect1 = entitiesForSelect1.filter(cat_1 =>
        cat_1.label.toUpperCase().includes(word.toUpperCase()),
      );
    });

    callback(entitiesForSelect1);
    setOptions1(entitiesForSelect1);
  };

  const loadOptions_2 = (input, callback) => {
    if (!input.length) return;
    let words = input.split(' ');
    let entitiesForSelect2 = categories[1].map(entity => {
      return {
        value: entity.categoryInternalId,
        label: entity.categoryName,
      };
    });
    words.map(word => {
      entitiesForSelect2 = entitiesForSelect2.filter(cat_2 =>
        cat_2.label.toUpperCase().includes(word.toUpperCase()),
      );
    });
    callback(entitiesForSelect2);
    setOptions2(entitiesForSelect2);
  };

  const loadOptions_3 = (input, callback) => {
    if (!input.length) return;
    let words = input.split(' ');
    let entitiesForSelect3 = categories[2].map(entity => {
      return {
        value: entity.categoryInternalId,
        label: entity.categoryName,
      };
    });

    words.map(word => {
      entitiesForSelect3 = entitiesForSelect3.filter(cat_3 =>
        cat_3.label.toUpperCase().includes(word.toUpperCase()),
      );
    });
    callback(entitiesForSelect3);
    setOptions3(entitiesForSelect3);
  };

  const loadOptions_4 = (input, callback) => {
    if (!input.length) return;
    let words = input.split(' ');
    let entitiesForSelect4 = categories[3].map(entity => {
      return {
        value: entity.categoryInternalId,
        label: entity.categoryName,
      };
    });

    words.map(word => {
      entitiesForSelect4 = entitiesForSelect4.filter(cat_4 =>
        cat_4.label.toUpperCase().includes(word.toUpperCase()),
      );
    });
    callback(entitiesForSelect4);
    setOptions4(entitiesForSelect4);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '0px solid black',
      borderColor: 'white',
      backgroundColor: 'none',
      outline: 'none',
      '&:hover': { backgroundColor: colorHover },
    }),
    placeholder: () => ({
      color: color,
      fontSize: '16px',
      marginLeft: '8px',
      '&:hover': { fontSize: '15px' },
    }),

    singleValue: () => ({
      color: color,
    }),

    menuList: provide => ({
      ...provide,
      color: color,
      backgroundColor: colorBg,
    }),
    option: (provider, state) => ({
      ...provider,
      '&:hover': { backgroundColor: colorHoverOption },
      backgroundColor: state.isFocused ? colorHoverOption : 'none',
      color: color,
    }),
  };

  let components = props.isDisabled && {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  return (
    <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 4 }} rows={{ lg: 3 }}>
      <Tooltip
        label="Filtrar por categoria 1"
        placement="top"
        arrowSize={5}
        hasArrow>
        <Box mb={1} mx={1}>
          <Box layerStyle={'input-box'} bgColor={props.bgColor}>
            <AsyncSelect
              styles={customStyles}
              onChange={e => handleChange(e, 'cat_1')}
              defaultOptions={options1}
              loadOptions={loadOptions_1}
              value={value1}
              isMulti={true}
              required={props.required}
              components={components}
              isDisabled={props.isDisabled}
              placeholder={'Categoria 1'}
            />
          </Box>
        </Box>
      </Tooltip>
      <Tooltip
        label="Filtrar por categoria 2"
        placement="top"
        arrowSize={5}
        hasArrow>
        <Box mb={1} mx={1}>
          <Box layerStyle={'input-box'} bgColor={props.bgColor}>
            <AsyncSelect
              styles={customStyles}
              onChange={e => handleChange(e, 'cat_2')}
              defaultOptions={options2}
              loadOptions={loadOptions_2}
              value={value2}
              isMulti={true}
              name={'cat_2'}
              required={props.required}
              components={components}
              isDisabled={props.isDisabled}
              placeholder={'Categoria 2'}
            />
          </Box>
        </Box>
      </Tooltip>
      <Tooltip
        label="Filtrar por categoria 3"
        placement="top"
        arrowSize={5}
        hasArrow>
        <Box mb={1} mx={1}>
          <Box layerStyle={'input-box'} bgColor={props.bgColor}>
            <AsyncSelect
              styles={customStyles}
              onChange={e => handleChange(e, 'cat_3')}
              defaultOptions={options3}
              loadOptions={loadOptions_3}
              value={value3}
              isMulti={true}
              name={'cat_3'}
              required={props.required}
              components={components}
              isDisabled={props.isDisabled}
              placeholder={'Categoria 3'}
            />
          </Box>
        </Box>
      </Tooltip>
      <Tooltip
        label="Filtrar por categoria 4"
        placement="top"
        arrowSize={5}
        hasArrow>
        <Box mb={1} mx={1}>
          <Box layerStyle={'input-box'} bgColor={props.bgColor}>
            <AsyncSelect
              styles={customStyles}
              onChange={e => handleChange(e, 'cat_4')}
              defaultOptions={options4}
              loadOptions={loadOptions_4}
              value={value4}
              isMulti={true}
              name={'cat_4'}
              required={props.required}
              components={components}
              isDisabled={props.isDisabled}
              placeholder={'Categoria 4'}
            />
          </Box>
        </Box>
      </Tooltip>
    </SimpleGrid>
  );
};

export default SelectCategoriesFilter;
