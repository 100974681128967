import {
  Box,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  VStack,
  Center,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import Footer from './Footer';
import NavBar from './NavBar';

export default function PublicLayout({ children }) {
  return (
    <Box as="section" minH="100vh">
      <NavBar links={[]} publicLayout={true} />
      <Flex
        spacing={2}
        h="full"
        w="full"
        bgColor={useColorModeValue('#ffffff', '#404040d2')}
        direction={'column'}
        minH="86vh">
        <Center>
          <VStack>{children}</VStack>
        </Center>
      </Flex>
      <Spacer />
      <Footer />
    </Box>
  );
}
