import {
  ChevronUpIcon,
  AddIcon,
  QuestionIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';
import {
  Box,
  useBreakpointValue,
  Flex,
  VStack,
  Button,
  Heading,
  Text,
  TableContainer,
  Table,
  Td,
  Tbody,
  Tr,
  Input,
  InputGroup,
  HStack,
  Switch,
  InputRightAddon,
  Skeleton,
  CircularProgress,
} from '@chakra-ui/react';
import { colors } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import apiHelpers from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';

const GeneralPanel = () => {
  const [user, setUser] = useState(null);
  const [panel, setPanel] = useState(null);
  const [panelLoading, setPanelLoading] = useState(true);
  const [sistemLoading, setSistemLoading] = useState(true);
  const [sistemStatus, setSistemStatus] = useState(null);
  const [lowCoverageLoading, setLowCoverageLoading] = useState(true);
  const [lowCoverage, setLowCoverage] = useState(null);
  const [categoriesCoverage, setCategoriesCoverage] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getUser();
    getPanelData();
    getSistemStatus();
    getLowCoverageData();
  }, []);
  const getUser = async () => {
    const userResponse = await apiHelpers.getCurrentUser();
    setUser(userResponse.data);
  };
  const getPanelData = async () => {
    setPanelLoading(true);
    const panelResponse = await apiHelpers.getGeneralPanelData();
    setPanel(panelResponse.data);
    setPanelLoading(false);
  };
  const getSistemStatus = async () => {
    setSistemLoading(true);
    const sistemResponse = await apiHelpers.getGeneralPanelSistemStatus();
    setSistemStatus(sistemResponse.data);
    setSistemLoading(false);
  };
  const getLowCoverageData = async () => {
    setLowCoverageLoading(true);
    const lowCoverageResponse = await apiHelpers.getGeneralPanelLowCoverage();
    setLowCoverage(lowCoverageResponse.data);
    setLowCoverageLoading(false);
  };
  return (
    <Flex w="100%" justify="center">
      <VStack w="100%">
        <Flex
          my={1}
          mt={4}
          ml={2}
          bg={colors.generic['user-label']}
          w="100%"
          color={'white'}
          justify="space-between"
          align="center"
          py={'10px'}
          px={'70px'}
          direction={{ base: 'column', md: 'row' }}>
          <Heading>
            <HStack>
              {user && (
                <Box
                  bg={'white'}
                  color={'black'}
                  fontSize={'21px'}
                  fontWeight={300}
                  borderRadius={'50px'}
                  w={'50px'}
                  h={'50px'}
                  pt={'10px'}
                  pl={'13px'}
                  border={'1px'}
                  borderColor={'black'}
                  position={'relative'}
                  boxShadow={'0px 4px 3px -1px rgba(0,0,0,0.75)'}>
                  {`${user?.firstName[0]}${user.lastName[0]}`}
                  <div
                    style={{
                      position: 'absolute',
                      backgroundColor: 'green',
                      width: '18px',
                      height: '18px',
                      borderRadius: '9px',
                      border: '4px black',
                      left: '35px',
                      bottom: '0px',
                    }}></div>
                </Box>
              )}
              <Text>{`¡Hola ${
                user ? user.firstName : '...'
              }! Bienvenido`}</Text>
            </HStack>
          </Heading>
          <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
            <Button
              visibility={'hidden'}
              variant="primary-button"
              onClick={() => {
                navigate('/users/new');
              }}
              w={240}>
              <Text mr={4}>Crear Usuario</Text>
              <AddIcon mb={1} />
            </Button>
          </Flex>
        </Flex>
        <Flex
          my={4}
          bg={colors.generic['admin-headers']}
          w="100%"
          color={'white'}
          minH={'100px'}
          py={4}
          justify="space-between"
          align="center"
          px={'70px'}
          direction={{ base: 'column', md: 'row' }}>
          <Flex direction={'column'} alignItems={'flex-start'} ml={2}>
            <Heading mb={0} pb={0}>
              Panel general
            </Heading>
            <Text mt={0} pt={0} fontWeight={500} fontSize={'18px'}>
              {!panelLoading ? (
                `Último relevamiento realizado: ${
                  panel ? formatter.formatDate(panel?.lastCrawl.createdAt) : ''
                }`
              ) : (
                <Skeleton h={'20px'} w={'300px'}></Skeleton>
              )}
            </Text>
          </Flex>
          <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
            <Button
              visibility={'hidden'}
              variant="primary-button"
              onClick={() => {
                navigate('/users/new');
              }}
              w={240}>
              <Text mr={4}>Crear Usuario</Text>
              <AddIcon mb={1} />
            </Button>
          </Flex>
        </Flex>

        <TableContainer
          className="my-third-step"
          //   overflowX={isMobile ? 'scroll' : 'auto'}
          whiteSpace={'normal'}
          maxW={{ md: 'full', lg: '1200px', xl: '1200px' }}
          minW={{ md: 'full', lg: '1200px', xl: '1200px' }}>
          <Table
            variant="simple"
            size={useBreakpointValue({ md: 'md', base: 'sm' })}
            mb={4}
            mt={5}>
            <Tbody>
              <Tr>
                <Td bg={colors.generic['not-even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i className="fas fa-store"></i> Cantidad de puntos de venta
                    relevados por categoría.{' '}
                    <Button
                      onClick={() => {
                        if (categoriesCoverage) {
                          setCategoriesCoverage(false);
                        } else {
                          setCategoriesCoverage(true);
                        }
                      }}>
                      <ChevronDownIcon></ChevronDownIcon>
                    </Button>
                  </Text>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  {!panelLoading ? (
                    <Input
                      variant="secondary-input"
                      value={panel?.totalCoverageByCategories}
                      fontSize={'24px'}
                      fontWeight={500}
                      textAlign={'center'}
                      disabled
                      _disabled={{ bg: 'white' }}
                    />
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              {categoriesCoverage && !panelLoading && (
                <>
                  {panel.coverageByCategories.map((item, i) => {
                    return (
                      <Tr>
                        <Td
                          bg={
                            i % 2
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          <Text fontSize={'24px'} fontWeight={500} ml={20}>
                            {item.category}
                          </Text>
                        </Td>
                        <Td
                          bg={
                            i % 2
                              ? colors.generic['not-even-row']
                              : colors.generic['even-row']
                          }>
                          <Input
                            variant="secondary-input"
                            value={item.cant}
                            fontSize={'24px'}
                            fontWeight={500}
                            textAlign={'center'}
                            disabled
                            _disabled={{ bg: 'white' }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
              <Tr>
                <Td bg={colors.generic['even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="far fa-check-circle"></i> Cantidad total de puntos
                    de venta relevados.
                  </Text>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  {!panelLoading ? (
                    <Input
                      variant="secondary-input"
                      value={panel?.totalCoverage.length}
                      fontSize={'24px'}
                      fontWeight={500}
                      textAlign={'center'}
                      disabled
                      _disabled={{ bg: 'white' }}
                    />
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['not-even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="fas fa-map-marker-alt"></i> Cantidad de provincias
                    relevadas
                  </Text>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  {!panelLoading ? (
                    <Input
                      variant="secondary-input"
                      fontSize={'24px'}
                      fontWeight={500}
                      value={`${panel?.coveredProvinces}/24`}
                      textAlign={'center'}
                      disabled
                      _disabled={{ bg: 'white' }}
                    />
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="fas fa-arrow-up"></i> Altas de productos
                    detectadas.
                  </Text>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  {!panelLoading ? (
                    <Input
                      variant="secondary-input"
                      value={panel?.newProducts?.length}
                      textAlign={'center'}
                      fontSize={'24px'}
                      fontWeight={500}
                      disabled
                      _disabled={{ bg: 'white' }}
                    />
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['not-even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="fas fa-arrow-down"></i> Bajas de productos
                    detectadas.
                  </Text>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  {!panelLoading ? (
                    <Input
                      variant="secondary-input"
                      value={panel?.removedProducts?.length}
                      textAlign={'center'}
                      fontSize={'24px'}
                      fontWeight={500}
                      disabled
                      _disabled={{ bg: 'white' }}
                    />
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="far fa-bell"></i> Alertas de cobertura emitidas.
                  </Text>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  {!lowCoverageLoading ? (
                    <Input
                      variant="secondary-input"
                      fontSize={'24px'}
                      fontWeight={500}
                      value={lowCoverage?.lowCoverageProducts?.length}
                      textAlign={'center'}
                      disabled
                      _disabled={{ bg: 'white' }}
                    />
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['not-even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="far fa-chart-bar"></i> Porcentaje de proceso
                    completado.
                  </Text>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  {!panelLoading ? (
                    <InputGroup w={'160px'}>
                      <Input
                        variant="secondary-input"
                        fontSize={'24px'}
                        fontWeight={500}
                        value={panel?.processStatus}
                        textAlign={'center'}
                        disabled
                        _disabled={{ bg: 'white' }}
                      />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="fas fa-spinner"></i> Porcentaje de utilización de
                    recursos del sistema.
                  </Text>
                </Td>
                <Td bg={colors.generic['even-row']}>
                  {!sistemLoading ? (
                    <InputGroup w="160px">
                      <Input
                        variant="secondary-input"
                        fontSize={'24px'}
                        fontWeight={500}
                        value={`${
                          sistemStatus
                            ? (
                                ((sistemStatus?.ram.total -
                                  sistemStatus?.ram.free) /
                                  sistemStatus?.ram?.total) *
                                100
                              ).toFixed(2)
                            : '0'
                        }`}
                        textAlign={'center'}
                        disabled
                        _disabled={{ bg: 'white' }}
                      />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td bg={colors.generic['not-even-row']}>
                  <Text fontSize={'24px'} fontWeight={500}>
                    <i class="far fa-folder"></i> Porcentaje de utilización de
                    recursos para almacenamiento.
                  </Text>
                </Td>
                <Td bg={colors.generic['not-even-row']}>
                  {!sistemLoading ? (
                    <InputGroup w="160px">
                      <Input
                        variant="secondary-input"
                        fontSize={'24px'}
                        fontWeight={500}
                        value={`${
                          sistemStatus
                            ? (
                                ((sistemStatus?.storage.total -
                                  sistemStatus?.storage.free) /
                                  sistemStatus?.storage?.total) *
                                100
                              ).toFixed(2)
                            : '0'
                        }`}
                        textAlign={'center'}
                        disabled
                        _disabled={{ bg: 'white' }}
                      />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                  ) : (
                    <Flex
                      w={'160px'}
                      h={'40px'}
                      bg={'white'}
                      borderRadius={'5px'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <CircularProgress
                        isIndeterminate
                        color={colors.generic[1]}
                        size={'30px'}></CircularProgress>
                    </Flex>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Flex>
  );
};

export default GeneralPanel;
