import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { colors } from '../../../theme';

const SelectCategoryParent = props => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const color = 'black';
  const colorBg = 'white';
  const colorHover = colors.primary['50'];
  const colorHoverOption = colors.primary['200'];
  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value) {
      userValue = options.find(
        option => String(option.value) === String(value),
      );

      userValue ? setValue(userValue) : loadDefaultOptions(value);
    }
    if (!props.value) {
      setValue(null);
    }
  }, [props.value]);

  useEffect(() => {
    defaultOptions();
  }, [props.prefix, props.level]);

  useEffect(() => {
    defaultOptions();
  }, []);

  useEffect(() => {
    if (props.level == 1) {
      setValue(null);
    }
  }, [props.level]);

  const handleChange = selectedValue => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-options-nameless';
    event.target.value = selectedValue ? selectedValue.value : null; //selectedValue.map(r=>r.value) : [];
    event.target.type = 'react-select';
    props.onChange(event);
    props.setParentLevel(selectedValue.level);
  };

  const defaultOptions = async () => {
    let filters = {};

    if (props.level !== 'all') {
      filters = {
        where: {
          categoryLevel: props.level,
          // prefix: props.prefix,
        },
      };
    }

    apiHelper
      .getParentOptions(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.categoryName,
            label: entity.categoryName,
            level: entity.categoryLevel,
          };
        });

        setOptions(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadDefaultOptions = value => {
    if (!value || !value.length) return;
    let filters = {};

    if (props.level == 'all') {
      filters = {
        where: {
          categoryName: value,
        },
      };
    } else {
      filters = {
        where: {
          categoryLevel: props.level,
          // prefix: props.prefix,
          categoryName: value,
        },
      };
    }
    apiHelper
      .getParentOptions(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.categoryName,
            label: entity.categoryName,
            level: entity.categoryLevel,
          };
        });
        setOptions(entitiesForSelect);
        setValue(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadOptions = (input, callback) => {
    if (!input.length) return;

    let filters = {
      where: {
        categoryName: input,
        // prefix: props.prefix,
      },
    };

    if (props.level !== 'all') {
      filters = {
        where: {
          categoryLevel: props.level,
          // prefix: props.prefix,
          categoryName: input,
        },
      };
    }

    apiHelper
      .getParentOptions(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.categoryName,
            label: entity.categoryName,
            level: entity.categoryLevel,
          };
        });
        setOptions(entitiesForSelect);
        callback(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '0px solid black',
      borderColor: 'white',
      backgroundColor: 'none',
      outline: 'none',
      '&:hover': { backgroundColor: colorHover },
    }),
    placeholder: () => ({
      color: color,
      fontSize: '16px',
      marginLeft: '8px',
      '&:hover': { fontSize: '15px' },
    }),

    singleValue: () => ({
      color: color,
    }),

    menuList: provide => ({
      ...provide,
      color: color,
      backgroundColor: colorBg,
    }),
    option: (provider, state) => ({
      ...provider,
      '&:hover': { backgroundColor: colorHoverOption },
      backgroundColor: state.isFocused ? colorHoverOption : 'none',
      color: color,
    }),
  };

  let components = props.isDisabled && {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  return (
    <div style={{ cursor: 'no-drop' }}>
      <AsyncSelect
        styles={customStyles}
        onChange={handleChange}
        defaultOptions={options}
        loadOptions={loadOptions}
        value={value}
        name={props.name}
        required={props.required}
        components={components}
        isDisabled={props.isDisabled || props.level == 1}
        placeholder={props.placeholder ? props.placeholder : 'Padre'}
      />
    </div>
  );
};

export default SelectCategoryParent;
