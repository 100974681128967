import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Tooltip,
  Button,
  useColorModeValue,
  Input,
  SimpleGrid,
  Spacer,
  ButtonGroup,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  DeleteIcon,
  SearchIcon,
  DownloadIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import SelectProvince from '../common/selects/SelectProvince';
import SelectStore from '../common/selects/SelectStore';
import SelectBranch from '../common/selects/SelectBranch';
import SelectDates from '../common/selects/SelectDates';
import moment from 'moment';

const ProductComparativePricesFilter = props => {
  const color = useColorModeValue('black', 'white');
  const colorTextButton = useColorModeValue('white', 'black');
  const colorHover = useColorModeValue('#DDDDDD', '#6B6B6B');
  const [datesFilter, setDatesFilter] = useState([null, null]);
  const initialState = {
    branchType: '',
    province: '',
    storeBrand: '',
    days: 30,
    day_from: '',
    day_to: '',
    coverage: '',
  };
  const [state, setState] = useState(initialState);
  const extraButtons = props.extraButtons;

  useEffect(() => {
    props.loadData(getFiltersObject(state));
  }, []);

  const onFiltersSubmit = () => {
    props.loadData(getFiltersObject(state, datesFilter));
  };

  const getFiltersObject = (values, datesFilter) => {
    return {
      where: {
        branchType: values.branchType ? values.branchType : null,
        province: values.province ? values.province : null,
        storeBrand: values.storeBrand ? values.storeBrand : null,
        coverage: values.coverage ? values.coverage : null,
        day_from:
          datesFilter && datesFilter[0]
            ? moment(datesFilter[0]).toString()
            : moment().subtract(30, 'days').toString(),
        day_to:
          datesFilter && datesFilter[1]
            ? moment(datesFilter[1]).toString()
            : moment().toString(),
      },
    };
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  const onFiltersReset = () => {
    setState(initialState);
    setDatesFilter([null, null]);
    props.loadData(getFiltersObject(initialState));
  };

  return (
    <Box>
      <SimpleGrid
        spacing={1}
        columns={{ base: 1, sm: 4, lg: 4 }}
        rows={{ lg: 2 }}>
        <Tooltip
          label="Filtrar por puntos de venta mínimos"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box>
            <InputGroup>
              <Input
                border={'1px solid'}
                value={state.coverage}
                borderColor={color}
                _placeholder={{
                  color: color,
                }}
                _hover={{
                  fontSize: '15px',
                  bg: colorHover,
                }}
                placeholder="Puntos de venta mínimos"
                name="coverage"
                onChange={handleChange}
                minW={'220px'}
              />
              {state.coverage !== '' && (
                <InputRightElement
                  children={
                    <Button
                      p={0}
                      m={0}
                      boxSize={0}
                      onClick={() =>
                        setState(prevState => {
                          return { ...prevState, coverage: '' };
                        })
                      }
                      bg={'none'}>
                      <CloseIcon color="grey" boxSize={3} />
                    </Button>
                  }
                />
              )}
            </InputGroup>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por rango de fecha"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            w="full"
            borderRadius="md"
            border={'1px solid'}
            borderColor={color}
            _hover={{
              fontSize: '15px',
              bg: colorHover,
            }}
            minW={'220px'}>
            <SelectDates value={datesFilter} onChange={setDatesFilter} />
          </Box>
        </Tooltip>

        <Tooltip
          label="Filtrar por canal"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            fontSize="16px"
            borderRadius="md"
            border={'1px solid'}
            borderColor={color}
            placeholder="Canal"
            _placeholder={{
              color: color,
            }}
            _hover={{
              fontSize: '15px',
            }}
            id="branchType"
            minW={'220px'}>
            <SelectBranch
              placeholder="Canal"
              value={state.branchType}
              combinationFilters={{
                storeBrand: state.storeBrand,
                province: state.province,
              }}
              name={'branchType'}
              onChange={handleChange}></SelectBranch>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por cadena"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            borderRadius="md"
            border={'1px solid'}
            borderColor={color}
            placeholder="Cadena"
            _placeholder={{
              color: color,
            }}
            fontSize="16px"
            _hover={{
              fontSize: '15px',

              backgroundColor: 'none',
            }}
            id="storeBrand"
            minW={'220px'}>
            {' '}
            <SelectStore
              placeholder="Cadena"
              value={state.storeBrand}
              combinationFilters={{
                province: state.province ? state.province : null,
                branchType: state.branchType ? state.branchType : null,
              }}
              name={'storeBrand'}
              onChange={handleChange}></SelectStore>
          </Box>
        </Tooltip>
        <Tooltip
          label="Filtrar por provincia"
          placement="top"
          arrowSize={5}
          hasArrow>
          <Box
            fontSize="16px"
            borderRadius="md"
            border={'1px solid'}
            borderColor={color}
            placeholder="Provincia"
            _placeholder={{
              color: color,
            }}
            _hover={{
              fontSize: '15px',
            }}
            id="province"
            minW={'220px'}>
            {' '}
            <SelectProvince
              placeholder="Provincia"
              value={state.province}
              combinationFilters={{
                storeBrand: state.storeBrand,
                branchType: state.branchType,
              }}
              defaultEnable={false}
              name={'province'}
              onChange={handleChange}></SelectProvince>
          </Box>
        </Tooltip>
        {props.extraButtons}
        <Box>
          <Button
            variant="reset-filters-button"
            onClick={onFiltersReset}
            type="reset"
            isLoading={props.isLoading}>
            <Text px={4} display={{ base: 'none', sm: 'block' }}>
              Limpiar filtros
            </Text>
            <DeleteIcon />
          </Button>
        </Box>
        <Box minW={'220px'}>
          <Button
            variant="submit-filters-button"
            onClick={onFiltersSubmit}
            type="submit"
            isLoading={props.isLoading}>
            <Text px={4} display={{ base: 'none', sm: 'block' }}>
              Aplicar filtros
            </Text>
            <SearchIcon />
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default ProductComparativePricesFilter;
