import React, { useEffect, useState } from 'react';
import Login from '../login/Login';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from '../../utils/PrivateRoute';
import UserDetail from '../user/UserDetail';
import User from '../user/User';
import ProductDetail from '../products/ProductDetail';
import RecoverPassword from '../login/RecoverPassword';
import { VTasksWidget } from 'vtasks-widget';
import PublicLayout from '../common/PublicLayout';
import PrivateLayout from '../common/PrivateLayout';
import Products from '../products/Products';
import Categories from '../categories/Categories';
import ManageCronProcesses from '../manageCronProcesses/ManageCronProcesses';
import ProductComparativeScreen from '../products/ProductComparativeScreen';
import BranchesContext from '../../contexts/BranchesContext';
import JoyrideContext from '../../contexts/JoyrideContext';
import apiHelpers from '../../utils/apiHelpers';
import Notification from '../notification/Notification';
import DinamicReport from '../dinamicReport/DinamicReport';
import AlertTemplate from 'react-alert-template-oldschool-dark';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import GeneralPanel from '../generalPanel/GeneralPanel';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

function App() {
  const [branches, setBranches] = useState({
    allBranches: [],
    branchesCount: 0,
    isLoading: true,
  });
  const [isJoyrideActive, setJoyride] = useState(false);

  useEffect(() => {
    if (apiHelpers.isAuthenticated()) {
      getBranches();
    }
  }, []);

  const getBranches = () => {
    apiHelpers
      .getBranches({
        limit: 10000,
      })
      .then(res =>
        setBranches({
          allBranches: res.data,
          branchesCount: res.data.length,
          isLoading: false,
        }),
      )
      .catch(e => console.log(e));
  };
  return (
    <>
      <VTasksWidget
        project="XXXXXXXXXXXXXXXXXXXXXXXX"
        environmentLabel="XXXXXX DEV"
        environmentUrl="dev.XXXXX.venturing.com.ar"
        apikey="XXXXXXXXXXXXXXXXX"
        vTasksUrl="http://dev.vtasks.venturing.com.ar"
        vTasksAPIUrl="https://dev.XXXXXX.venturing.com.ar/XXXXXX/api"
      />
      <JoyrideContext.Provider value={{ isJoyrideActive, setJoyride }}>
        <AlertProvider template={AlertTemplate} {...options}>
          <BranchesContext.Provider value={branches}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="login"
                  element={
                    <PublicLayout
                      children={<Login loadContexts={getBranches} />}
                    />
                  }
                />
                <Route
                  name="recover_password"
                  path={`/recover_password`}
                  element={<PublicLayout children={<RecoverPassword />} />}
                />

                {/* <Route exact path="/user" element={<PrivateRoute />}>
                <Route exact path="/user/:id" element={UserDetail} />
              </Route> */}
                <Route path="/" element={<Navigate to="/panel" replace />} />
                <Route exact path="/" element={<PrivateRoute />}>
                  <Route
                    exact
                    path="/products/ComparativeScreen"
                    element={
                      <PrivateLayout children={<ProductComparativeScreen />} />
                    }
                  />
                  <Route
                    exact
                    path="/panel"
                    element={<PrivateLayout children={<GeneralPanel />} />}
                  />
                  <Route
                    exact
                    path="/users/:id"
                    element={<PrivateLayout children={<UserDetail />} />}
                  />
                  <Route
                    exact
                    path="/notifications"
                    element={<PrivateLayout children={<Notification />} />}
                  />
                  <Route
                    exact
                    path="/users"
                    element={<PrivateLayout children={<User />} />}
                  />
                  <Route
                    exact
                    path="/products/:id"
                    element={<PrivateLayout children={<ProductDetail />} />}
                  />
                  <Route
                    exact
                    path="/products"
                    element={<PrivateLayout children={<Products />} />}
                  />
                  <Route
                    exact
                    path="/dinamicReport"
                    element={<PrivateLayout children={<DinamicReport />} />}
                  />
                  <Route
                    exact
                    path="/categories"
                    element={<PrivateLayout children={<Categories />} />}
                  />
                  <Route
                    exact
                    path="/manageCronProcesses"
                    element={
                      <PrivateLayout children={<ManageCronProcesses />} />
                    }
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </BranchesContext.Provider>
        </AlertProvider>
      </JoyrideContext.Provider>
    </>
  );
}

export default App;
