import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useColorMode } from '@chakra-ui/react';
import moment from 'moment';
import formatter from '../../utils/formatter';

const PriceChart = props => {
  const color = useColorMode('black', 'lightgrey');
  const [dataArray, setDataArray] = useState([]);
  const [seriesArray, setSeriesArray] = useState([]);
  const [series2Array, setSeries2Array] = useState([]);

  useEffect(() => {
    console.log(props.data);
    let newArray;
    for (let pricesOfProductEan of props.data) {
      newArray = dataArray;
      newArray.push(pricesOfProductEan);
    }

    let seriesData = [];
    let maxCoverage = 0;
    newArray.map(prices => {
      let data = [];
      prices.map(price => {
        data.push({
          max: price.maxPrice,
          min: price.minPrice,
          media: price.medPrice,
          fecha: moment(price.metric_date).startOf('day').toDate().getTime(),
          moda: price.modePrice,
          color: price.color,
          description: price.productName,
          coverageCount: price.coverage,
          coverage: (price.coverage / props.totalBranches) * 100,
          coverageTooltip: formatter.formatNumberDecimals(
            (price.coverage / props.totalBranches) * 100,
            2,
          ),
        });
        if ((price.coverage / props.totalBranches) * 100 > maxCoverage) {
          maxCoverage = (price.coverage / props.totalBranches) * 100;
        }
      });

      seriesData.push(data);
    });
    setDataArray(seriesData);
    const maxCoverageRound = Math.ceil(maxCoverage);
    let coverageY2AxisLimit;
    if (maxCoverage < 0.8) {
      coverageY2AxisLimit = 1;
    } else if (maxCoverage < 10) {
      coverageY2AxisLimit = maxCoverageRound * 2;
    } else if (maxCoverage < 50) {
      coverageY2AxisLimit = maxCoverage + 15;
    } else {
      coverageY2AxisLimit = 120;
    }

    am5.ready(function () {
      let root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          pinchZoomX: true,
        }),
      );

      let xRenderer = am5xy.AxisRendererX.new(root, {});
      xRenderer.grid.template.setAll({ forceHidden: true, fill: '#888888' });
      xRenderer.labels.template.setAll({
        multiLocation: 0,
        location: 0,
        fill: '#888888',
      });

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'hour', count: 10 },
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
          extraMin: 0.01,
          extraMax: 0.01,
          tooltipLocation: 0,
        }),
      );

      let yRenderer = am5xy.AxisRendererY.new(root, {});
      yRenderer.grid.template.setAll({ forceHidden: true, fill: '#888888' });
      yRenderer.labels.template.setAll({
        multiLocation: 0,
        location: 0,
        fill: '#888888',
      });

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          renderer: yRenderer,
          extraMin: 0.05,
          extraMax: 0.05,
        }),
      );

      let yRenderer2 = am5xy.AxisRendererY.new(root, {
        opposite: true,
      });

      let yAxis2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer2,
          min: 0,
          // max: 120,
          max: coverageY2AxisLimit,
          strictMinMax: true,
          syncWithAxis: yAxis,
        }),
      );

      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
          xAxis: xAxis,
        }),
      );
      cursor.lineX.set('stroke', '#888888');
      cursor.lineY.set('stroke', '#888888');

      let newSeries = [];
      let newSeries2 = [];
      for (let i = 0; i < dataArray.length; i++) {
        let series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: 'Series',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: props.priceSelected,
            valueXField: 'fecha',
            locationX: 0,
            stroke: am5.color(dataArray[i][0].color),
            seriesTooltipTarget: 'bullet',
            showTooltipOn: 'always',
            tooltip: am5.Tooltip.new(root, {
              labelText:
                props.priceSelected === 'coverage'
                  ? '{coverageTooltip}' + '% ' + ' ' + '{description}'
                  : props.priceSelected === 'coverageCount'
                  ? '{valueY} sucursales reportando {description}'
                  : '${valueY}' + '  ' + '{description}',
            }),
          }),
        );

        series.strokes.template.setAll({
          strokeWidth: 3,
        });

        series.bullets.push(function () {
          let circleTemplate = am5.Template.new({
            radius: 6,
            templateField: 'bulletSettings',
            fill: am5.color(dataArray[i][0].color),
            strokeWidth: 2,
            tooltipText:
              props.priceSelected === 'coverage'
                ? '{coverageTooltip}' + '% ' + ' ' + '{description}'
                : props.priceSelected === 'coverageCount'
                ? '{valueY} sucursales reportando {description}'
                : '${valueY}' + '  ' + '{description}',
            stroke: am5.color(dataArray[i][0].color),
          });

          let circle = am5.Circle.new(root, {}, circleTemplate);

          return am5.Bullet.new(root, {
            sprite: circle,
            locationX: 0,
          });
        });
        series.data.setAll(dataArray[i]);
        series.appear(1000);
        newSeries.push(series);

        let series2 = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: 'series2',
            xAxis: xAxis,
            yAxis: yAxis2,
            valueYField: 'coverage',
            valueXField: 'fecha',
            stroke: am5.color(dataArray[i][0].color),
            fill: am5.color(dataArray[i][0].color),
            categoryXField: 'fecha',
          }),
        );
        series2.columns.template.setAll({
          tooltipText: '{coverageTooltip}' + '% ' + ' ' + '{description}',
          width: am5.percent(90),
          tooltipY: 0,
        });

        series2.data.setAll(dataArray[i]);
        series2.appear(1000);
        newSeries2.push(series2);
      }

      setSeriesArray(newSeries);
      setSeries2Array(newSeries2);

      chart.appear(1000, 100);

      return () => root.dispose();
    });
  }, [props.priceSelected]);

  useEffect(() => {
    if (seriesArray !== null && seriesArray.length && props.chartPrices) {
      seriesArray.map((series, index) => {
        series.data.setAll(dataArray[index]);
        series.appear(1000);
      });

      setSeriesArray(seriesArray);
    }

    if (
      series2Array !== null &&
      series2Array.length &&
      props.chartCoverage &&
      props.priceSelected == 'coverage'
    ) {
      series2Array.map((series, index) => {
        series.data.setAll(dataArray[index]);
        series.appear(1000);
      });

      setSeries2Array(series2Array);
    }
    if (!props.chartPrices && seriesArray.length && seriesArray !== null) {
      seriesArray.map((series, index) => {
        series.data.setAll({});
        series.appear(1000);
      });
    }

    if (!props.chartCoverage && series2Array !== null && series2Array.length) {
      series2Array.map((series, index) => {
        series.data.setAll({});
        series.appear(1000);
      });
    }

    if (
      seriesArray !== null &&
      seriesArray.length &&
      props.chartCoverage &&
      props.priceSelected == 'coverageCount'
    ) {
      seriesArray.map((series, index) => {
        series.data.setAll(dataArray[index]);
        series.appear(1000);
      });

      setSeriesArray(seriesArray);
    }
  }, [
    props.data,
    seriesArray,
    series2Array,
    props.chartCoverage,
    props.chartPrices,
    props.priceSelected,
  ]);

  return (
    <div
      id="chartdiv"
      style={{
        width: '100%',
        height: '280px',
        background: color,
      }}
    />
  );
};
export default PriceChart;
