const axios = require('axios');
const config = require('../config/config');
const qs = require('qs');

/* APIs ENDPOINTS */
const SESSION_STORAGE_SESSION = 'session';
const SERVER_URL = config.backend.apiUrl;
const LOGIN_URL = SERVER_URL + '/auth/login';
const CHANGE_PASSWORD_URL = SERVER_URL + '/auth/change-password';
const RESET_URL = SERVER_URL + '/auth/reset';
const GENERATE_PASSWORD_URL = SERVER_URL + '/auth/generate-password';
const USERS_URL = SERVER_URL + '/user';
const USER_ROLES_URL = SERVER_URL + '/userRole';
const FILE_URL = SERVER_URL + '/file';
const FILE_INDEX_URL = SERVER_URL + '/file/{id}';
const FILE_URL_URL = SERVER_URL + '/file/url/{id}';
const PRICE_URL = SERVER_URL + '/price/';
const PRODUCT_URL = SERVER_URL + '/product';
const PRODUCT_FORMAT_URL = SERVER_URL + '/productFilter/productFormat';
const PRODUCT_BRAND_URL = SERVER_URL + '/productFilter/productBrand';
const CATEGORY_URL = SERVER_URL + '/category';
const PRICES_METRICS_URL = SERVER_URL + '/priceMetrics';
const BRANCH_URL = SERVER_URL + '/branch';
const CRAWLER_URL = SERVER_URL + '/crawler';
const PRODUCT_FILTERS_URL = SERVER_URL + '/productFilter';
const PARENT_OPTIONS_URL = SERVER_URL + '/category/parentOptions';
const PRESET_REPORT_URL = SERVER_URL + '/presetReport';

function getBackendUrl() {
  return SERVER_URL;
}

// Auth APIs

function login(payload) {
  return axios.post(LOGIN_URL, payload).then(function (response) {
    const token = response.data;
    sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify({ token }));
    getCurrentUser().then(function (userResponse) {
      let userData = userResponse.data;
      userData.token = token;
      sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify(userData));
      return userData;
    });
  });
}

function resetPassword(payload) {
  return axios
    .post(RESET_URL, {
      email: payload,
    })
    .then(function (response) {
      return response;
    });
}

function changePassword(payload) {
  return axios
    .post(
      CHANGE_PASSWORD_URL,
      {
        username: payload.username,
        password: payload.password,
        newPassword: payload.newPassword,
      },
      buildHeaders(),
    )
    .then(function (response) {
      return response;
    });
}

function generatePassword(payload) {
  return axios
    .post(GENERATE_PASSWORD_URL, payload, buildHeaders())
    .then(function (response) {
      return response;
    });
}

function logout() {
  sessionStorage.clear(SESSION_STORAGE_SESSION);
}

function isAuthenticated() {
  return sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null;
}

function isAdmin() {
  return (
    sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role == 'ADMIN'
  );
}

function buildHeaders() {
  if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
    return {
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token,
      },
    };
  } else {
    return null;
  }
}

// User APIs

function getUsers(parameters = []) {
  return axios.get(
    `${USERS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUsersCount(parameters = []) {
  return axios.get(
    `${USERS_URL + '/count'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUser(id) {
  return axios.get(USERS_URL + '/' + id, buildHeaders());
}

function postUser(body) {
  return axios.post(USERS_URL + '/', body, buildHeaders());
}

function patchUser(id, body) {
  return axios.patch(USERS_URL + '/' + id, body, buildHeaders());
}

function patchUserNotificationOptions(id, body) {
  return axios.patch(
    USERS_URL + '/' + id + '/notificationOptions',
    body,
    buildHeaders(),
  );
}

function patchUserNotification(id, body) {
  return axios.patch(
    USERS_URL + '/' + id + '/notification',
    body,
    buildHeaders(),
  );
}

function deleteUser(id) {
  return axios.delete(USERS_URL + '/' + id, buildHeaders());
}

function getCurrentUser() {
  return axios.get(USERS_URL + '/me', buildHeaders());
}

function getGeneralPanelData() {
  return axios.get(USERS_URL + '/panel', buildHeaders());
}

function getGeneralPanelSistemStatus() {
  return axios.get(USERS_URL + '/panel/sistemStatus', buildHeaders());
}

function getGeneralPanelLowCoverage() {
  return axios.get(USERS_URL + '/panel/lowCoverage', buildHeaders());
}

function getUserRoles(parameters = []) {
  return axios.get(
    `${USER_ROLES_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

// File APIs

function getFiles(parameters = []) {
  return axios.get(
    `${FILE_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getFilesCount() {
  return axios.get(FILE_URL + '/count', buildHeaders());
}

function getFile(id) {
  return axios.get(FILE_INDEX_URL.replace('{id}', id), buildHeaders());
}

function getFileURL(id) {
  return axios.get(FILE_URL_URL.replace('{id}', id), buildHeaders());
}

function postFile(file) {
  const data = new FormData();
  data.append('file', document.file);
  return axios.post(FILE_URL, data, buildHeaders());
}

function patchFile(id, payload) {
  return axios.patch(
    FILE_INDEX_URL.replace('{id}', id),
    payload,
    buildHeaders(),
  );
}

function deleteFile(id) {
  return axios.delete(FILE_INDEX_URL.replace('{id}', id), buildHeaders());
}

function getPrices(parameters = []) {
  return axios.get(
    `${PRICE_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getPricesReport(parameters = []) {
  return axios.get(
    `${PRICE_URL}/report?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getProducts(parameters = []) {
  return axios.get(
    `${PRODUCT_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getProductsByEans(parameters = []) {
  return axios.get(
    `${PRODUCT_URL}/eans?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getProductsCount(parameters = []) {
  return axios.get(
    `${PRODUCT_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function patchProduct(id, body) {
  return axios.patch(
    PRODUCT_URL + '/' + id + '/relevant',
    body,
    buildHeaders(),
  );
}

function patchProductDetails(id, body) {
  return axios.patch(PRODUCT_URL + '/' + id, body, buildHeaders());
}

function getCategories(parameters = []) {
  return axios.get(
    `${CATEGORY_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}
function getParentOptions(parameters = []) {
  return axios.get(
    `${PARENT_OPTIONS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getRelevantAndRelatedCategories(parameters = []) {
  return axios.get(
    `${CATEGORY_URL + '/getRelevantAndRelatedCategories'}?${qs.stringify(
      parameters,
      { skipNulls: true },
    )}`,
    buildHeaders(),
  );
}

function getCategoriesCount(parameters = []) {
  return axios.get(
    `${CATEGORY_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function patchCategory(id, body) {
  return axios.patch(CATEGORY_URL + '/' + id, body, buildHeaders());
}

function getProduct(ean) {
  return axios.get(PRODUCT_URL + '/' + ean, buildHeaders());
}

function getPricesMetrics(parameters = []) {
  return axios.get(
    `${PRICES_METRICS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getPricesMetricsForExport(parameters = []) {
  return axios.get(
    `${PRICES_METRICS_URL + '/export'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getPriceMetricsCount(parameters = []) {
  return axios.get(
    `${PRICES_METRICS_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getPricesMetricsProvinces(parameters = []) {
  return axios.get(
    `${PRICES_METRICS_URL + '/provinces'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getPricesMetricsBrands(parameters = []) {
  return axios.get(
    `${PRICES_METRICS_URL + '/brands'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getPricesMetricsStores(parameters = []) {
  return axios.get(
    `${PRICES_METRICS_URL + '/stores'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getBranches(parameters = []) {
  return axios.get(
    `${BRANCH_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getBranchCount(parameters = []) {
  return axios.get(
    `${BRANCH_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getCrawlPrices() {
  return axios.get(CRAWLER_URL + '/relevantcategories', buildHeaders());
}

function getCrawlStores() {
  return axios.get(CRAWLER_URL + '/stores', buildHeaders());
}

function getCrawlerCategories() {
  return axios.get(CRAWLER_URL + '/categories', buildHeaders());
}

function getCrawlerPricemetrics(date, preserveMetrics) {
  return axios.post(
    CRAWLER_URL + '/pricemetrics',
    { date: date ? date : null, preserveMetrics: preserveMetrics },
    buildHeaders(),
  );
}

function getMissingPrices(date) {
  return axios.post(
    CRAWLER_URL + '/missingprices',
    { date: date ? date : null },
    buildHeaders(),
  );
}

function notifyPriceShiftedProducts() {
  return axios.get(CRAWLER_URL + '/notifyPriceShiftedProducts', buildHeaders());
}

function notifyLowCoverageProducts() {
  return axios.get(CRAWLER_URL + '/notifyLowCoverageProducts', buildHeaders());
}

function notifyCancelledProducts() {
  return axios.get(CRAWLER_URL + '/notifyCancelledProducts', buildHeaders());
}

function notifynewproducts() {
  return axios.get(CRAWLER_URL + '/notifynewproducts', buildHeaders());
}

function getProductFilters(parameters = []) {
  return axios.get(
    `${PRODUCT_FILTERS_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getProductFormat(parameters = []) {
  return axios.get(
    `${PRODUCT_FORMAT_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getProductBrand(parameters = []) {
  return axios.get(
    `${PRODUCT_BRAND_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function postPresetReport(body) {
  return axios.post(PRESET_REPORT_URL, body, buildHeaders());
}

function getPresetReportForUser(userId) {
  return axios.get(PRESET_REPORT_URL + '/user/' + userId, buildHeaders());
}

function deletePresetReportForUser(presetId) {
  return axios.delete(PRESET_REPORT_URL + '/' + presetId, buildHeaders());
}

export default {
  SERVER_URL,
  getBackendUrl,
  getPrices,
  logout,
  login,
  resetPassword,
  changePassword,
  generatePassword,
  isAuthenticated,
  isAdmin,
  getCrawlPrices,
  getCrawlStores,
  getCrawlerPricemetrics,
  getCrawlerCategories,
  getMissingPrices,
  notifyPriceShiftedProducts,
  notifyLowCoverageProducts,
  notifyCancelledProducts,
  notifynewproducts,
  getUsers,
  getUsersCount,
  getUser,
  postUser,
  patchUser,
  deleteUser,
  getCurrentUser,
  getUserRoles,
  getFiles,
  getFilesCount,
  getFile,
  getFileURL,
  postFile,
  patchFile,
  deleteFile,
  getProducts,
  getProduct,
  getProductsCount,
  getCategories,
  getRelevantAndRelatedCategories,
  getCategoriesCount,
  patchCategory,
  patchProduct,
  getPricesMetrics,
  getPriceMetricsCount,
  getPricesMetricsProvinces,
  getPricesMetricsBrands,
  getPricesMetricsStores,
  getPricesMetricsForExport,
  getBranches,
  getBranchCount,
  getProductFilters,
  getProductFormat,
  getProductBrand,
  getParentOptions,
  patchProductDetails,
  patchUserNotification,
  postPresetReport,
  getPresetReportForUser,
  deletePresetReportForUser,
  getPricesReport,
  patchUserNotificationOptions,
  getProductsByEans,
  getGeneralPanelData,
  getGeneralPanelSistemStatus,
  getGeneralPanelLowCoverage,
};
