import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Stack,
  useColorMode,
  Text,
  Spacer,
  HStack,
  Link,
  Image,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  InfoOutlineIcon,
  MoonIcon,
  QuestionOutlineIcon,
  SunIcon,
} from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import apiHelpers from '../../utils/apiHelpers';
import sistemManual from './manual.pdf';
import JoyrideContext from '../../contexts/JoyrideContext';

const NavBar = props => {
  const { isJoyrideActive, setJoyride } = useContext(JoyrideContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const borderColor = useColorModeValue('white', 'white');
  const buttonBorderColor = useColorModeValue('white', 'white');
  const buttonTextColor = useColorModeValue('white', 'white');

  const mainColor = 'generic.1';
  const imageHeading = useColorModeValue(
    process.env.PUBLIC_URL + '/img/navbar_logo_light.png',
    process.env.PUBLIC_URL + '/img/navbar_logo_dark.png',
  );
  const navigate = useNavigate();
  const location = useLocation();
  const signOff = event => {
    event.preventDefault();
    try {
      apiHelpers.logout();
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  const NavLink = ({ children, href }) => (
    <Link
      borderColor={useColorModeValue('black', 'white')}
      borderBlock="1px solid "
      borderInline="1px solid"
      px={2}
      py={2}
      ml={2}
      rounded={'md'}
      _hover={{
        fontSize: '15px',
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', '#629C99'),
        color: useColorModeValue('gray.700', 'gray.100'),
      }}
      href={href}>
      {children}
    </Link>
  );
  const MenuLink = ({ children, href }) => {
    return (
      <Link
        href={href}
        _hover={{
          textDecoration: 'none',
          color: 'black',
        }}>
        <MenuItem
          _hover={{
            border: 'none',
            outline: 'none',
            textDecoration: 'none',
            color: 'black',
          }}>
          <Text
            as="b"
            _hover={{
              textDecoration: 'none',
              color: 'black',
            }}>
            {children}
          </Text>
        </MenuItem>
      </Link>
    );
  };

  const updateOnboarding = () => {
    setJoyride(true);
  };

  return (
    <Box bgGradient="linear(to-r,white,generic.1)" px={4} w={'full'} py={5}>
      <Flex
        h="54px"
        justifyContent={'space-between'}
        alignItems={'center'}
        px={3}>
        <Image width="200px" src={imageHeading} ml={6} />
        <Spacer />
        {!props.publicLayout && (
          <Flex gap={'25px'} mr={'45px'}>
            <Menu autoSelect={false}>
              <MenuButton
                as={Button}
                background="transparent"
                color={buttonTextColor}
                borderColor={buttonBorderColor}
                w={'185px'}
                h={'45px'}
                border="solid 2px "
                _active={{
                  bg: 'transparent',
                }}
                _hover={{
                  bg: 'transparent',
                }}>
                <Text color="white">Información</Text>
              </MenuButton>
              <MenuList border="3px solid" borderColor={'primary.200'}>
                <MenuItem
                  onClick={e => updateOnboarding(e)}
                  _hover={{
                    border: 'none',
                    outline: 'none',
                    bg: 'primary.300',
                  }}>
                  <Text fontSize="md" as="b">
                    ¿Cómo uso la plataforma?
                  </Text>
                  <InfoOutlineIcon ml={2} w={5} h={5}></InfoOutlineIcon>
                </MenuItem>
                <Link
                  href={sistemManual}
                  rel="noreferrer"
                  target="_blank"
                  _hover={{
                    border: 'none',
                    outline: 'none',
                    textDecoration: 'none',
                    color: 'black',
                  }}>
                  <MenuItem
                    _hover={{
                      border: 'none',
                      outline: 'none',
                      bg: 'primary.300',
                    }}>
                    <Text fontSize="md" as="b" mr={2}>
                      Manual del sistema
                    </Text>
                    <i className="fa fa-solid fa-book"></i>
                  </MenuItem>
                </Link>
              </MenuList>
            </Menu>
            <Menu autoSelect={false}>
              <MenuButton
                as={Button}
                rightIcon={<HamburgerIcon color="white" />}
                background="transparent"
                color={buttonTextColor}
                borderColor={buttonBorderColor}
                border="solid 2px "
                _hover={{
                  bg: 'transparent',
                }}
                _focus={{
                  bg: 'transparent',
                }}
                _active={{
                  bg: 'transparent',
                }}
                w={'185px'}
                h={'45px'}>
                <Text color="white">Menú</Text>
              </MenuButton>
              <MenuList
                border="solid 3px"
                borderColor={'primary.200'}
                w={'235px'}>
                {props.links.map(link => {
                  return (
                    <MenuItem
                      as="a"
                      href={link.href}
                      _hover={{ bg: 'primary.300', color: 'black' }}
                      style={{ textDecoration: 'none' }}>
                      <Text fontSize="md"> {link.label} </Text>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  _hover={{
                    border: 'none',
                    outline: 'none',
                    textDecoration: 'none',
                    color: 'black',
                  }}>
                  <Button
                    _hover={{
                      fontSize: '15px',
                      textDecoration: 'none',
                      bg: 'primary.400',
                      color: 'white',
                    }}
                    _active={{
                      bg: 'primary.600',
                    }}
                    h={'42px'}
                    w={'195px'}
                    bgColor={mainColor}
                    onClick={e => signOff(e)}>
                    <Text color="white">Cerrar Sesión</Text>
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default NavBar;
