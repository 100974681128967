import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiHelper from '../../utils/apiHelpers';
import {
  Flex,
  VStack,
  Heading,
  Spacer,
  TableContainer,
  Table,
  TableCaption,
  Stack,
  Skeleton,
  Thead,
  Tr,
  Th,
  Text,
  Box,
  useBreakpointValue,
  Center,
  Tbody,
  Td,
  Switch,
  HStack,
  Button,
  FormControl,
  Input,
  RadioGroup,
  Radio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { colors } from '../../theme';
import SelectUserRoleNew from '../common/selects/SelectUserRoleNew';
import { useAlert } from 'react-alert';

const UserDetail = props => {
  const alert = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    notification: null,
  });
  const [loading, setLoading] = useState(false);
  let param = location.pathname.split('/').pop();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (param == 'new') {
    } else {
      getUser();
    }
  }, []);
  const getUser = async () => {
    let response = await apiHelper.getUser(param);
    setUser(response.data);
  };
  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleDelete = async () => {
    try {
      await apiHelper.deleteUser(user.id);
      alert.success('Se elimino al usuario correctamente');
      navigate('/users');
    } catch (error) {
      console.log(error);
      alert.error('Ocurrio un error al eliminar el usuario');
    }
  };

  const handleRadioChange = event => {
    const target = event.target;
    const name = target.name;
    let value;
    if (name == 'notification-true') {
      value = true;
    } else {
      value = false;
    }
    setUser({
      ...user,
      notification: value,
    });
  };

  const validateUserData = () => {
    if (user.firstName.length < 4) {
      return {
        status: true,
        message: 'El nombre debe tener almenos 4 caracteres de largo',
      };
    }
    if (user.lastName.length < 4) {
      return {
        status: true,
        message: 'El apellido debe tener almenos 4 caracteres de largo',
      };
    }
    if (!user.role) {
      return { status: true, message: 'El campo de rol es obligatorio' };
    }
    return { status: false };
  };

  const submitUser = async () => {
    const validate = validateUserData();
    if (validate.status) {
      alert.error(validate.message);
      return;
    }
    try {
      if (param == 'new') {
        try {
          let response = await apiHelper.postUser(user);
          alert.success('Usuario creado correctamente');
        } catch (error) {
          return alert.error(error.response.data.message);
        }
      } else {
        try {
          let response = await apiHelper.patchUser(param, user);
          alert.success('Usuario modificado correctamente');
        } catch (error) {
          return alert.error(error.response.data.message);
        }
      }
      navigate('/users');
    } catch (error) {
      console.log('ERROR actualizando usuario', error);
    }
  };

  return (
    <Flex w="100%" justify="center">
      <VStack w="100%">
        <Flex
          my={4}
          bg={colors.generic['admin-headers']}
          w="100%"
          color={'white'}
          minH={'100px'}
          py={4}
          justify="space-around"
          align="center"
          direction={{ base: 'column', md: 'row' }}>
          <Heading>Usuarios</Heading>
          <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
            <Button variant="primary-button" visibility={'hidden'}>
              Agregar Usuario
            </Button>
          </Flex>
        </Flex>
        <Spacer />
        <Box
          bgColor={colors.generic['even-row']}
          borderRadius="lg"
          boxShadow={'dark-lg'}
          mb={5}
          m={useBreakpointValue({ base: 5, md: 4 })}
          w={{ base: '100%', md: '350px' }}>
          <Flex alignItems="center" justifyContent="center" w={'100%'}>
            <VStack direction="column" py={3}>
              {param == 'new' ? (
                <>
                  <i
                    class="fa fa-solid fa-user"
                    style={{ fontSize: '50px', color: 'white' }}></i>
                  <Text color={'white'} fontSize={20}>
                    Crear usuario
                  </Text>
                </>
              ) : (
                <>
                  <i
                    class="fa fa-solid fa-user"
                    style={{ fontSize: '50px', color: 'white' }}></i>
                  <Text color={'white'} fontSize={20}>
                    Datos del usuario
                  </Text>
                </>
              )}

              <VStack>
                <FormControl>
                  <VStack>
                    <Input
                      variant="primary-input"
                      placeholder="Nombre"
                      name="firstName"
                      value={user.firstName}
                      onChange={handleChange}
                    />
                    <Input
                      variant="primary-input"
                      placeholder="Apellido"
                      name="lastName"
                      value={user.lastName}
                      onChange={handleChange}
                    />
                    <Input
                      variant="primary-input"
                      placeholder="Nombre de usuario"
                      name="username"
                      value={user.username}
                      onChange={handleChange}
                    />
                    <Input
                      variant="primary-input"
                      placeholder="Email"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                    <Input
                      variant="primary-input"
                      placeholder="Contraseña"
                      name="password"
                      onChange={handleChange}
                    />
                    <Box
                      layerStyle={'input-box'}
                      w={'320px'}
                      bg={colors.generic[3]}>
                      <SelectUserRoleNew
                        placeholder="Rol"
                        value={user.role}
                        onChange={handleChange}
                        name="role"
                      />
                    </Box>

                    <VStack>
                      <Text>¿Desea recibir notificaciones en su correo?</Text>
                      <RadioGroup color={colors.generic[4]} name="notification">
                        <Stack spacing={5} direction="row">
                          <Radio
                            mt={2}
                            colorScheme="teal"
                            isChecked={user.notification == true}
                            name="notification-true"
                            onChange={handleRadioChange}>
                            <Box color={'black'}>Si</Box>
                          </Radio>
                          <Radio
                            isChecked={user.notification == false}
                            colorScheme="teal"
                            name="notification-false"
                            onChange={handleRadioChange}>
                            <Box color={'black'}>No</Box>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </VStack>

                    <Button variant="primary-button" onClick={submitUser}>
                      {param == 'new' ? 'Crear Usuario' : 'Guardar cambios'}
                    </Button>
                    {param != 'new' && (
                      <Button
                        colorScheme="red"
                        w={'320px'}
                        h={'45px'}
                        onClick={() => setDeleteModal(true)}>
                        Eliminar Usuario
                      </Button>
                    )}
                  </VStack>
                </FormControl>
              </VStack>
            </VStack>
          </Flex>
        </Box>
      </VStack>
      <Modal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        size="lg">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              {user.id &&
                `¿Desea eliminar al usuario ${user.firstName} ${user.lastName}?`}
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <Flex justifyContent={'flex-end'} gap={3}>
                <Button
                  variant="primary-button"
                  w={'120px'}
                  h={'45px'}
                  onClick={handleDelete}>
                  Eliminar
                </Button>
                <Button
                  colorScheme="red"
                  w={'120px'}
                  h={'45px'}
                  onClick={() => setDeleteModal(false)}>
                  Cancelar
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Flex>
  );
};

export default UserDetail;
