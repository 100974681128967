import { extendTheme } from '@chakra-ui/react';

export const colors = {
  brand: {
    100: '#f7fafc',
    900: '#1a202c',
  },
  primary: {
    50: '#ddfcfc',
    100: '#bceeef',
    200: '#99e0e2',
    300: '#73d2d5',
    400: '#4fc5c9',
    500: '#36abb0',
    600: '#258589',
    700: '#156063',
    800: '#023a3d',
    900: '#001618',
  },
  generic: {
    1: '#00b7b2',
    2: '#B2B4B8',
    3: '#FFFFFF',
    4: 'black',
    'admin-headers': '#b2b4b8',
    'admin-table-headers': '#999999',
    'not-even-row': '#e4e4e4',
    'even-row': '#d8d8d8',
    'user-label': '#8bdbd9',
  },
  'reset-filters': {
    50: '#fdffdf',
    100: '#fafbb3',
    200: '#f6f986',
    300: '#f3f657',
    400: '#f0f42c',
    500: '#f7f98d',
    600: '#a7aa0e',
    700: '#777906',
    800: '#474901',
    900: '#181800',
  },
};

const theme = extendTheme({
  colors: colors,
  fonts: {
    heading: 'Roboto,sans-serif',
    body: 'Roboto,sans-serif',
  },

  config: {
    initialColorMode: 'light',
  },
  components: {
    Modal: {
      sizes: {},
      baseStyle: props => ({
        dialog: {
          border: '4px',
          borderColor: 'primary.200',
        },
      }),
    },
    Input: {
      variants: {
        'primary-input': {
          field: {
            border: '2px solid',
            borderColor: 'generic.1',
            color: 'generic.4',
            _hover: {
              bg: 'primary.50',
              border: '2px solid',
              borderColor: 'generic.1',
            },
            _active: {
              bg: 'primary.100',
              border: '2px solid',
              borderColor: 'generic.1',
            },
            _placehoder: {
              color: 'generic.4',
              border: '2px solid',
              borderColor: 'generic.1',
              opacity: 1,
            },
            minW: '220px',
          },
        },
        'secondary-input': {
          field: {
            // border: '2px solid',
            borderColor: 'generic.1',
            color: 'generic.4',
            // _hover: {
            //   bg: 'primary.50',
            //   border: '2px solid',
            //   borderColor: 'generic.1',
            // },
            // _active: {
            //   bg: 'primary.100',
            //   border: '2px solid',
            //   borderColor: 'generic.1',
            // },
            // _placehoder: {
            //   color: 'generic.4',
            //   border: '2px solid',
            //   borderColor: 'generic.1',
            //   opacity: 1,
            // },
            w: '160px',
          },
        },
      },
    },
    Button: {
      variants: {
        'primary-button': {
          bg: 'generic.1',
          color: 'generic.3',
          fontSize: '16px',
          w: '320px',
          h: '45px',
          _hover: {
            bg: 'primary.400',
            fontSize: '15px',
          },
          _active: {
            bg: 'primary.600',
            fontSize: '15px',
          },
        },
        'secondary-button': {
          bg: 'generic.3',
          color: 'generic.1',
          fontSize: '16px',
          w: '320px',
          h: '45px',
          _hover: {
            bg: 'primary.50',
            fontSize: '15px',
          },
          _active: {
            bg: 'primary.100',
            fontSize: '15px',
          },
        },
        'graphic-button-active': {
          bg: 'generic.1',
          color: 'generic.3',
          w: 'full',
          fontSize: '16px',
          _hover: {
            bg: 'primary.400',
            fontSize: '15px',
          },
          _active: {
            bg: 'primary.600',
            fontSize: '15px',
          },
        },
        'graphic-button-inactive': {
          bg: 'gray.400',
          color: 'gray.800',
          fontSize: '16px',
          w: 'full',
          _hover: {
            bg: 'primary.50',
            fontSize: '15px',
          },
          _active: {
            bg: 'primary.100',
            fontSize: '15px',
          },
        },
        'reset-filters-button': {
          w: 'full',
          bg: '#f6fc7c',
          fontSize: '16px',
          _hover: {
            fontSize: '15px',
            bg: '#fafdb1',
          },
          _active: {
            fontSize: '15px',
            bg: '#f0fa27',
          },
        },
        'submit-filters-button': {
          w: 'full',
          bg: '#b8e8e8',
          fontSize: '16px',
          _hover: {
            fontSize: '15px',
            bg: '#e5fbfb',
          },
          _active: {
            fontSize: '15px',
            bg: '#80d2d2',
          },
        },
      },
    },
  },
  layerStyles: {
    'input-box': {
      borderRadius: 'md',
      border: '2px solid',
      borderColor: 'generic.1',
      color: 'generic.4',
      _hover: {
        fontSize: '15px',
        bg: 'primary.50',
      },
      _placehoder: {
        color: 'generic.4',
      },
      _active: {
        bg: 'primary.100',
      },
      minW: '220px',
    },
    'field-to-pick': {
      borderRadius: 'md',
      border: '2px solid',
      borderColor: 'generic.1',
      bg: 'white',
      color: 'generic.4',
      _hover: {
        fontSize: '15px',
        bg: 'primary.50',
        cursor: 'pointer',
      },
      _placehoder: {
        color: 'generic.4',
      },
      _active: {
        bg: 'primary.100',
      },
      minW: '120px',
      minH: '30px',
      textAlign: 'center',
      px: '10px',
      py: '2px',
    },
    'picked-field': {
      borderRadius: 'md',
      bg: 'generic.1',
      color: 'generic.4',
      _hover: {
        fontSize: '15px',
        bg: 'primary.300',
        cursor: 'pointer',
        color: 'red',
      },
      _placehoder: {
        color: 'generic.4',
      },
      _active: {
        bg: 'primary.600',
      },
      minW: '120px',
      minH: '30px',
      textAlign: 'center',
      px: '10px',
      py: '2px',
    },
  },
});

export default theme;
