import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { colors } from '../../../theme';

const SelectProductsByEanOrName = props => {
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState('');
  const [value, setValue] = useState(null);
  const [currentFilters, setCurrentFilters] = useState(null);
  const color = 'black';
  const colorBg = 'white';
  const colorHover = colors.primary['50'];
  const colorHoverOption = colors.primary['200'];
  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value) {
      userValue = options.find(
        option => String(option.value) === String(value),
      );

      userValue ? setValue(userValue) : loadDefaultOptions(value);
    }
    if (!props.value) {
      setValue(null);
    }
  }, [props.value]);

  useEffect(() => {
    defaultOptions();
  }, []);

  useEffect(() => {
    defaultOptions();
  }, [props.combinationFilters, props.filters]);

  useEffect(() => {
    let newOptions = options.filter(prevOption => {
      return !props.selectedProducts.find(sp => sp.value == prevOption.value);
    });
    setOptions(newOptions);
  }, [props.selectedProducts]);

  const handleChange = (selectedValue, action) => {
    if (action.action == 'clear') {
      setValue(selectedValue);
      return;
    }
    props.handleSelect(selectedValue);
  };

  const defaultOptions = async () => {
    let filters = {
      where: {},
    };

    if (JSON.stringify(filters) == JSON.stringify(currentFilters)) return;

    setCurrentFilters(filters);
    apiHelper
      .getProducts(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.productEan,
            label: `${entity.productName} - (${entity.productEan})`,
            description: entity.productName,
          };
        });

        setOptions(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadOptions = async (input, callback) => {
    if (!input.length) return;

    let byEanFilters = {
      where: {
        productEan: input,
      },
      limit: 20,
    };
    let byNameFilters = {
      where: {
        productName: input,
      },
      limit: 20,
    };

    const byEanResponse = await apiHelper.getProducts(byEanFilters);
    const byNameResponse = await apiHelper.getProducts(byNameFilters);

    let entitiesForSelect = [
      ...byEanResponse.data.map(entity => {
        return {
          value: entity.productEan,
          label: `${entity.productName} - (${entity.productEan})`,
        };
      }),
      ...byNameResponse.data.map(entity => {
        return {
          value: entity.productEan,
          label: `${entity.productName} - (${entity.productEan})`,
          description: entity.productName,
        };
      }),
    ];
    const pickedEans = props.selectedProducts.map(p => p.value);
    entitiesForSelect = entitiesForSelect.filter(op => {
      return !pickedEans.includes(op.value);
    });
    setOptions(entitiesForSelect);
    callback(entitiesForSelect);
  };

  const loadDefaultOptions = async value => {
    if (!value) return;

    let byEanFilters = {
      where: {
        productEan: input,
      },
      limit: 20,
    };
    let byNameFilters = {
      where: {
        productName: input,
      },
      limit: 20,
    };

    const byEanResponse = await apiHelper.getProducts(byEanFilters);
    const byNameResponse = await apiHelper.getProducts(byNameFilters);

    let entitiesForSelect = [
      ...byEanResponse.data.map(entity => {
        return {
          value: entity.productEan,
          label: `${entity.productName} - (${entity.productEan})`,
          description: entity.productName,
        };
      }),
      ...byNameResponse.data.map(entity => {
        return {
          value: entity.productEan,
          label: `${entity.productName} - (${entity.productEan})`,
          description: entity.productName,
        };
      }),
    ];
    const pickedEans = props.selectedProducts.map(p => p.value);
    entitiesForSelect = entitiesForSelect.filter(op => {
      return !pickedEans.includes(op.value);
    });
    setOptions(entitiesForSelect);
    // callback(entitiesForSelect);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '0px solid black',
      backgroundColor: 'none',
      outline: 'none',
      '&:hover': { backgroundColor: colorHover },
    }),
    placeholder: () => ({
      color: color,
      marginLeft: '8px',
      '&:hover': { fontSize: '15px' },
      fontSize: '16px',
    }),

    singleValue: () => ({
      color: color,
    }),

    menuList: provide => ({
      ...provide,
      color: color,
      backgroundColor: colorBg,
    }),
    option: (provider, state) => ({
      ...provider,
      '&:hover': { backgroundColor: colorHoverOption },
      backgroundColor: state.isFocused ? colorHoverOption : 'none',
      color: color,
    }),
  };

  let components = props.isDisabled && {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  return (
    <AsyncSelect
      styles={customStyles}
      onChange={handleChange}
      defaultOptions={options}
      loadOptions={loadOptions}
      isClearable={true}
      value={value}
      name={props.name}
      required={props.required}
      components={components}
      isDisabled={props.isDisabled}
      placeholder=""
    />
  );
};

export default SelectProductsByEanOrName;
